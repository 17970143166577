<template>
  <div id="form-layouts">
    <FormFieldLabel label="Form Layout" />

    <div class="row q-col-gutter-md">
      <div
        v-for="layout in formLayouts"
        :key="layout.id"
        class="col"
        :class="{ 'is-active': value === layout.value }"
      >
        <div class="layout" @click="handleClick(layout.value)">
          <!-- CLASSIC -->

          <ClassicLayoutSkeleton v-if="layout.value === 'CLASSIC'" />

          <!-- ... -->

          <!-- CARD -->

          <CardLayoutSkeleton v-if="layout.value === 'CARD'" />

          <!-- ... -->

          <!-- TAB -->

          <TabLayoutSkeleton v-if="layout.value === 'TAB'" />

          <!-- ... -->

          <Check v-if="value === layout.value" />
        </div>

        <div class="title">
          {{ layout.label }}
        </div>
        <div class="description">{{ layout.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import Check from "@/components/common/Check.vue";
import ClassicLayoutSkeleton from "./components/ClassicLayoutSkeleton.vue";
import CardLayoutSkeleton from "./components/CardLayoutSkeleton.vue";
import TabLayoutSkeleton from "./components/TabLayoutSkeleton.vue";

export default {
  name: "FormLayouts",

  components: {
    FormFieldLabel,
    Check,
    ClassicLayoutSkeleton,
    CardLayoutSkeleton,
    TabLayoutSkeleton,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      formLayouts: [
        {
          id: this.$nano.id(),
          label: "Classic",
          value: "CLASSIC",
          description: "Show all panels on one page",
        },
        {
          id: this.$nano.id(),
          label: "Card",
          value: "CARD",
          description: "Show one panel per page",
        },
        {
          id: this.$nano.id(),
          label: "Tab",
          value: "TAB",
          description: "Show each panel in a tab",
        },
      ],
    };
  },

  methods: {
    handleClick(layout) {
      this.$emit("input", layout);
    },
  },
};
</script>

<style lang="scss" scoped>
#form-layouts {
  .layout {
    height: 208px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    position: relative;
    padding: 16px;
    cursor: pointer;
  }

  .title {
    color: var(--icon-color);
    text-align: center;
    margin-top: 16px;
  }

  .description {
    @extend .text-sm;
    color: var(--icon-color-inverted);
    text-align: center;
  }

  .is-active {
    .layout {
      border-color: var(--secondary);
    }

    .title {
      color: var(--body-text-color);
      font-weight: 500;
    }
  }
}
</style>
