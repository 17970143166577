<template>
  <div id="nested-list-items-per-line">
    <!-- label -->

    <FormFieldLabel label="Nested List Items Per Line" is-mandatory />

    <!-- ... -->

    <!-- header -->

    <div class="header row">
      <div class="col-3 row items-center">
        <div class="label">Level</div>
      </div>

      <div class="col row items-center">
        <div class="label">Items Per Line</div>
      </div>
    </div>

    <!-- ... -->

    <!-- levels -->

    <div
      v-for="level in nestedListLevels"
      :key="level.id"
      class="row items-center q-mb-sm"
    >
      <!-- name -->

      <div class="col-3 label">
        {{ level.label }}
      </div>

      <!-- ... -->

      <!-- items per line -->

      <div class="col">
        <SelectField
          v-model="level.itemsPerLine"
          :is-clearable="false"
          :options="itemsPerLineOptions"
        />
      </div>

      <!-- ... -->
    </div>

    <!-- ... -->

    <BaseSeparator v-if="value.length" class="q-mt-md" />

    <!-- error -->

    <FormFieldError v-if="error" :error="error" />

    <!-- ... -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";

export default {
  name: "NestedListItemsPerLine",

  components: {
    FormFieldLabel,
    FormFieldError,
    SelectField,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    error: {
      type: String,
      default: "",
    },

    maxLevel: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      nestedListLevels: [],
      itemsPerLineOptions: [
        {
          id: this.$nano.id(),
          label: "Auto",
          value: "col-auto",
        },
        {
          id: this.$nano.id(),
          label: 1,
          value: "col-12",
        },
        {
          id: this.$nano.id(),
          label: 2,
          value: "col-6",
        },
        {
          id: this.$nano.id(),
          label: 3,
          value: "col-4",
        },
        {
          id: this.$nano.id(),
          label: 4,
          value: "col-3",
        },
        {
          id: this.$nano.id(),
          label: 6,
          value: "col-2",
        },
      ],
    };
  },

  watch: {
    maxLevel: {
      immediate: true,
      handler() {
        this.nestedListLevels = [];

        for (let level = 0; level < this.maxLevel; level++) {
          this.$set(
            this.nestedListLevels,
            level,
            this.value[level] || this.addLevel(level)
          );
        }
      },
    },

    nestedListLevels: {
      deep: true,
      handler() {
        this.$emit("input", this.nestedListLevels);
      },
    },
  },
  methods: {
    addLevel(level) {
      const _level = `Level - ${level + 1}`;
      return {
        id: this.$nano.id(),
        label: _level,
        value: level + 1,
        itemsPerLine: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#nested-list-items-per-line {
  .header {
    border-bottom: 1px solid var(--divider-color);
    margin-bottom: 16px;
    padding-bottom: 8px;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }
}
</style>
