<template>
  <div id="validation-settings">
    <!-- field rules -->

    <SingleChoiceField
      v-if="showFieldRule"
      :value="fieldRule"
      label="field rule"
      :options="fieldRules"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateFieldRule"
    />

    <!-- ... -->

    <!-- content rules -->

    <SingleChoiceField
      v-if="showContentRule"
      :value="contentRule"
      label="content rule"
      :options="_contentRules"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateContentRule"
    />

    <!-- ... -->

    <!-- range -->

    <div v-if="showRange" class="q-pa-md">
      <FormFieldLabel
        :label="
          ['NUMBER', 'COUNTER'].includes(fieldType)
            ? 'range'
            : 'character limit'
        "
        class="field-label"
      />

      <div class="row q-col-gutter-sm">
        <!-- minimum -->

        <ValidationProvider
          v-slot="{ errors }"
          name="minimum"
          class="col"
          :rules="{
            lessThanMax: maximum ? { target: '@maximum' } : false,
          }"
        >
          <NumberField
            :value="minimum"
            placeholder="Minimum"
            :error="errors[0]"
            @input="updateMinimum"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- maximum -->

        <NumberField
          :value="maximum"
          placeholder="Maximum"
          @input="updateMaximum"
        />

        <!-- ... -->
      </div>
    </div>

    <!-- ... -->

    <!-- date range -->

    <div v-if="fieldType === 'DATE'" class="q-pa-md">
      <FormFieldLabel label="range" class="field-label" />

      <div class="row q-col-gutter-sm">
        <!-- minimum -->

        <ValidationProvider
          v-slot="{ errors }"
          name="minimum"
          class="col"
          :rules="{
            lessThanMaxDate: maximum ? { target: '@maximum' } : false,
          }"
        >
          <DateField
            :value="minimum"
            placeholder="Minimum"
            :error="errors[0]"
            @input="updateMinimum"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- maximum -->

        <ValidationProvider name="maximum" class="col">
          <DateField
            :value="maximum"
            placeholder="Maximum"
            @input="updateMaximum"
          />
        </ValidationProvider>

        <!-- ... -->
      </div>
    </div>

    <!-- ... -->

    <!-- time range -->

    <div v-if="fieldType === 'TIME'" class="q-pa-md">
      <FormFieldLabel label="range" class="field-label" />

      <div class="row q-col-gutter-sm">
        <!-- minimum -->

        <ValidationProvider
          v-slot="{ errors }"
          name="minimum"
          class="col"
          :rules="{
            lessThanMaxTime: maximum ? { target: '@maximum' } : false,
          }"
        >
          <TimeField
            :value="minimum"
            placeholder="Minimum"
            :error="errors[0]"
            @input="updateMinimum"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- maximum -->

        <ValidationProvider name="maximum" class="col">
          <TimeField
            :value="maximum"
            placeholder="Maximum"
            @input="updateMaximum"
          />
        </ValidationProvider>

        <!-- ... -->
      </div>
    </div>

    <!-- ... -->

    <!-- date time range -->

    <div v-if="fieldType === 'DATE_TIME'" class="q-pa-md">
      <FormFieldLabel label="range" class="field-label" />

      <!-- minimum -->

      <ValidationProvider
        v-slot="{ errors }"
        name="minimum"
        class="col"
        :rules="{
          lessThanMaxDate: maximum ? { target: '@maximum' } : false,
        }"
      >
        <DateTimeField
          :value="minimum"
          :error="errors[0]"
          class="q-mb-sm"
          @input="updateMinimum"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- maximum -->

      <ValidationProvider name="maximum" class="col">
        <DateTimeField :value="maximum" @input="updateMaximum" />
      </ValidationProvider>

      <!-- ... -->
    </div>

    <!-- ... -->

    <!-- allowed file types -->

    <MultiSelectField
      v-if="fieldType === 'FILE_UPLOAD'"
      :value="allowedFileTypes"
      label="allowed file types"
      tooltip="By default, all the file types are allowed"
      :options="fileTypes"
      class="q-pa-md"
      @input="updateAllowedFileTypes"
    />

    <!-- ... -->

    <!-- max file size -->

    <ValidationProvider
      v-if="showMaxFileSize"
      v-slot="{ errors }"
      name="max file size"
      :rules="{ required: true, min_value: 1, max_value: 10 }"
    >
      <NumberField
        :value="maxFileSize"
        is-mandatory
        label="max file size (MB)"
        :is-clearable="false"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateMaxFileSize"
      />
    </ValidationProvider>

    <!-- ... -->
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import DateTimeField from "@/components/common/form/date-time-field/DateTimeField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";

export default {
  name: "ValidationSettings",

  components: {
    ValidationProvider,
    SingleChoiceField,
    FormFieldLabel,
    NumberField,
    DateField,
    TimeField,
    DateTimeField,
    MultiSelectField,
  },

  props: {
    fieldType: {
      type: String,
      default: "",
    },

    fieldRule: {
      type: String,
      default: "",
    },

    contentRule: {
      type: String,
      default: "",
    },

    minimum: {
      type: [String, Number],
      default: "",
    },

    maximum: {
      type: [String, Number],
      default: "",
    },

    allowedFileTypes: {
      type: Array,
      default: () => [],
    },

    maxFileSize: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      fieldRules: [
        {
          id: this.$nano.id(),
          label: "Required",
          description: "Must have a non-empty value",
          value: "REQUIRED",
        },
        {
          id: this.$nano.id(),
          label: "Optional",
          description: "Can have an empty value",
          value: "OPTIONAL",
        },
      ],
      contentRules: [
        {
          id: this.$nano.id(),
          label: "Alpha",
          description: "May contain only alphabetic characters",
          value: "ALPHA",
          for: ["SHORT_TEXT", "LONG_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Alpha Spaces",
          description: "May contain only alphabetic characters and spaces",
          value: "ALPHA_SPACES",
          for: ["SHORT_TEXT", "LONG_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Alpha Dash",
          description:
            "May contain only alphabetic characters, numbers, dashes and underscores",
          value: "ALPHA_DASH",
          for: ["SHORT_TEXT", "LONG_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Alpha Numeric",
          description: "May contain alphabetic characters and numbers",
          value: "ALPHA_NUMERIC",
          for: ["SHORT_TEXT", "LONG_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Text",
          description: "May contain all characters",
          value: "TEXT",
          for: ["SHORT_TEXT", "LONG_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Email",
          description: "Must be a valid email address",
          value: "EMAIL",
          for: ["SHORT_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Integer",
          description: "Does not accept decimals",
          value: "INTEGER",
          for: ["NUMBER"],
        },
        {
          id: this.$nano.id(),
          label: "Decimal",
          description: "Does accept decimals",
          value: "DECIMAL",
          for: ["NUMBER"],
        },
      ],
      fileTypes: [
        {
          id: this.$nano.id(),
          label: "pdf",
          value: "pdf",
        },
        {
          id: this.$nano.id(),
          label: "doc",
          value: "doc",
        },
        {
          id: this.$nano.id(),
          label: "docx",
          value: "docx",
        },
        {
          id: this.$nano.id(),
          label: "xls",
          value: "xls",
        },
        {
          id: this.$nano.id(),
          label: "xlsx",
          value: "xlsx",
        },
        {
          id: this.$nano.id(),
          label: "ppt",
          value: "ppt",
        },
        {
          id: this.$nano.id(),
          label: "pptx",
          value: "pptx",
        },
        {
          id: this.$nano.id(),
          label: "txt",
          value: "txt",
        },
        {
          id: this.$nano.id(),
          label: "csv",
          value: "csv",
        },
        {
          id: this.$nano.id(),
          label: "jpg",
          value: "jpg",
        },
        {
          id: this.$nano.id(),
          label: "jpeg",
          value: "jpeg",
        },
        {
          id: this.$nano.id(),
          label: "gif",
          value: "gif",
        },
        {
          id: this.$nano.id(),
          label: "png",
          value: "png",
        },
        {
          id: this.$nano.id(),
          label: "tif",
          value: "tif",
        },
        {
          id: this.$nano.id(),
          label: "tiff",
          value: "tiff",
        },
        {
          id: this.$nano.id(),
          label: "mp3",
          value: "mp3",
        },
        {
          id: this.$nano.id(),
          label: "wav",
          value: "wav",
        },
        {
          id: this.$nano.id(),
          label: "mp4",
          value: "mp4",
        },
        {
          id: this.$nano.id(),
          label: "wmv",
          value: "wmv",
        },
        {
          id: this.$nano.id(),
          label: "mov",
          value: "mov",
        },
      ],
    };
  },

  computed: {
    showFieldRule() {
      return [
        "LABEL",
        "SHORT_TEXT",
        "LONG_TEXT",
        "NUMBER",
        "COUNTER",
        "DATE",
        "TIME",
        "DATE_TIME",
        "SINGLE_SELECT",
        "MULTI_SELECT",
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
        "CHIPS",
        "IMAGE",
        "SIGNATURE",
        "RATING",
        "CHECKBOX",
        "RADIO",
        "NESTED_LIST",
      ].includes(this.fieldType);
    },

    showContentRule() {
      return ["SHORT_TEXT", "LONG_TEXT", "NUMBER"].includes(this.fieldType);
    },

    _contentRules() {
      return this.contentRules.filter((option) =>
        option.for.includes(this.fieldType)
      );
    },

    showRange() {
      return ["SHORT_TEXT", "LONG_TEXT", "NUMBER", "COUNTER"].includes(
        this.fieldType
      );
    },

    showMaxFileSize() {
      return ["FILE_UPLOAD", "IMAGE", "SIGNATURE"].includes(this.fieldType);
    },
  },

  methods: {
    updateFieldRule(rule) {
      this.$emit("update:fieldRule", rule);
    },

    updateContentRule(rule) {
      this.$emit("update:contentRule", rule);
    },

    updateMinimum(value) {
      this.$emit("update:minimum", value);
    },

    updateMaximum(value) {
      this.$emit("update:maximum", value);
    },

    updateAllowedFileTypes(types) {
      this.$emit("update:allowedFileTypes", types);
    },

    updateMaxFileSize(size) {
      this.$emit("update:maxFileSize", size);
    },
  },
};
</script>

<style lang="scss" scoped>
#validation-settings {
  .field-label {
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 10px;
  }
}
</style>
