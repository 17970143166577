<template>
  <div id="ai-settings">
    <!-- form fields validate -->

    <SingleChoiceField
      v-model="formControlValidate.optionsType"
      label="Form Controls Validate"
      :options="formFieldOptions"
      :options-per-line="1"
      class="q-pa-md"
    />

    <template v-if="formControlValidate.optionsType === 'MASTER'">
      <div class="row q-col-gutter-sm q-pa-md">
        <!-- master table -->

        <ValidationProvider
          v-slot="{ errors }"
          name="table name"
          :rules="{ required: true }"
          class="col"
        >
          <SelectField
            v-model="formControlValidate.masterFormId"
            is-mandatory
            label="Master Table"
            :error="errors[0]"
            :is-clearable="true"
            :options="masterForms"
            class=""
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- column -->

        <ValidationProvider
          v-slot="{ errors }"
          name="column"
          :rules="{ required: true }"
          class="col"
        >
          <MultiSelectField
            v-model="formControlValidate.masterFormColumn"
            is-mandatory
            label="Column"
            :error="errors[0]"
            :is-clearable="true"
            :options="masterFormColumns"
          />
        </ValidationProvider>

        <!-- ... -->
      </div>

      <ValidationProvider
        v-slot="{ errors }"
        name="column"
        :rules="{ required: true }"
      >
        <SelectField
          v-model="formControlValidate.formFields"
          is-mandatory
          label="Validate with prompt word"
          :error="errors[0]"
          :is-clearable="true"
          :options="formFields"
          class="q-pa-md"
        />
      </ValidationProvider>
    </template>

    <template v-if="formControlValidate.optionsType === 'EXISTING'">
      <ValidationProvider
        v-slot="{ errors }"
        name="column"
        :rules="{ required: true }"
      >
        <MultiSelectField
          v-model="formControlValidate.existingFormFields"
          is-mandatory
          label="Choose conrols to validate and compare"
          :error="errors[0]"
          :is-clearable="true"
          :options="formFields"
          class="q-pa-md"
        />
      </ValidationProvider>
    </template>

    <template v-if="formControlValidate.optionsType === 'CONDITION'">
      <ConditionBuilder
        :conditions.sync="formControlValidate.conditionFields"
        :group-logic.sync="groupLogic"
        :fields="formFields"
        class="q-pa-md"
      />
    </template>
    <!-- ... -->

    <!-- image fields validate -->

    <SingleChoiceField
      v-model="imageFileValidate.optionsType"
      label="Form Controls Validate"
      :options="imageFieldOptions"
      :options-per-line="1"
      class="q-pa-md"
    />

    <template v-if="imageFileValidate.optionsType === 'DATA'">
      <ValidationProvider
        v-slot="{ errors }"
        name="column"
        :rules="{ required: true }"
      >
        <MultiSelectField
          v-model="imageFileValidate.formFields"
          is-mandatory
          label="Choose conrols to validate and compare"
          :error="errors[0]"
          :is-clearable="true"
          :options="formFields"
          class="q-pa-md"
        />
      </ValidationProvider>
    </template>
    <!-- ... -->

    <!-- form fields validate -->

    <MultipleChoiceField
      :value="fileDataValidate"
      label="File Data Validate"
      :options="fileDataValidateOptions"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateFileDataValidate"
    />
    <!-- .... -->
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { form } from "@/api/factory.js";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import MultipleChoiceField from "@/components/common/form/multiple-choice-field/MultipleChoiceField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import ConditionBuilder from "@/components/common/condition-builder/ConditionBuilder.vue";

export default {
  name: "AISettings",

  components: {
    ValidationProvider,
    MultiSelectField,
    SingleChoiceField,
    SelectField,
    ConditionBuilder,
    MultipleChoiceField,
  },

  props: {
    formControlValidate: {
      type: Object,
      default: () => {},
    },

    imageFileValidate: {
      type: Object,
      default: () => {},
    },

    fileDataValidate: {
      type: Array,
      default: () => [],
    },

    panels: {
      type: Array,
      default: () => [],
    },

    fieldId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      formFieldOptions: [
        {
          id: this.$nano.id(),
          label: "Validate from master information",
          value: "MASTER",
          description:
            "Validate data against recognized master data for accuracy",
        },
        {
          id: this.$nano.id(),
          label: "Validate from Existing fields",
          value: "EXISTING",
          description: "Verify data consistency using existing form fields",
        },
        {
          id: this.$nano.id(),
          label: "Validate from defined information",
          value: "CONDITION",
          description: "Manually validate data against defined criteria",
        },
      ],
      masterForms: [],
      masterFormColumns: [],
      groupLogic: "ALL",
      fileDataValidateOptions: [
        {
          id: this.$nano.id(),
          label: "Verify with Predefined date",
          value: "PREDEFINED",
          description: "Confirm data against a predefined date for validation",
        },
        {
          id: this.$nano.id(),
          label: "Validate Document Expiry Date",
          value: "EXPIRY_DATE",
          description:
            "Check document expiry dates against the current date for validity",
        },
      ],
      imageFieldOptions: [
        {
          id: this.$nano.id(),
          label: "Compare image with documents",
          value: "IMAGE",
          description: "Analyze similarities between images and documents",
        },
        {
          id: this.$nano.id(),
          label: "Compare data with documents",
          value: "DATA",
          description: "Compare specific data fields across multiple documents",
        },
      ],
    };
  },

  computed: {
    formFields() {
      if (!this.panels) return [];
      let fieldList = [];
      let fields = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
      }

      fieldList.forEach((field) => {
        // console.log(field);

        if (field.id !== this.fieldId) {
          if (
            this.formControlValidate.optionsType === "MASTER" &&
            (field.type === "SHORT_TEXT" ||
              field.type === "LONG_TEXT" ||
              field.type === "LABEL" ||
              field.type === "PARAGRAPH")
          ) {
            fields.push({
              id: this.$nano.id(),
              label: field.label,
              value: field.id,
            });
          }
          if (
            (this.formControlValidate.optionsType === "EXISTING" ||
              this.formControlValidate.optionsType === "CONDITION") &&
            field.type !== "LABEL" &&
            field.type !== "PARAGRAPH" &&
            field.type !== "FILE_UPLOAD" &&
            field.type !== "CHIPS" &&
            field.type !== "TABLE" &&
            field.type !== "DIVIDER" &&
            field.type !== "TEXT_BUILDER" &&
            field.type !== "FILL_IN_THE_BLANKS" &&
            field.type !== "MATRIX" &&
            field.type !== "SIGNATURE"
          ) {
            fields.push({
              id: this.$nano.id(),
              label: field.label,
              value: field.id,
            });
          }
        }
      });

      return fields;
    },
  },

  watch: {
    "formControlValidate.masterFormId": {
      deep: true,
      immediate: true,
      handler() {
        if (this.formControlValidate.masterFormId) {
          this.getFormFields(this.formControlValidate.masterFormId);
        }
      },
    },
  },

  created() {
    this.getMasterForms();
  },

  methods: {
    async getMasterForms() {
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "MASTER",
                dataType: "",
              },
              {
                criteria: "publishOption",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 500,
        currentPage: 1,
        hasSecurity: false,
      });

      if (error) {
        this.$alert.error("Error fetching master form list");
        return;
      }

      this.masterForms = [];
      const { data } = payload;
      if (data.length) {
        this.masterForms = data[0].value.map((form) => ({
          id: this.$nano.id(),
          label: form.name,
          value: form.id,
        }));
      }
    },

    async getFormFields(formId) {
      this.masterFormColumns = [];
      const { error, payload } = await form.getForm(formId);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        let form = JSON.parse(payload.formJson);
        let masterfields = [];
        const panels = [...form.panels, ...form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        for (const panel of panels) {
          masterfields.push(...panel.fields);
        }

        masterfields.forEach((field) => {
          if (field.type !== "DIVIDER") {
            this.masterFormColumns.push({
              id: field.id,
              label: field.label,
              value: field.id,
            });
          }
        });
      }
    },

    updateFileDataValidate(value) {
      this.$emit("update:fileDataValidate", value);
    },
  },
};
</script>
