<template>
  <Sheet
    :value="value"
    width="720px"
    no-padding
    has-footer
    @input="closeSettings"
  >
    <!-- title -->

    <template #title>Text editor</template>

    <!-- ... -->

    <!-- content -->

    <template #default>
      <div id="tip-tap-editor">
        <!-- toolbar -->

        <Toolbar
          :show-add-field="showAddField"
          @set-heading="setHeading"
          @unset-heading="unsetHeading"
          @toggle-alignment="toggleAlignment"
          @toggle-bold="toggleBold"
          @toggle-italic="toggleItalic"
          @toggle-underline="toggleUnderline"
          @toggle-bullet-list="toggleBulletList"
          @toggle-number-list="toggleNumberList"
          @increase-indent="increaseIndent"
          @decrease-indent="decreaseIndent"
          @set-text-color="setTextColor"
          @unset-text-color="unsetTextColor"
          @set-bg-color="setBgColor"
          @unset-bg-color="unsetBgColor"
          @undo="undo"
          @redo="redo"
          @set-link="setLink"
          @add-field="addField"
        />

        <!-- ... -->

        <!-- content -->

        <EditorContent :editor="editor" />

        <!-- ... -->
      </div>
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton
        label="cancel"
        is-flat
        class="q-mr-sm"
        @click="closeSettings"
      />

      <BaseButton label="save" @click="save" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import Toolbar from "./components/toolbar/Toolbar.vue";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import FieldPlaceholder from "./components/field-placeholder/extension.js";
import { cloneDeep, isEmpty } from "lodash-es";
import getNewField from "@/helpers/new-field.js";

export default {
  name: "TextEditor",

  components: {
    Sheet,
    Toolbar,
    EditorContent,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    field: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      editor: null,
      textContent: "",
      fibFields: [],
    };
  },

  computed: {
    showAddField() {
      if (isEmpty(this.field)) {
        return false;
      }

      return this.field.type === "FILL_IN_THE_BLANKS";
    },
  },

  created() {
    if (!isEmpty(this.field)) {
      this.textContent = this.field.settings.specific.textContent;
      this.fibFields = cloneDeep(this.field.settings.specific.fibFields);
    }

    this.initiateEditor();
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  methods: {
    closeSettings() {
      this.$emit("input", false);
    },

    initiateEditor() {
      this.editor = new Editor({
        content: this.textContent,
        extensions: [
          StarterKit,
          Link,
          Underline,
          TextAlign.configure({
            types: ["heading", "paragraph"],
          }),
          TextStyle,
          Color,
          Highlight.configure({
            multicolor: true,
          }),
          Placeholder.configure({
            placeholder: "Start typing …",
          }),
          FieldPlaceholder,
        ],
      });
    },

    setHeading(level) {
      this.editor.chain().focus().toggleHeading({ level }).run();
    },

    unsetHeading() {
      this.editor.chain().focus().clearNodes().unsetAllMarks().run();
    },

    toggleAlignment(alignment) {
      this.editor.chain().focus().setTextAlign(alignment).run();
    },

    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },

    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },

    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run();
    },

    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run();
    },

    toggleNumberList() {
      this.editor.chain().focus().toggleOrderedList().run();
    },

    increaseIndent() {
      this.editor.chain().focus().sinkListItem("listItem").run();
    },

    decreaseIndent() {
      this.editor.chain().focus().liftListItem("listItem").run();
    },

    undo() {
      this.editor.chain().focus().undo().run();
    },

    redo() {
      this.editor.chain().focus().redo().run();
    },

    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (!url) {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },

    setTextColor(color) {
      this.editor.chain().focus().setColor(color).run();
    },

    unsetTextColor() {
      this.editor.chain().focus().unsetColor().run();
    },

    setBgColor(color) {
      this.editor.chain().focus().setHighlight({ color }).run();
    },

    unsetBgColor() {
      this.editor.chain().focus().unsetHighlight().run();
    },

    addField(fieldType) {
      const newField = getNewField(fieldType);
      const placeholder = `<FieldPlaceholder fieldId="${newField.id}" fieldType="${fieldType}" />`;

      this.editor.chain().focus().insertContent(placeholder).run();
      this.fibFields.push(newField);
    },

    diffFibFields() {
      const content = this.editor.getHTML();
      const parser = new DOMParser();
      const html = parser.parseFromString(content, "text/html");
      const placeholderNodes = Array.from(
        html.querySelectorAll("fieldplaceholder")
      );

      const placeholders = placeholderNodes.map(
        (placeholder) => placeholder.attributes.fieldid.value
      );

      this.fibFields = this.fibFields.filter((field) =>
        placeholders.includes(field.id)
      );
    },

    save() {
      this.diffFibFields();
      const field = cloneDeep(this.field);
      field.settings.specific.textContent = this.editor.getHTML();
      field.settings.specific.fibFields = this.fibFields;

      this.$emit("save", field);
      this.closeSettings();
    },
  },
};
</script>

<style lang="scss" scoped>
#tip-tap-editor {
  margin: 16px;
}
</style>
