<template>
  <div id="form-header">
    <!-- form info -->

    <div class="row items-center" @click="$emit('settings')">
      <!-- settings -->

      <BaseActionButton
        v-tooltip.top="'edit form settings'"
        icon="eva-edit"
        is-flat
        color="primary"
        class="q-mr-md"
        @click="$emit('settings')"
      />

      <!-- ... -->

      <div>
        <div class="title-2">{{ formName }}</div>
        <div class="description">{{ formDescription }}</div>
      </div>
    </div>

    <!-- ... -->

    <q-space />

    <BaseButton
      label="preview"
      color="secondary"
      icon="eva-eye-outline"
      class="q-mr-sm"
      @click="$emit('preview')"
    />

    <BaseButton label="save" icon="eva-save-outline" @click="$emit('save')" />
  </div>
</template>

<script>
export default {
  name: "ReportsHeader",

  props: {
    formSettings: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formName() {
      return this.formSettings.general.name || "Form name";
    },

    formDescription() {
      return this.formSettings.general.description || "Reports description";
    },
  },
};
</script>

<style lang="scss" scoped>
#form-header {
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  background-color: var(--component-bg-color);

  .description {
    @extend .text-sm;
    color: var(--icon-color);
  }
}
</style>
