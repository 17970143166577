import { render, staticRenderFns } from "./ReportsPreview.vue?vue&type=template&id=06538e88&scoped=true&"
import script from "./ReportsPreview.vue?vue&type=script&lang=js&"
export * from "./ReportsPreview.vue?vue&type=script&lang=js&"
import style0 from "./ReportsPreview.vue?vue&type=style&index=0&id=06538e88&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06538e88",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog});
