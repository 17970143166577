<template>
  <div class="nested-list-list">
    <div v-for="(item, idx) in list" :key="item.id" class="item">
      <!-- ... -->

      <div class="row items-center">
        <!-- level btn -->

        <div v-tooltip.top="`level ${level}`">
          <div
            class="level q-mr-sm"
            :class="{ 'is-readonly': level === maxLevel }"
            @click="item.showChildren = !item.showChildren"
          >
            <div class="q-ml-xs">L{{ level }}</div>
            <BaseIcon
              :name="item.showChildren ? 'mdi-menu-down' : 'mdi-menu-right'"
              inherit-color
              :class="{ 'is-disabled': level === maxLevel }"
            />
          </div>
        </div>

        <!-- ... -->

        <!-- label -->

        <TextField
          v-model="item.label"
          :is-clearable="false"
          class="col q-mr-sm"
        />

        <!-- ... -->

        <!-- delete btn -->

        <BaseActionButton
          is-flat
          no-border
          color="red"
          icon="eva-trash-2-outline"
          @click="deleteItem(idx)"
        />

        <!-- ... -->
      </div>

      <!-- ... -->

      <!-- recursive component -->

      <NestedList
        v-if="level < maxLevel"
        v-show="item.showChildren"
        v-model="item.children"
        :level="level + 1"
        :max-level="maxLevel"
      />

      <!-- ... -->

      <!-- negative space -->

      <div v-if="level === maxLevel || !item.showChildren" class="q-py-sm" />

      <!-- ... -->
    </div>

    <!-- add btn -->

    <div
      v-tooltip:secondary.right="`add level ${level} item`"
      class="add-btn"
      @click="addItem"
    >
      <BaseIcon name="eva-plus" inherit-color />
    </div>

    <!-- ... -->
  </div>
</template>

<script>
import TextField from "@/components/common/form/text-field/TextField.vue";
import { cloneDeep } from "lodash-es";

export default {
  name: "NestedList",

  components: { TextField },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    level: {
      type: Number,
      default: 0,
    },

    maxLevel: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      list: [],
    };
  },

  watch: {
    list: {
      deep: true,
      handler() {
        this.$emit("input", this.list);
      },
    },
  },

  created() {
    if (!this.value.length) {
      return;
    }

    this.list = cloneDeep(this.value);
  },

  methods: {
    addItem() {
      this.list.push({
        id: this.$nano.id(),
        label: "Item",
        level: this.level,
        showChildren: true,
        children: [],
      });
    },

    deleteItem(itemIdx) {
      this.list.splice(itemIdx, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.nested-list-list {
  padding: 16px;
  padding-right: 0px;
  position: relative;
  margin-left: 40px;

  &::before {
    content: "";
    position: absolute;
    top: 22px;
    left: 40px;
    height: calc(100% - 32px - 6px);
    width: 1px;
    background-color: var(--border-color);
    z-index: -1;
  }

  .level {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--icon-color);
    text-transform: uppercase;
    border-radius: 4px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--border-color);
    user-select: none;

    &:hover {
      cursor: pointer;
      background-color: var(--hover-bg-color);
    }
  }

  .add-btn {
    margin: 0px 6px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-color);
    border-radius: 100%;
    color: var(--icon-color);
    background-color: var(--component-bg-color);

    &:hover {
      cursor: pointer;
      color: white;
      background-color: var(--secondary);
    }
  }
}
</style>
