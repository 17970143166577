<template>
  <Sheet
    :value="value"
    width="720px"
    no-padding
    has-footer
    show-maximize-btn
    @input="closeSettings"
  >
    <!-- title -->

    <template #title>Table Builder</template>

    <!-- ... -->

    <!-- content -->

    <template #default>
      <div id="table-builder">
        <BaseScrollbar class="q-pa-md">
          <ValidationObserver ref="form">
            <table>
              <thead>
                <tr>
                  <th
                    v-for="column in tableColumns"
                    :key="column.id"
                    :class="getClass(column.size)"
                  >
                    {{ column.label }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(row, idx) in tableFixedRowLabels" :key="idx">
                  <td v-for="column in tableColumns" :key="column.id">
                    <ValidationProvider
                      v-if="column.type === 'LABEL'"
                      v-slot="{ errors }"
                      :rules="{ required: true }"
                    >
                      <TextField
                        v-model="tableFixedRowLabels[idx][column.id]"
                        :error="errors[0]"
                        placeholder="Label"
                        :is-clearable="false"
                      />
                    </ValidationProvider>
                  </td>
                </tr>
              </tbody>
            </table>
          </ValidationObserver>
        </BaseScrollbar>
      </div>
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton
        label="cancel"
        is-flat
        class="q-mr-sm"
        @click="closeSettings"
      />

      <BaseButton label="save" @click="save" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import { cloneDeep, lowerCase } from "lodash-es";

export default {
  name: "TableBuilder",

  components: { Sheet, ValidationObserver, ValidationProvider, TextField },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    field: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tableFixedRowLabels: [],
    };
  },

  computed: {
    tableColumns() {
      return this.field.settings.specific.tableColumns;
    },

    tableFixedRowCount() {
      return this.field.settings.specific.tableFixedRowCount;
    },
  },

  created() {
    this.tableFixedRowLabels = cloneDeep(
      this.field.settings.specific.tableFixedRowLabels
    );

    const rowsToAdd = this.tableFixedRowCount - this.tableFixedRowLabels.length;

    for (let i = 1; i <= rowsToAdd; i++) {
      const model = {};

      for (let column of this.tableColumns) {
        if (column.type === "LABEL") {
          model[column.id] = "";
        }
      }

      this.tableFixedRowLabels.push(model);
    }
  },

  methods: {
    getClass(columnSize) {
      return lowerCase(columnSize);
    },

    closeSettings() {
      this.$emit("input", false);
    },

    async save() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      const field = cloneDeep(this.field);
      field.settings.specific.tableFixedRowLabels = this.tableFixedRowLabels;

      this.$emit("save", field);
      this.closeSettings();
    },
  },
};
</script>

<style lang="scss" scoped>
#table-builder {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 70px;
      }

      &.medium {
        width: 140px;
      }

      &.large {
        width: 210px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      width: 200px;

      .actions {
        width: 48px;
      }
    }

    td {
      padding: 4px;
      vertical-align: top;
    }
  }
}
</style>
