<template>
  <div id="form-qr-code">
    <FormFieldLabel label="QR Code" is-mandatory />

    <div class="wrapper">
      <img src="@/assets/qrcodec.png" alt="qr code" />

      <div class="col q-ml-lg">
        <div class="">
          Share this QR code with your clients to let them access the form
          directly on their devices
        </div>

        <BaseButton label="download" color="secondary" class="q-mt-md" />
      </div>
    </div>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";

export default {
  name: "QRCode",

  components: { FormFieldLabel },
};
</script>

<style lang="scss" scoped>
#form-qr-code {
  .wrapper {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 8px;
    display: flex;
    align-items: center;

    img {
      width: 156px;
      height: auto;
    }
  }
}
</style>
