<template>
  <div>
    <FormFieldLabel label="matrix type" />

    <div class="row items-center">
      <SelectField
        :value="value"
        :options="matrixTypes"
        :is-clearable="false"
        class="col q-mr-sm"
        @input="handleInput"
      />

      <BaseActionButton
        is-flat
        color="secondary"
        icon="eva-settings-outline"
        no-border
        :is-disabled="!showSettings"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";

export default {
  name: "MatrixType",

  components: { FormFieldLabel, SelectField },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      matrixTypes: [
        {
          id: this.$nano.id(),
          label: "Short Text",
          value: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          label: "Checkbox",
          value: "CHECKBOX",
        },
        {
          id: this.$nano.id(),
          label: "Radio",
          value: "RADIO",
        },
        {
          id: this.$nano.id(),
          label: "Single Select",
          value: "SINGLE_SELECT",
        },
      ],
      showSettings: true,
    };
  },

  methods: {
    handleInput(value) {
      this.showSettings = false;
      this.$emit("input", value);
    },

    handleClick() {
      this.$emit("matrix-type-settings");
    },
  },
};
</script>

<style lang="scss" scoped></style>
