<template>
  <div class="column full-height">
    <q-skeleton
      type="text"
      width="30%"
      height="12px"
      animation="none"
      class="q-mt-md"
      :class="bgColor"
    />
    <q-skeleton type="rect" animation="none" class="q-mb-sm" :class="bgColor" />

    <q-skeleton
      type="text"
      width="30%"
      height="12px"
      animation="none"
      :class="bgColor"
    />
    <q-skeleton type="rect" animation="none" class="q-mb-sm" :class="bgColor" />

    <q-skeleton
      type="text"
      width="30%"
      height="12px"
      animation="none"
      :class="bgColor"
    />
    <div class="row items-start">
      <q-skeleton
        type="QCheckbox"
        size="20px"
        animation="none"
        :class="bgColor"
      />
      <q-skeleton
        type="text"
        width="20%"
        animation="none"
        class="q-ml-sm"
        :class="activeBgColor"
      />

      <q-skeleton
        type="QCheckbox"
        size="20px"
        animation="none"
        class="q-ml-sm"
        :class="bgColor"
      />
      <q-skeleton
        type="text"
        width="20%"
        animation="none"
        class="q-ml-sm"
        :class="bgColor"
      />
    </div>

    <q-space />

    <div class="row justify-end">
      <q-skeleton
        type="QBtn"
        height="20px"
        animation="none"
        width="48px"
        :class="bgColor"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ClassicLayoutSkeleton",

  computed: {
    ...mapState(["darkTheme"]),

    bgColor() {
      return this.darkTheme ? "bg-gray-7" : "bg-gray-4";
    },

    activeBgColor() {
      return this.darkTheme ? "bg-primary-8" : "bg-primary-3";
    },
  },
};
</script>

<style lang="scss" scoped></style>
