<template>
  <div id="field-label" :class="{ 'no-margin': noMargin }">
    <div class="label" :class="transform">
      <!-- {{ label }} -->
      <a href="" target="_blank">{{ label }}</a>
    </div>

    <BaseIcon
      v-if="tooltip"
      v-tooltip.top="tooltip"
      inherit-color
      name="eva-question-mark-circle"
      class="info-icon"
    />
  </div>
</template>

<script>
export default {
  name: "URLField",

  props: {
    label: {
      type: String,
      required: true,
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    noMargin: {
      type: Boolean,
      default: false,
    },

    transform: {
      type: String,
      default: "transform",
    },
  },
};
</script>

<style lang="scss" scoped>
#field-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 1.25rem;

  &.no-margin {
    margin: 0px;
  }

  .label {
    color: var(--icon-color);
    font-weight: 500;
    &.transform {
      text-transform: capitalize;
    }
  }

  .optional {
    color: var(--icon-color-inverted);
    margin-left: 3px;
  }

  .required {
    color: red;
    margin-left: 3px;
  }

  .info-icon {
    margin-left: 8px;
    cursor: pointer;
    color: var(--icon-color-inverted);
  }
}
</style>
