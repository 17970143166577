<template>
  <div id="add-form-panel">
    <div class="wrapper" v-on="$listeners">add panel</div>
  </div>
</template>

<script>
export default {
  name: "AddFormPanel",
};
</script>

<style lang="scss" scoped>
#add-form-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 24px 0px;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px dashed var(--body-text-color-inverted);
  }

  .wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 24px;
    border-radius: 36px;
    color: var(--icon-color);
    text-transform: capitalize;
    font-weight: 500;
    background-color: var(--hover-bg-color);

    &:hover {
      color: var(--secondary);
      cursor: pointer;
    }
  }
}
</style>
