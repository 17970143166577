import { render, staticRenderFns } from "./TabLayoutSkeleton.vue?vue&type=template&id=4790cf81&scoped=true&"
import script from "./TabLayoutSkeleton.vue?vue&type=script&lang=js&"
export * from "./TabLayoutSkeleton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4790cf81",
  null
  
)

export default component.exports
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSkeleton,QSpace});
