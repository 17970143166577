<template>
  <div id="form-builder-wrapper">
    <ItemBuilder @close="closeBuilder">
      <!-- header -->

      <template #title> Reports {{ _tab }} </template>

      <template #description>
        {{ _description }}
      </template>

      <template #header>
        <div class="row items-center justify-center">
          <Tabs v-model="tab" :tabs="tabs" is-full-width class="tabs" />
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          icon="eva-question-mark"
          color="secondary"
          class="q-mr-sm"
          @click="showQuickHelp"
        />
      </template>

      <!-- ... -->

      <!-- content -->

      <ReportsBuilder
        v-if="tab === 'BUILDER'"
        :form="form"
        @update-panels="updatePanels"
        @update-secondary-panels="updateSecondaryPanels"
        @settings="tab = 'SETTINGS'"
        @save="saveForm"
      />

      <ReportSettings
        v-if="tab === 'SETTINGS'"
        :form="form"
        @update="updateSettings"
      />

      <ReportsPublish
        v-if="tab === 'PUBLISH'"
        :form-settings="form.settings"
        @update="updateSettings"
      />

      <!-- ... -->

      <!-- quick help -->

      <QuickHelp v-model="quickHelp" />

      <!-- ... -->
    </ItemBuilder>
  </div>
</template>

<script>
import ItemBuilder from "@/components/common/item-builder/ItemBuilder.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";
import ReportsBuilder from "./reports-builder/ReportsBuilder.vue";
import ReportSettings from "./reports-settings/ReportsSettings.vue";
import ReportsPublish from "./reports-publish/ReportsPublish.vue";
import { capitalize, lowerCase } from "lodash-es";

export default {
  name: "ReportsBuilderWrapper",

  components: {
    ItemBuilder,
    Tabs,
    QuickHelp,
    ReportsBuilder,
    ReportSettings,
    ReportsPublish,
  },

  props: {
    formId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      quickHelp: false,
      tab: "SETTINGS",
      tabs: [
        {
          id: this.$nano.id(),
          label: "Builder",
          value: "BUILDER",
          description:
            " Signgle Report - Report on a single object such as your Task / Files / Workflows ",
        },
        {
          id: this.$nano.id(),
          label: "Settings",
          value: "SETTINGS",
          description: "Customize and manage your form settings",
        },
        {
          id: this.$nano.id(),
          label: "Publish",
          value: "PUBLISH",
          description: "Publish and deploy your form in action",
        },
      ],
      form: {
        uid: this.$nano.id(),
        panels: [],
        secondaryPanels: [],
        settings: {
          general: {
            name: "Reports - " + this.$day.newDateTime(),
            description: "",
            layout: "CLASSIC",
            type: "WORKFLOW",
          },
          rules: [],
          publish: {
            publishOption: "DRAFT",
            publishSchedule: "",
            unpublishSchedule: "",
          },
        },
        isDeleted: false,
      },
    };
  },

  computed: {
    _tab() {
      return capitalize(lowerCase(this.tab));
    },

    _description() {
      let currentTab = this.tabs.filter((item) => item.value === this.tab);
      return currentTab[0].description;
    },
  },

  watch: {
    formId: {
      immediate: true,
      handler() {
        if (this.formId) {
          this.mode = "EDIT";
          this.getForm();
        }
      },
    },
  },

  methods: {
    showQuickHelp() {
      this.quickHelp = true;
    },

    closeBuilder() {
      this.$router.back();
    },

    updatePanels(panels) {
      this.form.panels = panels;
    },

    updateSecondaryPanels(panels) {
      this.form.secondaryPanels = panels;
    },

    updateSettings(settings) {
      this.form.settings = settings;
      this.tab = "BUILDER";
    },

    saveForm() {},

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#form-builder-wrapper {
  height: 100vh;

  .tabs {
    width: 240px;
    border-bottom: 1px solid var(--divider-color);
  }
}
</style>
