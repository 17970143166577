import { render, staticRenderFns } from "./CardLayoutSkeleton.vue?vue&type=template&id=9b05db0e&scoped=true&"
import script from "./CardLayoutSkeleton.vue?vue&type=script&lang=js&"
export * from "./CardLayoutSkeleton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b05db0e",
  null
  
)

export default component.exports
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSkeleton,QSpace});
