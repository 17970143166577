<template>
  <div id="tabs-builder">
    <!-- label -->

    <FormFieldLabel
      label="Tabs"
      is-mandatory
      tooltip="Selected secondary panel will be displayed on tab click"
    />

    <!-- ... -->

    <!-- header -->

    <div class="header row items-center">
      <div class="col label q-mr-lg">Label</div>

      <div class="col label">Secondary Panel</div>

      <div class="row items-center">
        <BaseActionButton
          is-flat
          color="secondary"
          icon="eva-plus"
          no-border
          @click="addTab"
        />
      </div>
    </div>

    <!-- ... -->

    <!-- tabs -->

    <Draggable v-model="tabs">
      <div v-for="(tab, idx) in tabs" :key="tab.id" class="row q-mb-sm">
        <div class="row items-center q-mr-sm" style="height: 46px">
          <BaseIcon
            name="drag_indicator"
            size="16px"
            inherit-color
            class="drag-icon"
          />
        </div>

        <!-- name -->

        <div class="col q-mr-sm">
          <ValidationProvider v-slot="{ errors }" :rules="{ required: true }">
            <TextField
              v-model="tab.label"
              :error="errors[0]"
              :is-clearable="false"
            />
          </ValidationProvider>
        </div>

        <!-- ... -->

        <!-- secondary panel -->

        <div class="col q-mr-sm">
          <ValidationProvider v-slot="{ errors }" :rules="{ required: true }">
            <SelectField
              v-model="tab.panel"
              :options="secondaryPanels"
              :is-clearable="false"
              :error="errors[0]"
            />
          </ValidationProvider>
        </div>

        <!-- ... -->

        <!-- delete -->

        <div class="row items-center" style="height: 46px">
          <BaseActionButton
            is-flat
            color="red"
            icon="eva-close-outline"
            no-border
            @click="deleteTab(idx)"
          />
        </div>

        <!-- ... -->
      </div>
    </Draggable>

    <!-- ... -->

    <BaseSeparator v-if="value.length" class="q-mt-md" />

    <!-- error -->

    <FormFieldError v-if="error" :error="error" />

    <!-- ... -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import Draggable from "@/components/common/Draggable.vue";
import { ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { cloneDeep } from "lodash-es";

export default {
  name: "TabsBuilder",

  components: {
    FormFieldLabel,
    FormFieldError,
    Draggable,
    ValidationProvider,
    TextField,
    SelectField,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    error: {
      type: String,
      default: "",
    },

    secondaryPanels: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      tabs: [],
    };
  },

  watch: {
    tabs: {
      deep: true,
      handler() {
        this.$emit("input", this.tabs);
      },
    },
  },

  created() {
    this.tabs = cloneDeep(this.value);
  },

  methods: {
    addTab() {
      const tab = "tab - " + this.$nano.id().slice(0, 3);

      this.tabs.push({
        id: this.$nano.id(),
        label: tab,
        value: tab,
        panel: "",
      });
    },

    deleteTab(tabIdx) {
      this.tabs.splice(tabIdx, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
#tabs-builder {
  .header {
    border-bottom: 1px solid var(--divider-color);
    margin-bottom: 16px;
    padding-bottom: 4px;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }

  .drag-icon {
    color: var(--icon-color-inverted);
    cursor: move;
  }
}
</style>
