<template>
  <div>
    <FormFieldLabel label="QR Generate" />

    <div class="options-wrapper">
      <CheckboxField
        v-model="QRGenerate"
        label="Generate QR Image"
        description="Include the form information on the QR"
        class="q-mb-lg"
      />

      <MultiSelectField
        v-if="QRGenerate"
        is-mandatory
        :value="value"
        label="Form Fields"
        :options="fields"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";

export default {
  name: "QRGenerate",

  components: { FormFieldLabel, CheckboxField, MultiSelectField },

  props: {
    value: {
      type: Array,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      QRGenerate: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        if (this.value.length) {
          this.QRGenerate = true;
        }
      },
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.options-wrapper {
  border-top: 1px solid var(--divider-color);
  padding-top: 16px;
}
</style>
