<template>
  <BaseActionButton v-tooltip.top="'add field'" is-flat icon="eva-plus">
    <q-menu
      anchor="bottom middle"
      self="top middle"
      :offset="[0, 8]"
      transition-show="scale"
      transition-hide="scale"
    >
      <ListItem
        v-for="field in fields"
        :key="field.id"
        :icon="field.icon"
        :label="field.label"
        class="text-capitalize"
        @click="$emit('click', field.value)"
      />
    </q-menu>
  </BaseActionButton>
</template>

<script>
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "AddField",

  components: { ListItem },

  data() {
    return {
      fields: [
        {
          id: this.$nano.id(),
          label: "short text",
          value: "SHORT_TEXT",
          icon: "mdi-form-textbox",
        },
        {
          id: this.$nano.id(),
          label: "number",
          value: "NUMBER",
          icon: "mdi-numeric",
        },
        {
          id: this.$nano.id(),
          label: "date",
          value: "DATE",
          icon: "eva-calendar-outline",
        },
        {
          id: this.$nano.id(),
          label: "time",
          value: "TIME",
          icon: "eva-clock-outline",
        },
        {
          id: this.$nano.id(),
          label: "single select",
          value: "SINGLE_SELECT",
          icon: "mdi-form-dropdown",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
