<template>
  <Sheet :value="value" has-footer @input="closeSettings">
    <!-- title -->

    <template #title>Panel Settings</template>

    <!-- ... -->

    <!-- form -->

    <template #default>
      <!-- title -->

      <TextField v-model="title" label="title" class="field" />

      <!-- ... -->

      <!-- description -->

      <TextAreaField v-model="description" label="description" class="field" />

      <!-- ... -->
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton
        label="cancel"
        is-flat
        class="q-mr-sm"
        @click="closeSettings"
      />

      <BaseButton label="save" @click="save" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import { cloneDeep, isEmpty } from "lodash-es";

export default {
  name: "PanelSettings",

  components: {
    Sheet,
    TextField,
    TextAreaField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    panel: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      title: "",
      description: "",
    };
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        if (!isEmpty(this.panel)) {
          this.title = this.panel.settings.title;
          this.description = this.panel.settings.description;
        }
      },
    },
  },

  methods: {
    closeSettings() {
      this.$emit("input", false);
    },

    save() {
      const panel = cloneDeep(this.panel);
      panel.settings.title = this.title;
      panel.settings.description = this.description;
      this.$emit("save", panel);
      this.closeSettings();
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  margin: 16px 16px 24px 16px;
}
</style>
