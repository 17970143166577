<template>
  <FormSection
    :icon="isActive ? 'mdi-bullhorn' : 'mdi-bullhorn-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Publish</template>
    <template #description>
      Manage the publishing status of your form
    </template>

    <FormFieldLabel label="publish options" />

    <div class="options-wrapper">
      <!-- save as draft -->

      <RadioField
        :value="publishOption === 'DRAFT'"
        label="Save as Draft"
        description="Pick from where you left"
        class="q-mb-md"
        @input="updatePublishOption('DRAFT')"
      />

      <!-- ... -->

      <!-- publish now -->

      <RadioField
        :value="publishOption === 'PUBLISHED'"
        label="Publish Now"
        description="Deploy and  publish now"
        class="q-mb-md"
        @input="updatePublishOption('PUBLISHED')"
      />

      <!-- ... -->

      <!-- schedule to publish -->

      <BaseFeatureLock v-if="checkFeature" position="CENTER_RIGHT">
        <RadioField
          :value="publishOption === 'PUBLISH_SCHEDULE'"
          label="Schedule to Publish"
          description="Deploy but publish this later"
          class="q-mb-md"
          @input="updatePublishOption('PUBLISH_SCHEDULE')"
        />
      </BaseFeatureLock>

      <!-- ... -->

      <!-- publish schedule -->

      <DateTimeField
        v-if="publishOption === 'PUBLISH_SCHEDULE'"
        :value="publishSchedule"
        class="q-mb-md"
        style="margin-left: 36px"
        @input="updatePublishSchedule"
      />

      <!-- ... -->

      <!-- unpublish now -->

      <RadioField
        :value="publishOption === 'UNPUBLISHED'"
        label="Unpublish Now"
        description="Bring back this from to draft now"
        class="q-mb-md"
        @input="updatePublishOption('UNPUBLISHED')"
      />

      <!-- ... -->

      <!-- schedule to unpublish -->

      <BaseFeatureLock v-if="checkFeature" position="CENTER_RIGHT">
        <RadioField
          :value="publishOption === 'UNPUBLISH_SCHEDULE'"
          label="Schedule to Unpublish"
          description="Bring back this form to draft later"
          @input="updatePublishOption('UNPUBLISH_SCHEDULE')"
        />
      </BaseFeatureLock>

      <!-- ... -->

      <!-- unpublish schedule -->

      <DateTimeField
        v-if="publishOption === 'UNPUBLISH_SCHEDULE'"
        :value="unpublishSchedule"
        class="q-mt-md"
        style="margin-left: 36px"
        @input="updateUnpublishSchedule"
      />

      <!-- ... -->
    </div>
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import RadioField from "@/components/common/form/radio-field/RadioField.vue";
import DateTimeField from "@/components/common/form/date-time-field/DateTimeField.vue";

export default {
  name: "PublishOptions",

  components: {
    FormSection,
    FormFieldLabel,
    RadioField,
    DateTimeField,
  },

  props: {
    publishOption: {
      type: String,
      required: true,
    },

    publishSchedule: {
      type: String,
      required: true,
    },

    unpublishSchedule: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },
  },

  computed: {
    checkFeature() {
      let origin = location.origin;
      if (
        origin === "http://localhost:8080" ||
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    updatePublishOption(option) {
      this.$emit("update:publishOption", option);
    },

    updatePublishSchedule(schedule) {
      this.$emit("update:publishSchedule", schedule);
    },

    updateUnpublishSchedule(schedule) {
      this.$emit("update:unpublishSchedule", schedule);
    },
  },
};
</script>

<style lang="scss" scoped>
.options-wrapper {
  border-top: 1px solid var(--divider-color);
  padding-top: 16px;
}
</style>
