<template>
  <div id="validation-settings">
    <!-- field rules -->

    <SingleChoiceField
      v-if="showFieldRule"
      :value="fieldRule"
      label="field rule"
      :options="fieldRules"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateFieldRule"
    />

    <!-- ... -->

    <!-- content rules -->

    <SingleChoiceField
      v-if="showContentRule"
      :value="contentRule"
      label="content rule"
      :options="_contentRules"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateContentRule"
    />

    <!-- ... -->

    <!-- range -->

    <div v-if="showRange" class="q-pa-md">
      <SingleChoiceField
        v-if="['NUMBER'].includes(fieldType)"
        :value="rangeType"
        label="Range"
        :options="rangeOptions"
        :options-per-line="1"
        @input="updateRangeType"
      />

      <div v-if="rangeType === 'MAXIMUM'" class="row q-col-gutter-sm q-mt-sm">
        <!-- maximum -->

        <!-- <NumberField
         :value="maximum"
         placeholder="Maximum"
         @input="updateMaximum"
       /> -->
        <NumberField
          :value="minimum"
          label="minimum"
          placeholder="Minimum"
          class="col-6"
          @input="updateMinimum"
        />

        <SelectField
          :value="maximumNumberField"
          label="maximum"
          :options="numberFields"
          :options-per-line="1"
          class="col-6"
          @input="updateMaximumField"
        />

        <!-- ... -->

        <!-- minimum -->

        <!-- <ValidationProvider
          v-slot="{ errors }"
          name="minimum"
          class="col-6"
          :rules="{
            lessThanMax: maximum ? { target: maximum } : false,
          }"
        > -->
        <!-- <NumberField
          :value="minimum"
          label="minimum"
          placeholder="Minimum"
          @input="updateMinimum"
        /> -->
        <!-- </ValidationProvider> -->

        <!-- ... -->
      </div>

      <div v-if="rangeType === 'MINIMUM'" class="row q-col-gutter-sm q-mt-sm">
        <!-- minimum -->

        <SelectField
          :value="minimumNumberField"
          label="minimum"
          :options="numberFields"
          :options-per-line="1"
          class="col-6"
          @input="updateMinimumField"
        />

        <!-- ... -->

        <!-- maximum -->

        <NumberField
          :value="maximum"
          placeholder="Maximum"
          label="Maximum"
          class="col-6"
          @input="updateMaximum"
        />

        <!-- ... -->
      </div>

      <template
        v-if="!['NUMBER'].includes(fieldType) || rangeType === 'CUSTOM'"
      >
        <FormFieldLabel
          v-if="!['NUMBER'].includes(fieldType)"
          :label="
            ['NUMBER', 'COUNTER'].includes(fieldType)
              ? 'range'
              : 'character limit'
          "
          class="field-label"
        />

        <div class="row q-col-gutter-sm q-mt-sm">
          <!-- minimum -->

          <ValidationProvider
            v-slot="{ errors }"
            name="minimum"
            class="col"
            :rules="{
              lessThanMax: maximum ? { target: maximum } : false,
            }"
          >
            <NumberField
              :value="minimum"
              placeholder="Minimum"
              :error="errors[0]"
              @input="updateMinimum"
            />
          </ValidationProvider>

          <!-- ... -->

          <!-- maximum -->

          <NumberField
            :value="maximum"
            placeholder="Maximum"
            @input="updateMaximum"
          />

          <!-- ... -->
        </div>
      </template>
    </div>

    <!-- ... -->

    <!-- date range -->

    <div v-if="fieldType === 'DATE'" class="q-pa-md">
      <SingleChoiceField
        :value="dateRange"
        label="Range"
        :options="dateRanges"
        :options-per-line="1"
        class="q-mb-md"
        @input="updateDateRange"
      />

      <div v-if="dateRange === 'MINI_CURRENT_DATE'" class="q-mb-md">
        <CounterField
          :value="maxiDays"
          label="How many days should be the maximum number of days from the current date?"
          @input="updateMaxiDays"
        />
      </div>

      <div v-if="dateRange === 'MAX_CURRENT_DATE'" class="q-mb-md">
        <CounterField
          :value="miniDays"
          label="How many days should be the minimum number of days from the current date?"
          @input="updateMiniDays"
        />
      </div>
      <!-- <FormFieldLabel label="range" class="field-label" /> -->

      <div v-if="dateRange === 'DATE_RANGE'">
        <div class="row q-col-gutter-sm">
          <!-- minimum -->

          <ValidationProvider
            v-slot="{ errors }"
            name="minimum"
            class="col"
            :rules="{
              lessThanMaxDate: maximum ? { target: maximum } : false,
            }"
          >
            <DateField
              :value="minimum"
              placeholder="Minimum"
              :error="errors[0]"
              @input="updateMinimum"
            />
          </ValidationProvider>

          <!-- ... -->

          <!-- maximum -->

          <ValidationProvider name="maximum" class="col">
            <DateField
              :value="maximum"
              placeholder="Maximum"
              @input="updateMaximum"
            />
          </ValidationProvider>
        </div>
        <!-- ... -->
        <div class="row">
          <FormFieldError v-if="dateError" :error="dateError" />
        </div>
      </div>
    </div>

    <!-- ... -->

    <!-- time range -->

    <div v-if="fieldType === 'TIME'" class="q-pa-md">
      <!-- <FormFieldLabel label="range" class="field-label" /> -->

      <SingleChoiceField
        :value="timeRange"
        label="Range"
        :options="timeRanges"
        :options-per-line="1"
        class="q-mb-md"
        @input="updateTimeRange"
      />

      <div v-if="timeRange === 'MINI_CURRENT_TIME'" class="q-mb-md">
        <CounterField
          :value="maxiTime"
          label="How many hours should be the maximum hours from the current time?"
          @input="updateMaxiTimes"
        />
      </div>

      <div v-if="timeRange === 'MAX_CURRENT_TIME'" class="q-mb-md">
        <CounterField
          :value="miniTime"
          label="How many hours should be the minimum hours from the current time?"
          @input="updateMiniTimes"
        />
      </div>

      <div v-if="timeRange === 'TIME_RANGE'">
        <div class="row q-col-gutter-sm">
          <!-- minimum -->

          <ValidationProvider
            v-slot="{ errors }"
            name="minimum"
            class="col"
            :rules="{
              lessThanMaxTime: maximum ? { target: maximum } : false,
            }"
          >
            <TimeField
              :value="minimum"
              placeholder="Minimum"
              :error="errors[0]"
              @input="updateMinimum"
            />
          </ValidationProvider>

          <!-- ... -->

          <!-- maximum -->

          <ValidationProvider name="maximum" class="col">
            <TimeField
              :value="maximum"
              placeholder="Maximum"
              @input="updateMaximum"
            />
          </ValidationProvider>

          <!-- ... -->
        </div>
        <div class="row">
          <FormFieldError v-if="timeError" :error="timeError" />
        </div>
      </div>
    </div>

    <!-- ... -->

    <!-- date time range -->

    <div v-if="fieldType === 'DATE_TIME'" class="q-pa-md">
      <FormFieldLabel label="range" class="field-label" />

      <!-- minimum -->

      <ValidationProvider
        v-slot="{ errors }"
        name="minimum"
        class="col"
        :rules="{
          lessThanMaxDate: maximum ? { target: maximum } : false,
        }"
      >
        <DateTimeField
          :value="minimum"
          :error="errors[0]"
          class="q-mb-sm"
          @input="updateMinimum"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- maximum -->

      <ValidationProvider name="maximum" class="col">
        <DateTimeField :value="maximum" @input="updateMaximum" />
      </ValidationProvider>

      <!-- ... -->
    </div>

    <!-- ... -->

    <!-- allowed file types -->

    <MultiSelectField
      v-if="fieldType === 'FILE_UPLOAD'"
      :value="allowedFileTypes"
      label="allowed file types"
      tooltip="By default, all the file types are allowed"
      :options="fileTypes"
      class="q-pa-md"
      @input="updateAllowedFileTypes"
    />

    <!-- ... -->

    <!-- max file size -->

    <ValidationProvider
      v-if="showMaxFileSize"
      v-slot="{ errors }"
      name="max file size"
      :rules="{ required: true, min_value: 1, max_value: 10 }"
    >
      <NumberField
        :value="maxFileSize"
        is-mandatory
        label="max file size (MB)"
        :is-clearable="false"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateMaxFileSize"
      />
    </ValidationProvider>

    <!-- ... -->

    <MultiSelectField
      v-if="fieldType === 'FILE_UPLOAD'"
      :value="assignOtherControls"
      label="Choose controls to auto-fill the values from the document"
      tooltip=""
      :options="fieldControls"
      class="q-pa-md"
      @input="updateAssignOtherControls"
    />

    <SelectField
      v-if="fieldType === 'FILE_UPLOAD'"
      :value="documentExpiryField"
      label="Choose document expiry field"
      tooltip=""
      :options="dateFields"
      class="q-pa-md"
      @input="updateDocumentExpiryField"
    />

    <!-- enable control -->
    <div v-if="showEnableControl" class="q-pa-md">
      <FormFieldLabel
        label="Enable controls based on the selected value"
        class="field-label"
        is-mandatory
      />
      <BaseScrollbar>
        <div>
          <table>
            <thead>
              <tr>
                <th class="medium">Value</th>
                <th class="large">Controls</th>
                <th class="action">
                  <BaseActionButton
                    is-flat
                    class="q-ml-xs"
                    color="secondary"
                    icon="eva-plus-outline"
                    no-border
                    @click="addRow"
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(row, index) in enableSettings" :key="index">
                <td>
                  <div class="row">
                    <SelectField
                      v-model="enableSettings[index]['value']"
                      class="col"
                      :options="options"
                      :is-clearable="false"
                    />

                    <!-- <MultiSelectField
                      v-else
                      v-model="enableSettings[index]['value']"
                      class="col"
                      :options="options"
                      :is-clearable="false"
                    /> -->
                  </div>
                </td>
                <td>
                  <MultiSelectField
                    v-model="enableSettings[index]['controls']"
                    :options="_panelFields"
                    :is-clearable="false"
                  />
                </td>
                <td class="action">
                  <BaseActionButton
                    is-flat
                    color="red"
                    icon="eva-close-outline"
                    no-border
                    @click="removeRow(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </BaseScrollbar>
    </div>
    <!-- ... -->

    <!-- mandatory control -->
    <div v-if="showEnableControl" class="q-pa-md">
      <FormFieldLabel
        label="Mandatory controls based on the selected value"
        class="field-label"
        is-mandatory
      />
      <BaseScrollbar>
        <div>
          <table>
            <thead>
              <tr>
                <th class="medium">Value</th>
                <th class="large">Controls</th>
                <th class="action">
                  <BaseActionButton
                    is-flat
                    class="q-ml-xs"
                    color="secondary"
                    icon="eva-plus-outline"
                    no-border
                    @click="addMandatoryRow"
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(row, index) in mandatorySettings" :key="index">
                <td>
                  <div class="row">
                    <SelectField
                      v-model="mandatorySettings[index]['value']"
                      class="col"
                      :options="options"
                      :is-clearable="false"
                    />

                    <!-- <MultiSelectField
                      v-else
                      v-model="enableSettings[index]['value']"
                      class="col"
                      :options="options"
                      :is-clearable="false"
                    /> -->
                  </div>
                </td>
                <td>
                  <MultiSelectField
                    v-model="mandatorySettings[index]['controls']"
                    :options="nonMandatoryFields"
                    :is-clearable="false"
                  />
                </td>
                <td class="action">
                  <BaseActionButton
                    is-flat
                    color="red"
                    icon="eva-close-outline"
                    no-border
                    @click="removeMandatoryRow(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </BaseScrollbar>
    </div>
    <!-- ... -->

    <!-- answer indicator -->
    <div
      v-if="showAnswerIndiactorOption && fieldRule === 'REQUIRED'"
      class="q-pa-md"
    >
      <SingleChoiceField
        :value="answerIndicator"
        label="answer status indicator"
        :options="answerStatusOptions"
        :options-per-line="1"
        class="q-mb-md"
        @input="updateAnswerIndicator"
      />
    </div>
    <!-- ... -->

    <!-- readonly controls -->

    <div v-if="showEnableControl" class="q-pa-md">
      <FormFieldLabel
        label="Readonly controls based on the selected value"
        class="field-label"
        is-mandatory
      />
      <BaseScrollbar>
        <div>
          <table>
            <thead>
              <tr>
                <th class="medium">Value</th>
                <th class="large">Controls</th>
                <th class="action">
                  <BaseActionButton
                    is-flat
                    class="q-ml-xs"
                    color="secondary"
                    icon="eva-plus-outline"
                    no-border
                    @click="addReadonlyRow"
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(row, index) in readonlySettings" :key="index">
                <td>
                  <div class="row">
                    <SelectField
                      v-model="readonlySettings[index]['value']"
                      class="col"
                      :options="options"
                      :is-clearable="false"
                    />

                    <!-- <MultiSelectField
                      v-else
                      v-model="enableSettings[index]['value']"
                      class="col"
                      :options="options"
                      :is-clearable="false"
                    /> -->
                  </div>
                </td>
                <td>
                  <MultiSelectField
                    v-model="readonlySettings[index]['controls']"
                    :options="panelFieldsWithTableColumns"
                    :is-clearable="false"
                  />
                </td>
                <td class="action">
                  <BaseActionButton
                    is-flat
                    color="red"
                    icon="eva-close-outline"
                    no-border
                    @click="removeReadonlyRow(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </BaseScrollbar>
    </div>

    <!-- ... -->

    <!-- time format -->

    <div
      v-if="fieldType === 'DATE_TIME' || fieldType === 'TIME'"
      class="q-pa-md"
    >
      <SingleChoiceField
        :value="timeFormat"
        label="Time Format"
        :options="timeFormats"
        :options-per-line="2"
        class="q-mb-md"
        @input="updateTimeFormat"
      />
    </div>
    <!-- ... -->
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import DateTimeField from "@/components/common/form/date-time-field/DateTimeField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import CounterField from "@/components/common/form/counter-field/CounterField.vue";
import { axiosCrypto } from "@/api/axios.js";
import FormFieldError from "@/components/common/form/FormFieldError";
export default {
  name: "ValidationSettings",

  components: {
    ValidationProvider,
    SingleChoiceField,
    FormFieldLabel,
    NumberField,
    DateField,
    TimeField,
    DateTimeField,
    MultiSelectField,
    SelectField,
    CounterField,
    FormFieldError,
  },

  props: {
    fieldType: {
      type: String,
      default: "",
    },

    fieldRule: {
      type: String,
      default: "",
    },

    contentRule: {
      type: String,
      default: "",
    },

    minimum: {
      type: [String, Number],
      default: "",
    },

    maximum: {
      type: [String, Number],
      default: "",
    },

    allowedFileTypes: {
      type: Array,
      default: () => [],
    },

    maxFileSize: {
      type: Number,
      default: 0,
    },

    settingsFor: {
      type: String,
      default: "",
    },

    customOptions: {
      type: String,
      default: "",
    },

    panels: {
      type: Array,
      default: () => [],
    },

    fieldId: {
      type: String,
      default: "",
    },

    enableSettings: {
      type: Array,
      default: () => [],
    },

    masterFormId: {
      type: Number,
      default: 0,
    },

    masterFormColumn: {
      type: String,
      default: "",
    },

    dateRange: {
      type: String,
      default: "DATE_RANGE",
    },

    timeRange: {
      type: String,
      default: "TIME_RANGE",
    },

    maxiDays: {
      type: Number,
      default: 0,
    },

    miniDays: {
      type: Number,
      default: 0,
    },

    maxiTime: {
      type: Number,
      default: 0,
    },

    miniTime: {
      type: Number,
      default: 0,
    },

    dateError: {
      type: String,
      default: "",
    },

    timeError: {
      type: String,
      default: "",
    },

    assignOtherControls: {
      type: Array,
      default: () => [],
    },

    mandatorySettings: {
      type: Array,
      default: () => [],
    },

    answerIndicator: {
      type: String,
      default: "",
    },

    readonlySettings: {
      type: Array,
      default: () => [],
    },

    documentExpiryField: {
      type: String,
      default: "",
    },

    timeFormat: {
      type: String,
      default: "",
    },

    rangeType: {
      type: String,
      default: "CUSTOM",
    },

    maximumNumberField: {
      type: String,
      default: "",
    },

    minimumNumberField: {
      type: String,
      default: "",
    },

    tableFieldId: {
      type: String,
      default: "",
    },

    separateOptionsUsing: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      fieldRules: [
        {
          id: this.$nano.id(),
          label: "Required",
          description: "Must have a non-empty value",
          value: "REQUIRED",
        },
        {
          id: this.$nano.id(),
          label: "Optional",
          description: "Can have an empty value",
          value: "OPTIONAL",
        },
      ],
      contentRules: [
        {
          id: this.$nano.id(),
          label: "Alpha",
          description: "May contain only alphabetic characters",
          value: "ALPHA",
          for: ["SHORT_TEXT", "LONG_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Alpha Spaces",
          description: "May contain only alphabetic characters and spaces",
          value: "ALPHA_SPACES",
          for: ["SHORT_TEXT", "LONG_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Alpha Dash",
          description:
            "May contain only alphabetic characters, numbers, dashes and underscores",
          value: "ALPHA_DASH",
          for: ["SHORT_TEXT", "LONG_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Alpha Numeric",
          description: "May contain alphabetic characters and numbers",
          value: "ALPHA_NUMERIC",
          for: ["SHORT_TEXT", "LONG_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Text",
          description: "May contain all characters",
          value: "TEXT",
          for: ["SHORT_TEXT", "LONG_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Email",
          description: "Must be a valid email address",
          value: "EMAIL",
          for: ["SHORT_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Web",
          description: "Must be a valid web address",
          value: "WEB",
          for: ["SHORT_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Integer",
          description: "Does not accept decimals",
          value: "INTEGER",
          for: ["NUMBER"],
        },
        {
          id: this.$nano.id(),
          label: "Decimal",
          description: "Does accept decimals",
          value: "DECIMAL",
          for: ["NUMBER", "CURRENCY"],
        },
        {
          id: this.$nano.id(),
          label: "Commas",
          description: "Does accept commas",
          value: "COMMAS",
          for: ["CURRENCY", "NUMBER"],
        },
        {
          id: this.$nano.id(),
          label: "Both",
          description: "Does accept commas and decimals",
          value: "BOTH",
          for: ["CURRENCY", "NUMBER"],
        },
      ],
      fileTypes: [
        {
          id: this.$nano.id(),
          label: "pdf",
          value: "pdf",
        },
        {
          id: this.$nano.id(),
          label: "doc",
          value: "doc",
        },
        {
          id: this.$nano.id(),
          label: "docx",
          value: "docx",
        },
        {
          id: this.$nano.id(),
          label: "xls",
          value: "xls",
        },
        {
          id: this.$nano.id(),
          label: "xlsx",
          value: "xlsx",
        },
        {
          id: this.$nano.id(),
          label: "ppt",
          value: "ppt",
        },
        {
          id: this.$nano.id(),
          label: "pptx",
          value: "pptx",
        },
        {
          id: this.$nano.id(),
          label: "txt",
          value: "txt",
        },
        {
          id: this.$nano.id(),
          label: "csv",
          value: "csv",
        },
        {
          id: this.$nano.id(),
          label: "jpg",
          value: "jpg",
        },
        {
          id: this.$nano.id(),
          label: "jpeg",
          value: "jpeg",
        },
        {
          id: this.$nano.id(),
          label: "gif",
          value: "gif",
        },
        {
          id: this.$nano.id(),
          label: "png",
          value: "png",
        },
        {
          id: this.$nano.id(),
          label: "tif",
          value: "tif",
        },
        {
          id: this.$nano.id(),
          label: "tiff",
          value: "tiff",
        },
        {
          id: this.$nano.id(),
          label: "mp3",
          value: "mp3",
        },
        {
          id: this.$nano.id(),
          label: "wav",
          value: "wav",
        },
        {
          id: this.$nano.id(),
          label: "mp4",
          value: "mp4",
        },
        {
          id: this.$nano.id(),
          label: "wmv",
          value: "wmv",
        },
        {
          id: this.$nano.id(),
          label: "mov",
          value: "mov",
        },
      ],
      options: [],
      dateRanges: [
        {
          id: this.$nano.id(),
          label: "Minimum Date",
          description: "Set the current date as minimum date",
          value: "MINI_CURRENT_DATE",
        },
        {
          id: this.$nano.id(),
          label: "Maximum Date",
          description: "Set the current date as maximum date",
          value: "MAX_CURRENT_DATE",
        },
        {
          id: this.$nano.id(),
          label: "Select Date",
          description: "Use range between two dates",
          value: "DATE_RANGE",
        },
        {
          id: this.$nano.id(),
          label: "None",
          description: "No selection required",
          value: "NONE",
        },
      ],
      timeRanges: [
        {
          id: this.$nano.id(),
          label: "Minimum Time",
          description: "Set the current time as minimum time",
          value: "MINI_CURRENT_TIME",
        },
        {
          id: this.$nano.id(),
          label: "Maximum Time",
          description: "Set the current time as maximum time",
          value: "MAX_CURRENT_TIME",
        },
        {
          id: this.$nano.id(),
          label: "Select Time",
          description: "Use range between two times",
          value: "TIME_RANGE",
        },
        {
          id: this.$nano.id(),
          label: "None",
          description: "No selection required",
          value: "NONE",
        },
      ],
      answerStatusOptions: [
        {
          id: this.$nano.id(),
          label: "Yes",
          description:
            "Indicator confirms that the question has been addressed",
          value: "YES",
        },
        {
          id: this.$nano.id(),
          label: "No",
          description:
            "Indicator signifies that the question hast not yet beed addressed, promoting the user to take neccessorry action",
          value: "NO",
        },
      ],

      timeFormats: [
        {
          id: this.$nano.id(),
          label: "12",
          value: "12",
        },
        {
          id: this.$nano.id(),
          label: "24",
          value: "24",
        },
      ],
      rangeOptions: [
        {
          id: this.$nano.id(),
          label: "Minimum Fixed, Maximum Flexible",
          description:
            "Set the minimum value directly and assign the maximum value from another field",
          value: "MAXIMUM",
        },
        {
          id: this.$nano.id(),
          label: "Minimum Flexible, Maximum Fixed",
          description:
            "Set the maximum value directly and assign the minimum value from another field",
          value: "MINIMUM",
        },
        {
          id: this.$nano.id(),
          label: "Custom Both Minumum and Maximum",
          description: "Set the minimum value and maximum directly",
          value: "CUSTOM",
        },
      ],
    };
  },

  computed: {
    showFieldRule() {
      return [
        "LABEL",
        "SHORT_TEXT",
        "LONG_TEXT",
        "NUMBER",
        "COUNTER",
        "DATE",
        "TIME",
        "DATE_TIME",
        "SINGLE_SELECT",
        "MULTI_SELECT",
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
        "CHIPS",
        "IMAGE",
        "SIGNATURE",
        "RATING",
        "CHECKBOX",
        "RADIO",
        "NESTED_LIST",
        "TEXT_BUILDER",
        "PASSWORD",
        "CURRENCY",
        "FILE_UPLOAD",
        "COUNTRY_CODE",
      ].includes(this.fieldType);
    },

    showContentRule() {
      return ["SHORT_TEXT", "LONG_TEXT", "NUMBER", "CURRENCY"].includes(
        this.fieldType
      );
    },

    _contentRules() {
      return this.contentRules.filter((option) =>
        option.for.includes(this.fieldType)
      );
    },

    showRange() {
      return ["SHORT_TEXT", "LONG_TEXT", "NUMBER", "COUNTER"].includes(
        this.fieldType
      );
    },

    showMaxFileSize() {
      return ["FILE_UPLOAD", "IMAGE", "SIGNATURE"].includes(this.fieldType);
    },

    _panelFields() {
      if (!this.panels) return [];
      let panelIndex = 1;
      let fieldList = [];
      let fieldControls = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
        if (panel.fields.length) {
          fieldControls.push({
            id: panel.id,
            label: panel.settings.title
              ? panel.settings.title + " (Panel)"
              : "PANEL-" + panelIndex,
            value: panel.id,
          });
          panelIndex += 1;
        }
      }
      fieldList.forEach((field) => {
        if (field.type !== "DIVIDER") {
          if (field.id !== this.fieldId) {
            if (field.type === "TABLE") {
              fieldControls.push({
                id: this.$nano.id(),
                label: field.label ? field.label + " (TABLE)" : field.type,
                value: field.id,
              });
            } else {
              fieldControls.push({
                id: this.$nano.id(),
                label: field.label ? field.label + " (Control)" : field.type,
                value: field.id,
              });
            }
          }
        }
      });
      return fieldControls;
    },

    showEnableControl() {
      return [
        "SINGLE_SELECT",
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
        "MULTI_SELECT",
      ].includes(this.fieldType);
    },

    hasMultipleValue() {
      return ["SINGLE_SELECT", "SINGLE_CHOICE"].includes(this.fieldType);
    },

    fieldControls() {
      if (!this.panels) return [];

      let fieldList = [];
      let fieldControls = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
      }
      if (this.settingsFor === "NORMAL") {
        fieldList.forEach((field) => {
          if (field.type !== "DIVIDER" && field.type !== "TABLE") {
            if (field.id !== this.fieldId) {
              fieldControls.push({
                id: this.$nano.id(),
                label: field.label ? field.label : field.type,
                value: field.id,
              });
            }
          }
        });
      } else if (this.settingsFor === "TABLE") {
        let tableField = fieldList.find(
          (table) => table.id === this.tableFieldId
        );
        if (tableField) {
          let columns = tableField.settings.specific.tableColumns;
          columns.forEach((column) => {
            if (column.id !== this.fieldId) {
              fieldControls.push({
                id: this.$nano.id(),
                label: column.label ? column.label : column.type,
                value: column.id,
              });
            }
          });
        }
      }
      return fieldControls;
    },

    nonMandatoryFields() {
      if (!this.panels) return [];
      // let panelIndex = 1;
      let fieldList = [];
      let fieldControls = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
        // if (panel.fields.length) {
        //   fieldControls.push({
        //     id: panel.id,
        //     label: panel.settings.title
        //       ? panel.settings.title + " (Panel)"
        //       : "PANEL-" + panelIndex,
        //     value: panel.id,
        //   });
        //   panelIndex += 1;
        // }
      }
      fieldList.forEach((field) => {
        if (field.type !== "DIVIDER") {
          if (field.id !== this.fieldId) {
            if (field.type === "TABLE") {
              fieldControls.push({
                id: this.$nano.id(),
                label: field.label ? field.label + " (TABLE)" : field.type,
                value: field.id,
              });

              field.settings.specific.tableColumns.forEach((table) => {
                fieldControls.push({
                  id: this.$nano.id(),
                  label: table.label
                    ? table.label + " (TABLE - COLUMN)"
                    : table.type,
                  value: `${table.id}`,
                });
              });
            } else {
              fieldControls.push({
                id: this.$nano.id(),
                label: field.label ? field.label + " (Control)" : field.type,
                value: field.id,
              });
            }
          }
        }
      });
      return fieldControls;
    },

    showAnswerIndiactorOption() {
      return [
        "SHORT_TEXT",
        "LONG_TEXT",
        "NUMBER",
        "COUNTER",
        "DATE",
        "TIME",
        "DATE_TIME",
        "SINGLE_SELECT",
        "MULTI_SELECT",
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
        "CHIPS",
        "IMAGE",
        "SIGNATURE",
        "RATING",
        "CHECKBOX",
        "RADIO",
        "NESTED_LIST",
        "TEXT_BUILDER",
        "PASSWORD",
        "CURRENCY",
        "FILE_UPLOAD",
        "COUNTRY_CODE",
      ].includes(this.fieldType);
    },

    panelFieldsWithTableColumns() {
      if (!this.panels) return [];
      let panelIndex = 1;
      let fieldList = [];
      let fieldControls = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
        if (panel.fields.length) {
          fieldControls.push({
            id: panel.id,
            label: panel.settings.title
              ? panel.settings.title + " (Panel)"
              : "PANEL-" + panelIndex,
            value: panel.id,
          });
          panelIndex += 1;
        }
      }
      fieldList.forEach((field) => {
        if (field.type !== "DIVIDER") {
          if (field.id !== this.fieldId) {
            if (field.type === "TABLE") {
              fieldControls.push({
                id: this.$nano.id(),
                label: field.label ? field.label + " (TABLE)" : field.type,
                value: field.id,
              });

              field.settings.specific.tableColumns.forEach((table) => {
                fieldControls.push({
                  id: this.$nano.id(),
                  label: table.label
                    ? table.label + " (TABLE - COLUMN)"
                    : table.type,
                  value: `${table.id}`,
                });
              });
            } else {
              fieldControls.push({
                id: this.$nano.id(),
                label: field.label ? field.label + " (Control)" : field.type,
                value: field.id,
              });
            }
          }
        }
      });
      return fieldControls;
    },

    dateFields() {
      if (!this.panels) return [];
      let fieldList = [];
      let fields = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
      }

      fieldList.forEach((field) => {
        if (this.settingsFor === "NORMAL") {
          if (field.id !== this.fieldId) {
            if (field.type === "DATE") {
              fields.push({
                id: this.$nano.id(),
                label: field.label,
                value: field.id,
              });
            }
          }
        } else if (this.settingsFor === "TABLE") {
          console.log(field, this.tableFieldId, "table");
          if (field.id === this.tableFieldId) {
            let columns = field.settings.specific.tableColumns;
            columns.forEach((column) => {
              if (column.type === "DATE") {
                fields.push({
                  id: this.$nano.id(),
                  label: column.label,
                  value: column.id,
                });
              }
            });
          }
        }
      });

      return fields;
    },

    numberFields() {
      if (!this.panels) return [];
      let fieldList = [];
      let fields = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
      }

      fieldList.forEach((field) => {
        if (field.id !== this.fieldId) {
          if (field.type === "NUMBER") {
            fields.push({
              id: this.$nano.id(),
              label: field.label,
              value: field.id,
            });
          }
        }
      });

      return fields;
    },
  },

  watch: {
    enableSettings: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit("update:enableSettings", this.enableSettings);
      },
    },

    customOptions: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.customOptions) {
          this.getOptions();
        }
      },
    },

    masterFormColumn: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.masterFormId && this.masterFormColumn) {
          this.getOptions();
        }
      },
    },

    mandatorySettings: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit("update:mandatorySettings", this.mandatorySettings);
      },
    },

    readonlySettings: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit("update:readonlySettings", this.readonlySettings);
      },
    },
  },

  mounted() {
    if (this.enableSettings.length === 0) {
      this.addRow();
    }

    if (this.mandatorySettings.length === 0) {
      this.addMandatoryRow();
    }

    if (this.readonlySettings.length === 0) {
      this.addReadonlyRow();
    }
  },

  methods: {
    updateFieldRule(rule) {
      this.$emit("update:fieldRule", rule);
    },

    updateContentRule(rule) {
      this.$emit("update:contentRule", rule);
    },

    updateMinimum(value) {
      if (this.maximum === "Current Date") {
        this.$emit("update:minimum", "");
      } else {
        this.$emit("update:minimum", value);
      }
    },

    updateMaximum(value) {
      if (this.minimum === "Current Date") {
        this.$emit("update:maximum", "");
      } else {
        this.$emit("update:maximum", value);
      }
    },

    updateAllowedFileTypes(types) {
      this.$emit("update:allowedFileTypes", types);
    },

    updateMaxFileSize(size) {
      this.$emit("update:maxFileSize", size);
    },

    addRow() {
      const model = {
        id: this.$nano.id(),
        value: "",
        controls: [],
      };

      this.enableSettings.push(model);
    },

    removeRow(rowIdx) {
      this.enableSettings.splice(rowIdx, 1);
    },

    async getOptions() {
      this.options = [];
      if (this.customOptions) {
        const separator = this.separateOptionsUsing === "NEWLINE" ? "\n" : ",";

        this.options = this.customOptions.split(separator).map((option) => ({
          id: this.$nano.id(),
          label: option.trim(),
          value: option.trim(),
        }));
      } else if (this.masterFormId) {
        try {
          const response = await axiosCrypto.post(
            `/form/${this.masterFormId}/uniqueColumnValues`,
            JSON.stringify({
              column: this.masterFormColumn,
              keyword: "",
              rowFrom: 0,
              rowCount: 0, // + 10,
            })
          );
          const { status, data } = response;
          if (status !== 200) {
            throw response;
          }
          const optionValues = JSON.parse(data);
          if (optionValues.length) {
            this.options = optionValues.map((option) => ({
              id: this.$nano.id(),
              label: option,
              value: option,
            }));
          }
        } catch (e) {
          console.error(e);
          this.$alert.error("Error fetching options");
        }
      }
    },

    updateDateRange(value) {
      this.$emit("update:minimum", "");
      this.$emit("update:maximum", "");
      this.$emit("update:miniDays", 0);
      this.$emit("update:maxiDays", 0);
      this.$emit("update:dateRange", value);
    },

    updateTimeRange(value) {
      this.$emit("update:minimum", "");
      this.$emit("update:maximum", "");
      this.$emit("update:miniTime", 0);
      this.$emit("update:maxiTime", 0);
      this.$emit("update:timeRange", value);
    },

    updateMaxiDays(value) {
      this.$emit("update:maxiDays", value);
    },

    updateMiniDays(value) {
      this.$emit("update:miniDays", value);
    },

    updateMaxiTimes(value) {
      this.$emit("update:maxiTime", value);
    },

    updateMiniTimes(value) {
      this.$emit("update:miniTime", value);
    },

    updateAssignOtherControls(value) {
      this.$emit("update:assignOtherControls", value);
    },

    updateDocumentExpiryField(value) {
      this.$emit("update:documentExpiryField", value);
    },

    addMandatoryRow() {
      const model = {
        id: this.$nano.id(),
        value: "",
        controls: [],
      };

      this.mandatorySettings.push(model);
    },

    removeMandatoryRow(rowIdx) {
      this.mandatorySettings.splice(rowIdx, 1);
    },

    updateAnswerIndicator(value) {
      this.$emit("update:answerIndicator", value);
    },

    addReadonlyRow() {
      const model = {
        id: this.$nano.id(),
        value: "",
        controls: [],
      };

      this.readonlySettings.push(model);
    },

    removeReadonlyRow(rowIdx) {
      this.readonlySettings.splice(rowIdx, 1);
    },

    updateTimeFormat(value) {
      this.$emit("update:timeFormat", value);
    },

    updateRangeType(value) {
      this.$emit("update:minimum", "");
      this.$emit("update:maximum", "");
      this.$emit("update:minimumNumberField", "");
      this.$emit("update:maximumNumberField", "");

      this.$emit("update:rangeType", value);
    },

    updateMaximumField(value) {
      this.$emit("update:minimumNumberField", "");

      this.$emit("update:maximumNumberField", value);
    },

    updateMinimumField(value) {
      this.$emit("update:maximumNumberField", "");

      this.$emit("update:minimumNumberField", value);
    },
  },
};
</script>

<style lang="scss" scoped>
#validation-settings {
  .field-label {
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 10px;
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 80px;
      }

      &.medium {
        width: 150px;
      }

      &.large {
        width: 210px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td {
      padding: 4px;
      vertical-align: top;
    }
  }
}
</style>
