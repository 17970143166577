<template>
  <div id="form-builder">
    <FormFields :active-panel-type="activePanelType" />

    <Form
      class="col"
      :form="form"
      :active-panel-type="activePanelType"
      :is-loading="isLoading"
      @switch-panel-type="switchPanelType"
      @update-panels="updatePanels"
      @update-secondary-panels="updateSecondaryPanels"
      @settings="showSettings"
      @save="saveForm"
    />
  </div>
</template>

<script>
import FormFields from "./components/FormFields.vue";
import Form from "./components/form/Form.vue";

export default {
  name: "FormBuilder",

  components: { FormFields, Form },

  props: {
    form: {
      type: Object,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activePanelType: "PRIMARY_PANELS",
    };
  },

  methods: {
    switchPanelType(type) {
      this.activePanelType = type;
    },

    updatePanels(panels) {
      this.$emit("update-panels", panels);
    },

    updateSecondaryPanels(panels) {
      this.$emit("update-secondary-panels", panels);
    },

    showSettings() {
      this.$emit("settings");
    },

    saveForm() {
      this.$emit("save");
    },
  },
};
</script>

<style lang="scss" scoped>
#form-builder {
  min-height: calc(100vh - 68px);
  background-color: var(--body-bg-color);
  display: flex;
}
</style>
