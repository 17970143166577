<template>
  <Sheet
    :value="value"
    width="720px"
    no-padding
    has-footer
    @input="closeSettings"
  >
    <!-- title -->

    <template #title>
      {{ _activeTab }} Settings - {{ field.label || field.type }}
    </template>

    <!-- ... -->

    <!-- form -->

    <template #default>
      <div id="field-settings" class="row">
        <!-- ... -->

        <div class="content">
          <ValidationObserver ref="form">
            <!-- general settings -->

            <GeneralSettings
              v-show="activeTab === 'GENERAL'"
              :settings-for="settingsFor"
              :field-type="field.type"
              :label.sync="label"
              :size.sync="size"
              :visibility.sync="visibility"
              :hide-label.sync="hideLabel"
              :placeholder.sync="placeholder"
              :tooltip.sync="tooltip"
              :divider-type.sync="dividerType"
            />

            <!-- ... -->

            <!-- specific settings -->

            <SpecificSettings
              v-show="activeTab === 'SPECIFIC'"
              :settings-for="settingsFor"
              :secondary-panels="secondaryPanels"
              :field-type="field.type"
              :options-type.sync="optionsType"
              :master-table.sync="masterTable"
              :master-table-column.sync="masterTableColumn"
              :predefined-table.sync="predefinedTable"
              :predefined-table-column.sync="predefinedTableColumn"
              :custom-options.sync="customOptions"
              :separate-options-using.sync="separateOptionsUsing"
              :allow-to-add-new-options.sync="allowToAddNewOptions"
              :options-per-line.sync="optionsPerLine"
              :default-value.sync="defaultValue"
              :custom-default-value.sync="customDefaultValue"
              :auto-generate-value.sync="autoGenerateValue"
              :allow-multiple-files.sync="allowMultipleFiles"
              :show-column-total.sync="showColumnTotal"
              :rating-icon.sync="ratingIcon"
              :rating-icon-count.sync="ratingIconCount"
              :allow-half-rating.sync="allowHalfRating"
              :allow-multiple-signatures.sync="allowMultipleSignatures"
              :table-columns.sync="tableColumns"
              :table-rows-type.sync="tableRowsType"
              :table-fixed-row-count.sync="tableFixedRowCount"
              :qr-value.sync="qrValue"
              :matrix-columns.sync="matrixColumns"
              :matrix-rows.sync="matrixRows"
              :matrix-type.sync="matrixType"
              :matrix-type-settings.sync="matrixTypeSettings"
              :fib-fields="fibFields"
              :minimum="minimum"
              :maximum="maximum"
              :tab-list.sync="tabList"
              :popup-trigger-type.sync="popupTriggerType"
              :secondary-panel.sync="secondaryPanel"
              :mapped-field-id.sync="mappedFieldId"
              :mapped-column-id.sync="mappedColumnId"
              :nested-list-max-level.sync="nestedListMaxLevel"
              :nested-list-field-type.sync="nestedListFieldType"
              :nested-list-type-settings.sync="nestedListTypeSettings"
              :nested-list-items-per-line.sync="nestedListItemsPerLine"
              :login-type.sync="loginType"
              :additional-login-types.sync="additionalLoginTypes"
              @fib-settings="showFibFieldSettings"
              @column-settings="showColumnSettings"
              @matrix-type-settings="showMatrixTypeSettings"
              @nested-list-type-settings="showNestedListTypeSettings"
            />

            <!-- ... -->

            <!-- validation -->

            <ValidationSettings
              v-show="activeTab === 'VALIDATION'"
              :settings-for="settingsFor"
              :field-type="field.type"
              :field-rule.sync="fieldRule"
              :content-rule.sync="contentRule"
              :minimum.sync="minimum"
              :maximum.sync="maximum"
              :allowed-file-types.sync="allowedFileTypes"
              :max-file-size.sync="maxFileSize"
            />

            <!-- ... -->
          </ValidationObserver>
        </div>

        <!-- ... -->

        <!-- tabs -->

        <div class="tabs">
          <BaseActionButton
            v-for="tab in tabs"
            :key="tab.id"
            v-tooltip.left="tab.label"
            :is-flat="activeTab !== tab.value"
            :icon="tab.icon"
            :color="activeTab === tab.value ? tabColor : 'gray'"
            class="q-mb-md"
            @click="activeTab = tab.value"
          />
        </div>

        <!-- ... -->
      </div>
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton
        label="cancel"
        is-flat
        :color="tabColor"
        class="q-mr-sm"
        @click="closeSettings"
      />

      <BaseButton label="save" :color="tabColor" @click="save" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { cloneDeep, isEmpty, lowerCase } from "lodash-es";
import Sheet from "@/components/common/popup/Sheet.vue";
import GeneralSettings from "./components/GeneralSettings.vue";
import SpecificSettings from "./components/specific-settings/SpecificSettings.vue";
import ValidationSettings from "./components/ValidationSettings.vue";

export default {
  name: "FieldSettings",

  components: {
    Sheet,
    ValidationObserver,
    GeneralSettings,
    SpecificSettings,
    ValidationSettings,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    field: {
      type: Object,
      default: () => {},
    },

    settingsFor: {
      type: String,
      default: "NORMAL",
      validator(value) {
        return [
          "NORMAL",
          "FILL_IN_THE_BLANKS",
          "TABLE",
          "MATRIX",
          "NESTED_LIST",
        ].includes(value);
      },
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      tabs: [
        {
          id: this.$nano.id(),
          label: "general",
          value: "GENERAL",
          icon: "eva-options-2-outline",
        },
        {
          id: this.$nano.id(),
          label: "specific",
          value: "SPECIFIC",
          icon: "mdi-code-tags-check",
        },
        {
          id: this.$nano.id(),
          label: "validation",
          value: "VALIDATION",
          icon: "mdi-function-variant",
        },
      ],
      activeTab: "GENERAL",

      // general settings

      hideLabel: false,
      label: "",
      size: "",
      visibility: "",
      placeholder: "",
      tooltip: "",
      dividerType: "",

      // ...

      // specific settings

      optionsType: "",
      masterTable: "",
      masterTableColumn: "",
      predefinedTable: "",
      predefinedTableColumn: "",
      customOptions: "",
      separateOptionsUsing: "",
      allowToAddNewOptions: false,
      optionsPerLine: 0,
      defaultValue: "",
      customDefaultValue: "",
      autoGenerateValue: {
        prefix: "Form",
        suffix: "DATE_TIME",
      },
      showColumnTotal: false,
      allowMultipleFiles: false,
      ratingIcon: "",
      ratingIconCount: 5,
      allowHalfRating: false,
      allowMultipleSignatures: false,
      tableColumns: [],
      tableRowsType: "",
      tableFixedRowCount: 0,
      qrValue: false,
      matrixColumns: [],
      matrixRows: [],
      matrixType: "",
      matrixTypeSettings: {},
      tabList: [],
      popupTriggerType: "",
      secondaryPanel: "",
      mappedFieldId: "",
      mappedColumnId: "",
      nestedListMaxLevel: 0,
      nestedListFieldType: "SHORT_TEXT",
      nestedListItemsPerLine: [],
      nestedListTypeSettings: {},
      loginType: "",
      additionalLoginTypes: [],
      // ...

      // validation settings

      fieldRule: "",
      contentRule: "",
      minimum: "",
      maximum: "",
      allowedFileTypes: [],
      maxFileSize: 0,

      // ...

      // fib fields

      fibFields: [],

      // ...
    };
  },

  computed: {
    _activeTab() {
      return lowerCase(this.activeTab);
    },

    tabColor() {
      return this.settingsFor === "NORMAL" ? "primary" : "secondary";
    },

    getCustomDefaultValue() {
      switch (this.field.type) {
        case "MULTI_SELECT":
        case "MULTIPLE_CHOICE":
          return [];
        default:
          return "";
      }
    },
  },

  watch: {
    field: {
      immediate: true,
      deep: true,
      handler() {
        if (!isEmpty(this.field)) {
          // general settings

          this.hideLabel = this.field.settings.general.hideLabel;
          this.label = this.field.label;
          this.size = this.field.settings.general.size;
          this.visibility = this.field.settings.general.visibility;
          this.placeholder = this.field.settings.general.placeholder;
          this.tooltip = this.field.settings.general.tooltip;
          this.dividerType = this.field.settings.general.dividerType;

          // ...

          // specific settings

          this.optionsType = this.field.settings.specific.optionsType;
          this.masterTable = this.field.settings.specific.masterTable;
          this.masterTableColumn =
            this.field.settings.specific.masterTableColumn;
          this.predefinedTable = this.field.settings.specific.predefinedTable;
          this.predefinedTableColumn =
            this.field.settings.specific.predefinedTableColumn;
          this.customOptions = this.field.settings.specific.customOptions;
          this.separateOptionsUsing =
            this.field.settings.specific.separateOptionsUsing;
          this.allowToAddNewOptions =
            this.field.settings.specific.allowToAddNewOptions;
          this.optionsPerLine = this.field.settings.specific.optionsPerLine;
          this.defaultValue = this.field.settings.specific.defaultValue;
          this.customDefaultValue =
            this.field.settings.specific.customDefaultValue;
          this.autoGenerateValue =
            this.field.settings.specific.autoGenerateValue;
          this.showColumnTotal = this.field.settings.specific.showColumnTotal;
          this.allowMultipleFiles =
            this.field.settings.specific.allowMultipleFiles;
          this.ratingIcon = this.field.settings.specific.ratingIcon;
          this.ratingIconCount = this.field.settings.specific.ratingIconCount;
          this.allowHalfRating = this.field.settings.specific.allowHalfRating;
          this.allowMultipleSignatures =
            this.field.settings.specific.allowMultipleSignatures;
          this.tableColumns = this.field.settings.specific.tableColumns;
          this.tableRowsType = this.field.settings.specific.tableRowsType;
          this.tableFixedRowCount =
            this.field.settings.specific.tableFixedRowCount;
          this.qrValue = this.field.settings.specific.qrValue;
          this.matrixColumns = this.field.settings.specific.matrixColumns;
          this.matrixRows = this.field.settings.specific.matrixRows;
          this.matrixType = this.field.settings.specific.matrixType;
          this.matrixTypeSettings =
            this.field.settings.specific.matrixTypeSettings;
          this.fibFields = this.field.settings.specific.fibFields;
          this.tabList = this.field.settings.specific.tabList;
          this.popupTriggerType = this.field.settings.specific.popupTriggerType;
          this.secondaryPanel = this.field.settings.specific.secondaryPanel;
          this.mappedFieldId = this.field.settings.specific.mappedFieldId;
          this.mappedColumnId = this.field.settings.specific.mappedColumnId;
          this.nestedListMaxLevel =
            this.field.settings.specific.nestedListMaxLevel;
          this.nestedListFieldType =
            this.field.settings.specific.nestedListFieldType;
          this.nestedListTypeSettings =
            this.field.settings.specific.nestedListTypeSettings;
          this.nestedListItemsPerLine =
            this.field.settings.specific.nestedListItemsPerLine;
          this.loginType = this.field.settings.specific.loginType;
          this.additionalLoginTypes =
            this.field.settings.specific.additionalLoginTypes;
          // ...

          // validation settings

          this.fieldRule = this.field.settings.validation.fieldRule;
          this.contentRule = this.field.settings.validation.contentRule;
          this.minimum = this.field.settings.validation.minimum;
          this.maximum = this.field.settings.validation.maximum;
          this.allowedFileTypes =
            this.field.settings.validation.allowedFileTypes;
          this.maxFileSize = this.field.settings.validation.maxFileSize;

          // ...
        }
      },
    },
  },

  mounted() {
    if (["LOGIN_AUTHENTICATION"].includes(this.field.type)) {
      this.activeTab = "SPECIFIC";
    }
  },

  methods: {
    closeSettings() {
      this.$emit("input", false);
    },

    async save() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      const field = cloneDeep(this.field);

      // general settings

      field.settings.general.hideLabel = this.hideLabel;
      field.label = this.label;
      field.settings.general.size = this.size;
      field.settings.general.visibility = this.visibility;
      field.settings.general.placeholder = this.placeholder;
      field.settings.general.tooltip = this.tooltip;
      field.settings.general.dividerType = this.dividerType;

      // ...

      // specific settings

      field.settings.specific.optionsType = this.optionsType;
      field.settings.specific.masterTable = this.masterTable;
      field.settings.specific.masterTableColumn = this.masterTableColumn;
      field.settings.specific.predefinedTable = this.predefinedTable;
      field.settings.specific.predefinedTableColumn =
        this.predefinedTableColumn;
      field.settings.specific.customOptions = this.customOptions;
      field.settings.specific.separateOptionsUsing = this.separateOptionsUsing;
      field.settings.specific.allowToAddNewOptions = this.allowToAddNewOptions;
      field.settings.specific.optionsPerLine = this.optionsPerLine;
      field.settings.specific.defaultValue = this.defaultValue;
      field.settings.specific.customDefaultValue = this.customDefaultValue
        ? this.customDefaultValue
        : this.getCustomDefaultValue;
      field.settings.specific.autoGenerateValue = this.autoGenerateValue;
      field.settings.specific.showColumnTotal = this.showColumnTotal;
      field.settings.specific.allowMultipleFiles = this.allowMultipleFiles;
      field.settings.specific.ratingIcon = this.ratingIcon;
      field.settings.specific.ratingIconCount = this.ratingIconCount;
      field.settings.specific.allowHalfRating = this.allowHalfRating;
      field.settings.specific.allowMultipleSignatures =
        this.allowMultipleSignatures;
      field.settings.specific.tableColumns = this.tableColumns;
      field.settings.specific.tableRowsType = this.tableRowsType;
      field.settings.specific.tableFixedRowCount = this.tableFixedRowCount;
      field.settings.specific.qrValue = this.qrValue;
      field.settings.specific.matrixColumns = this.matrixColumns;
      field.settings.specific.matrixRows = this.matrixRows;
      field.settings.specific.matrixType = this.matrixType;
      field.settings.specific.matrixTypeSettings = this.matrixTypeSettings;
      field.settings.specific.fibFields = this.fibFields;
      field.settings.specific.tabList = this.tabList;
      field.settings.specific.popupTriggerType = this.popupTriggerType;
      field.settings.specific.secondaryPanel = this.secondaryPanel;
      field.settings.specific.mappedFieldId = this.mappedFieldId;
      field.settings.specific.mappedColumnId = this.mappedColumnId;
      field.settings.specific.nestedListMaxLevel = this.nestedListMaxLevel;
      field.settings.specific.nestedListFieldType = this.nestedListFieldType;
      field.settings.specific.nestedListTypeSettings =
        this.nestedListTypeSettings;
      field.settings.specific.nestedListItemsPerLine =
        this.nestedListItemsPerLine;
      field.settings.specific.loginType = this.loginType;
      field.settings.specific.additionalLoginTypes = this.additionalLoginTypes;
      // ...

      // validation settings

      field.settings.validation.fieldRule = this.fieldRule;
      field.settings.validation.contentRule = this.contentRule;
      field.settings.validation.minimum = this.minimum;
      field.settings.validation.maximum = this.maximum;
      field.settings.validation.allowedFileTypes = this.allowedFileTypes;
      field.settings.validation.maxFileSize = this.maxFileSize;

      // ...

      this.$emit("save", field);
      this.closeSettings();
    },

    showFibFieldSettings(fibField) {
      this.$emit("fib-settings", fibField);
    },

    showColumnSettings(column) {
      this.$emit("column-settings", column);
    },

    showMatrixTypeSettings() {
      this.$emit("matrix-type-settings");
    },

    showNestedListTypeSettings() {
      this.$emit("nested-list-type-settings");
    },
  },
};
</script>

<style lang="scss" scoped>
#field-settings {
  .field {
    margin: 16px 16px 24px 16px;
  }

  .content {
    flex: 1;
    padding-bottom: 36px;
    border-right: 1px solid var(--divider-color);
  }

  .tabs {
    width: 68px;
    height: 100%;
    height: calc(100vh - 112px);
    padding: 16px;
  }
}
</style>
