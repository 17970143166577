<template>
  <FormSection
    :icon="isActive ? 'eva-share' : 'eva-share-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Share</template>
    <template #description>
      Easily share and collaborate on your forms through multiple channels,
      including URL, QR code, and email
    </template>

    <!-- form url -->

    <TextField
      :value="formUrl"
      label="form url"
      is-mandatory
      is-readonly
      class="q-mb-md"
    />

    <BaseButton
      v-if="formId"
      label="Copy URL"
      class="q-mb-lg"
      @click="copyText"
    />

    <!-- ... -->

    <!-- form qrCode -->

    <BaseFeatureLock>
      <QRCode class="q-mb-lg" />
    </BaseFeatureLock>

    <!-- ... -->

    <!-- email to -->

    <BaseFeatureLock>
      <EmailTo
        :email-to-internal-users="emailToInternalUsers"
        :email-to-external-users="emailToExternalUsers"
        @updateInternalUsers="updateInternalUsers"
        @updateExternalUsers="updateExternalUsers"
      />
    </BaseFeatureLock>

    <!-- ... -->
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import QRCode from "./components/QRCode.vue";
import EmailTo from "./components/EmailTo.vue";
import { copyToClipboard } from "quasar";

export default {
  name: "ShareOptions",

  components: {
    FormSection,
    TextField,
    QRCode,
    EmailTo,
  },

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    emailToInternalUsers: {
      type: Array,
      required: true,
    },

    emailToExternalUsers: {
      type: Array,
      required: true,
    },

    formId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      formUrl: "",
    };
  },

  created() {
    this.formLink();
  },

  methods: {
    updateInternalUsers(internalUsers) {
      this.$emit("update:emailToInternalUsers", internalUsers);
    },

    updateExternalUsers(externalUsers) {
      this.$emit("update:emailToExternalUsers", externalUsers);
    },

    formLink() {
      this.formUrl = "";
      if (this.formId) {
        let origin = location.origin;
        if (
          origin === "https://app.ezofis.com" ||
          origin === "https://appuat.ezofis.com" ||
          origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net" ||
          origin === "https://trial.ezofis.com"
        ) {
          this.formUrl = `${origin}/form/${this.$store.state.session.tenantId}/${this.formId}`;
        } else {
          this.formUrl = `${origin}/app/form/${this.$store.state.session.tenantId}/${this.formId}`;
        }
      }
    },

    async copyText() {
      await copyToClipboard(this.formUrl);
      this.$alert.success(`Link copied`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
