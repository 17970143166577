<template>
  <div id="form-fields">
    <Tabs
      v-model="tab"
      :tabs="tabs"
      color="secondary"
      is-full-width
      class="tabs"
    />

    <div class="q-mx-md q-mt-md">
      <expandableSearch
        class="full-width"
        @search="searchFields"
      ></expandableSearch>
    </div>

    <BaseScrollbar height="calc(100vh - 195px)" class="q-mt-sm q-pb-sm q-px-md">
      <div
        v-for="field in _fields"
        :key="field.id"
        class="field"
        draggable
        @dragstart="(e) => handleFieldDragStart(e, field.type)"
      >
        <BaseIcon
          name="drag_indicator"
          size="16px"
          inherit-color
          class="drag-icon"
        />

        <div class="icon-wrapper">
          <BaseIcon :name="field.icon" />
        </div>

        <div class="label">{{ field.name }}</div>
        <q-chip
          v-if="field.chips"
          size="sm"
          text-color="white"
          color="secondary"
          >New</q-chip
        >
      </div>
    </BaseScrollbar>
  </div>
</template>

<script>
import Tabs from "@/components/common/tabs/Tabs.vue";
import expandableSearch from "@/components/common/ExpandableSearch.vue";

export default {
  name: "FormFields",

  components: { Tabs, expandableSearch },

  props: {
    activePanelType: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      tab: "BASIC",
      tabs: [
        {
          id: this.$nano.id(),
          label: "basic fields",
          value: "BASIC",
        },
        {
          id: this.$nano.id(),
          label: "advanced fields",
          value: "ADVANCED",
        },
      ],
      fields: [
        {
          id: this.$nano.id(),
          name: "label",
          type: "LABEL",
          icon: "mdi-label-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "short text",
          type: "SHORT_TEXT",
          icon: "mdi-form-textbox",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "long text",
          type: "LONG_TEXT",
          icon: "mdi-form-textarea",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "number",
          type: "NUMBER",
          icon: "mdi-numeric",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "date",
          type: "DATE",
          icon: "eva-calendar-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "time",
          type: "TIME",
          icon: "eva-clock-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "date time",
          type: "DATE_TIME",
          icon: "mdi-calendar-clock-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },

        {
          id: this.$nano.id(),
          name: "single select",
          type: "SINGLE_SELECT",
          icon: "mdi-form-dropdown",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "multi select",
          type: "MULTI_SELECT",
          icon: "mdi-form-select",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "single choice",
          type: "SINGLE_CHOICE",
          icon: "eva-radio-button-on",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "multiple choice",
          type: "MULTIPLE_CHOICE",
          icon: "eva-checkmark-square-2-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "counter",
          type: "COUNTER",
          icon: "mdi-counter",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Currency",
          type: "CURRENCY",
          icon: "mdi-currency-usd",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
          chips: "new",
        },
        {
          id: this.$nano.id(),
          name: "calculated",
          type: "CALCULATED",
          icon: "mdi-function-variant",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "country code",
          type: "COUNTRY_CODE",
          icon: "eva-globe-2",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
          chips: "new",
        },
        {
          id: this.$nano.id(),
          name: "divider",
          type: "DIVIDER",
          icon: "mdi-format-vertical-align-center",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "full name",
          type: "FULL_NAME",
          icon: "mdi-account-settings",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
          chips: "new",
        },
        {
          id: this.$nano.id(),
          name: "address",
          type: "ADDRESS",
          icon: "mdi-map-marker",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
          chips: "new",
        },
        {
          id: this.$nano.id(),
          name: "Contact Info",
          type: "CONTACT_INFO",
          icon: "mdi-account-card-details",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
          chips: "new",
        },
        {
          id: this.$nano.id(),
          name: "Phone Number",
          type: "PHONE_NUMBER",
          icon: "mdi-phone",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
          chips: "new",
        },
        {
          id: this.$nano.id(),
          name: "Website",
          type: "WEBSITE",
          icon: "mdi-link",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
          chips: "new",
        },
        {
          id: this.$nano.id(),
          name: "Yes/No",
          type: "YES_NO",
          icon: "eva-repeat-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
          chips: "new",
        },
        {
          id: this.$nano.id(),
          name: "Legal",
          type: "LEGAL",
          icon: "eva-shield-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
          chips: "new",
        },
        {
          id: this.$nano.id(),
          name: "Opinion Scale",
          type: "OPINION_SCALE",
          icon: "mdi-relative-scale",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
          chips: "new",
        },
        {
          id: this.$nano.id(),
          name: "Redirect to URL",
          type: "REDIRECT_TO_URL",
          icon: "eva-external-link-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
          chips: "new",
        },
        {
          id: this.$nano.id(),
          name: "password",
          type: "PASSWORD",
          icon: "eva-eye-off-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "chips",
          type: "CHIPS",
          icon: "mdi-dns-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "paragraph",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "file upload",
          type: "FILE_UPLOAD",
          icon: "mdi-file-document-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        // {
        //   id: this.$nano.id(),
        //   name: "Login Authentication",
        //   type: "LOGIN_AUTHENTICATION",
        //   icon: "mdi-login",
        //   group: "ADVANCED",
        //   for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        // },
        {
          id: this.$nano.id(),
          name: "text builder",
          type: "TEXT_BUILDER",
          icon: "mdi-text-box",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "fill in the blanks",
          type: "FILL_IN_THE_BLANKS",
          icon: "mdi-format-letter-matches",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "table",
          type: "TABLE",
          icon: "mdi-grid",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "matrix",
          type: "MATRIX",
          icon: "mdi-table",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        // {
        //   id: this.$nano.id(),
        //   name: "image",
        //   type: "IMAGE",
        //   icon: "eva-image-outline",
        //   group: "ADVANCED",
        //   for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "rating",
        //   type: "RATING",
        //   icon: "eva-star-outline",
        //   group: "ADVANCED",
        //   for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        // },
        {
          id: this.$nano.id(),
          name: "signature",
          type: "SIGNATURE",
          icon: "mdi-signature-freehand",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        // {
        //   id: this.$nano.id(),
        //   name: "tabs",
        //   type: "TABS",
        //   icon: "mdi-tab",
        //   group: "ADVANCED",
        //   for: ["PRIMARY_PANELS"],
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "popup",
        //   type: "POPUP",
        //   icon: "mdi-checkbox-multiple-blank-outline",
        //   group: "ADVANCED",
        //   for: ["PRIMARY_PANELS"],
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "nested list",
        //   type: "NESTED_LIST",
        //   icon: "mdi-file-tree-outline",
        //   group: "ADVANCED",
        //   for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        // },
      ],
      search: "",
    };
  },

  computed: {
    _fields() {
      let fields = this.fields.filter(
        (field) =>
          field.group === this.tab &&
          field.for.includes(this.activePanelType) &&
          (field.name !== "file upload" || !this.checkTenant)
      );
      if (this.search) {
        return fields.filter(
          (field) =>
            field.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      }
      return fields;
    },

    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    handleFieldDragStart(e, fieldType) {
      e.dataTransfer.setData("fieldType", fieldType);
    },

    searchFields(text) {
      this.search = text;
    },
  },
};
</script>

<style lang="scss" scoped>
#form-fields {
  width: 320px;
  background-color: var(--component-bg-color);

  .tabs {
    margin: 0px 16px;
    border-bottom: 1px solid var(--divider-color);
  }

  .field {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    user-select: none;
    transition: all 0.3s ease;

    .drag-icon {
      color: var(--icon-color-inverted);
    }

    .icon-wrapper {
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--body-bg-color);
      border-radius: 100%;
      margin: 0px 16px 0px 8px;
      transition: all 0.3s ease;
    }

    .label {
      font-weight: 500;
      text-transform: capitalize;
      flex: 1;
    }

    &:hover {
      background-color: var(--hover-bg-color);
      cursor: move;
    }
  }
}
</style>
