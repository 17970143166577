<template>
  <div id="nested-list-field-type">
    <!-- label -->

    <FormFieldLabel
      label="Nested List Field Type"
      is-mandatory
      tooltip="Selected type of field will be displayed on parent click if there are no nested children"
    />

    <!-- ... -->

    <div class="row items-center">
      <SelectField
        :value="value"
        :options="fieldTypes"
        :is-clearable="false"
        class="col q-mr-sm"
        @input="handleInput"
      />

      <BaseActionButton
        is-flat
        color="secondary"
        icon="eva-settings-outline"
        no-border
        :is-disabled="!showSettings"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";

export default {
  name: "NestedListFieldType",

  components: {
    FormFieldLabel,
    SelectField,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      fieldTypes: [
        {
          id: this.$nano.id(),
          label: "Short Text",
          value: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          label: "Number",
          value: "NUMBER",
        },
        {
          id: this.$nano.id(),
          label: "Counter",
          value: "COUNTER",
        },
        {
          id: this.$nano.id(),
          label: "Date",
          value: "DATE",
        },
        {
          id: this.$nano.id(),
          label: "Time",
          value: "TIME",
        },
        {
          id: this.$nano.id(),
          label: "Date Time",
          value: "DATE_TIME",
        },
      ],
      showSettings: true,
    };
  },

  methods: {
    handleInput(value) {
      this.showSettings = false;
      this.$emit("input", value);
    },

    handleClick() {
      this.$emit("nested-list-type-settings");
    },
  },
};
</script>

<style lang="scss" scoped></style>
