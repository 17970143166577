<template>
  <div id="form-settings">
    <!-- action bar -->

    <ActionBar v-model="tab" :tabs="tabs" @save="saveSettings" />

    <!-- ... -->

    <!-- settings -->

    <BaseScrollbar height="calc(100vh - 125px)">
      <div class="row q-pb-xl">
        <ValidationObserver ref="form">
          <!-- general settings -->

          <GeneralSettings
            v-if="tab === 'GENERAL_SETTINGS'"
            :form="form"
            :mode="mode"
            :name.sync="name"
            :description.sync="description"
            :layout.sync="layout"
            :type.sync="type"
            :qr-fields.sync="qrFields"
            :unique-columns.sync="uniqueColumns"
            :is-active="tab === 'GENERAL_SETTINGS'"
            @click="tab = 'GENERAL_SETTINGS'"
          />

          <!-- ... -->

          <!-- rules settings -->

          <!-- ... -->

          <WorkInProgress
            v-if="tab === 'OTHER_SETTINGS'"
            feature="Other Settings"
          />
        </ValidationObserver>

        <!-- ... -->
      </div>
    </BaseScrollbar>

    <!-- ... -->
  </div>
</template>

<script>
import ActionBar from "../components/ActionBar.vue";
import { ValidationObserver } from "vee-validate";
import GeneralSettings from "./components/general-settings/GeneralSettings.vue";
import WorkInProgress from "@/components/common/state/WorkInProgress.vue";
import { cloneDeep } from "lodash-es";

export default {
  name: "ReportsSettings",

  components: {
    ActionBar,
    ValidationObserver,
    GeneralSettings,
    WorkInProgress,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mode: "NEW",
      tab: "GENERAL_SETTINGS",
      tabs: [
        {
          id: this.$nano.id(),
          label: "general",
          value: "GENERAL_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "other",
          value: "OTHER_SETTINGS",
        },
      ],

      // general settings

      name: "",
      description: "",
      layout: "",
      type: "",
      qrFields: [],
      uniqueColumns: [],

      // specific settings

      rules: [],
    };
  },

  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        const formSettings = cloneDeep(this.form.settings);

        this.name = formSettings.general.name;
        this.description = formSettings.general.description;
        this.layout = formSettings.general.layout;
        this.type = formSettings.general.type;
        this.qrFields = formSettings.general.qrFields;
        this.uniqueColumns = formSettings.general.uniqueColumns;
        this.rules = formSettings.rules;
      },
    },
  },

  methods: {
    async saveSettings() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      const formSettings = cloneDeep(this.form.settings);

      formSettings.general.name = this.name;
      formSettings.general.description = this.description;
      formSettings.general.layout = this.layout;
      formSettings.general.type = this.type;
      formSettings.general.qrFields = this.qrFields;
      formSettings.general.uniqueColumns = this.uniqueColumns;
      formSettings.rules = this.rules;

      this.$emit("update", formSettings);
    },
  },
};
</script>

<style lang="scss" scoped></style>
