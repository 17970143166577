<template>
  <div class="relative-position">
    <!-- form header -->

    <ReportsHeader
      :form-settings="form.settings"
      @settings="showSettings"
      @preview="showFormPreview"
      @save="saveForm"
    />

    <!-- ... -->

    <!-- form preview -->

    <ReportsPreview
      v-model="isFormPreviewVisible"
      :form-settings="form.settings"
      :panels="form.panels"
      :secondary-panels="form.secondaryPanels"
    />

    <!-- ... -->

    <!-- panel type -->
    <!-- ... -->

    <!-- primary panels -->
    <div class="row">
      <div class="col-6 q-pr-sm">
        <Panels
          :panels="form.panels"
          :secondary-panels="form.secondaryPanels"
          panel-type="PRIMARY_PANELS"
          :form-settings="form.settings"
          @update="updatePanels"
        />
      </div>
      <div class="col-6 q-pr-sm disablegrid">
        <Panels
          :panels="form.secondaryPanels"
          panel-type="SECONDARY_PANELS"
          :form-settings="form.settings"
          @update="updateSecondaryPanels"
        />
      </div>
    </div>

    <div class="q-pa-md">
      <FilterItems
        label="Filter Reports"
        item="REPORTSDATA"
        :columns="columnData"
        :filter-by="filterBy"
        style="float: right"
      />
      <BaseIcon
        v-tooltip.top="'Table View'"
        name="mdi-file-table-box"
        color="gray"
        size="16px"
        class="q-pa-sm q-mr-sm"
        style="border: 1px solid gray; border-radius: 25px; float: right"
        @click="openTable"
      />
      <BaseIcon
        v-tooltip.top="'Chart View'"
        name="mdi-file-table-box-multiple"
        class="q-pa-sm q-mr-sm"
        color="gray"
        size="16px"
        style="border: 1px solid gray; border-radius: 25px; float: right"
        @click="openChart"
      />
    </div>

    <div v-if="tableView" class="q-pa-sm q-mr-md">
      <ItemList mode="REPORTSDATA" :columns="columns" :items="reportData">
      </ItemList>
    </div>

    <div v-if="chatView">
      <div id="charts">
        <div class="row q-col-gutter-md">
          <!-- bar chart - vertical -->

          <div class="col-4">
            <RangeChart
              title="Range Chart - Vertical"
              :xy-data="rangeChartxyData"
            />
          </div>

          <div class="col-4">
            <RangeChart
              title="Range Chart - Horizontal"
              is-horizontal
              :xy-data="rangeChartxyData"
            />
          </div>

          <div class="col-4">
            <BarChart
              title="Bar Chart - Vertical"
              :x-data="barChartxData"
              :y-data="barChartyData"
            >
              <!-- <template #header>
                <div class="row">
                  <BaseButton
                    label="add"
                    class="q-mr-sm"
                    @click="addBarChartData"
                  />
                  <BaseButton
                    label="remove"
                    color="secondary"
                    @click="removeBarChartData"
                  />
                </div>
              </template> -->
            </BarChart>
          </div>

          <div class="col-4">
            <AreaChart
              title="Area Chart"
              :x-data="lineChartxData"
              :y-data="lineChartyData"
            >
              <!-- <template #header>
                <div class="row">
                  <BaseButton
                    label="add"
                    class="q-mr-sm"
                    @click="addLineChartData"
                  />
                  <BaseButton
                    label="remove"
                    color="secondary"
                    @click="removeLineChartData"
                  />
                </div>
              </template> -->
            </AreaChart>
          </div>

          <div class="col-4">
            <PieChart
              title="Pie Chart"
              :x-data="pieChartxData"
              :y-data="pieChartyData"
            />
          </div>

          <div class="col-4">
            <DonutChart
              title="Donut Chart"
              :x-data="pieChartxData"
              :y-data="pieChartyData"
            />
          </div>

          <div class="col-4">
            <BarChart
              title="Bar Chart - Horizontal"
              is-horizontal
              :x-data="barChartxData"
              :y-data="barChartyData"
            >
              <!-- <template #header>
                <div class="row">
                  <BaseButton
                    label="add"
                    class="q-mr-sm"
                    @click="addBarChartData"
                  />
                  <BaseButton
                    label="remove"
                    color="secondary"
                    @click="removeBarChartData"
                  />
                </div>
              </template> -->
            </BarChart>
          </div>

          <div class="col-4">
            <BarChart
              title="Stack Chart - Vertical"
              is-stacked
              :x-data="barChartxData"
              :y-data="barChartyData"
            >
              <!-- <template #header>
                <div class="row">
                  <BaseButton
                    label="add"
                    class="q-mr-sm"
                    @click="addBarChartData"
                  />
                  <BaseButton
                    label="remove"
                    color="secondary"
                    @click="removeBarChartData"
                  />
                </div>
              </template> -->
            </BarChart>
          </div>

          <div class="col-4">
            <BarChart
              title="Stack Chart - Horizontal"
              is-stacked
              is-horizontal
              :x-data="barChartxData"
              :y-data="barChartyData"
            >
              <!-- <template #header>
                <div class="row">
                  <BaseButton
                    label="add"
                    class="q-mr-sm"
                    @click="addBarChartData"
                  />
                  <BaseButton
                    label="remove"
                    color="secondary"
                    @click="removeBarChartData"
                  />
                </div>
              </template> -->
            </BarChart>
          </div>

          <div class="col-4">
            <AreaChart
              title="Area Chart"
              :x-data="lineChartxData"
              :y-data="lineChartyData"
            >
              <!-- <template #header>
                <div class="row">
                  <BaseButton
                    label="add"
                    class="q-mr-sm"
                    @click="addLineChartData"
                  />
                  <BaseButton
                    label="remove"
                    color="secondary"
                    @click="removeLineChartData"
                  />
                </div>
              </template> -->
            </AreaChart>
          </div>

          <div class="col-4">
            <PieChart
              title="Pie Chart"
              :x-data="pieChartxData"
              :y-data="pieChartyData"
            />
          </div>

          <div class="col-4">
            <DonutChart
              title="Donut Chart"
              :x-data="pieChartxData"
              :y-data="pieChartyData"
            />
          </div>

          <div class="col-4">
            <BubbleChart
              title="Bubble Chart"
              :x-data="bubbleChartxData"
              :y-data="bubbleChartyData"
            />
          </div>

          <div class="col-4">
            <BoxPlotChart
              title="Box Plot Chart - Vertical"
              :xy-data="boxPlotChartxyData"
            />
          </div>

          <div class="col-4">
            <BoxPlotChart
              title="Box Plot Chart - Horizontal"
              is-horizontal
              :xy-data="boxPlotChartxyData"
            />
          </div>

          <div class="col-4">
            <CandleStickChart
              title="Candle Stick Chart"
              :xy-data="candleStickChartxyData"
            />
          </div>

          <div class="col-4">
            <HeatMapChart
              title="Heat Map Chart"
              :xy-data="heatMapChartxyData"
            />
          </div>

          <!-- ... -->
        </div>
      </div>
    </div>

    <!-- ... -->
  </div>
</template>

<script>
import ReportsHeader from "./components/ReportsHeader.vue";
import ReportsPreview from "./components/ReportsPreview.vue";
import ItemList from "@/components/common/display/ItemList.vue";
import FilterItems from "@/components/common/display/item-actions/components/FilterItems.vue";
import Panels from "./components/panels/Panels.vue";
import DataType from "@/constants/data-type.js";
import BarChart from "@/components/common/charts/BarChart.vue";
import AreaChart from "@/components/common/charts/AreaChart.vue";
import PieChart from "@/components/common/charts/PieChart.vue";
import DonutChart from "@/components/common/charts/DonutChart.vue";
import BubbleChart from "@/components/common/charts/BubbleChart.vue";
import BoxPlotChart from "@/components/common/charts/BoxPlotChart.vue";
import CandleStickChart from "@/components/common/charts/CandleStickChart.vue";
import RangeChart from "@/components/common/charts/RangeChart.vue";
import HeatMapChart from "@/components/common/charts/HeatMapChart.vue";
export default {
  name: "Reports",

  components: {
    ReportsHeader,
    ReportsPreview,
    Panels,
    ItemList,
    FilterItems,
    BarChart,
    AreaChart,
    PieChart,
    DonutChart,
    BubbleChart,
    BoxPlotChart,
    CandleStickChart,
    RangeChart,
    HeatMapChart,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },

    activePanelType: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isFormPreviewVisible: false,
      chatView: false,
      tableView: true,
      columns: [
        {
          id: this.$nano.id(),
          name: "docno",
          label: "Doc No",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "level",
          label: "Level",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "files",
          label: "Files",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "size",
          label: "Size",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdby",
          label: "Created By",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdat",
          label: "Created At",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
      ],
      reportData: [
        {
          key: "",
          data: [
            {
              id: this.$nano.id(),
              docno: "10000-1",
              level: "1",
              files: "2",
              size: "242.42 KB",
              createdby: "admin@ezofis.com",
              createdat: "2023-May-31 o4:53 PM",
              icon: "mdi-folder",
            },
            {
              id: this.$nano.id(),
              docno: "asd",
              level: "1",
              files: "1",
              size: "7.4 KB",
              createdby: "admin@ezofis.com",
              createdat: "2023-May-31 o5:03 PM",
              icon: "mdi-folder",
            },
            {
              id: this.$nano.id(),
              docno: "1123",
              level: "1",
              files: "4",
              size: "1.57 MB",
              createdby: "admin@ezofis.com",
              createdat: "2023-Feb-28 o2:49 PM",
              icon: "mdi-folder",
            },
            {
              id: this.$nano.id(),
              docno: "asd",
              level: "1",
              files: "1",
              size: "7.4 KB",
              createdby: "admin@ezofis.com",
              createdat: "2023-May-31 o5:03 PM",
              icon: "mdi-folder",
            },
          ],
        },
      ],
      filterBy: [],
      columnData: [],
      barChartxData: [2019, 2020],
      barChartyData: [
        {
          name: "",
          data: [34, 19],
        },
        {
          name: "",
          data: [12, 39],
        },
      ],
      lineChartxData: [2019, 2020],
      lineChartyData: [
        {
          name: "",
          data: [34, 19],
        },
        {
          name: "",
          data: [12, 39],
        },
      ],
      pieChartxData: ["Apple", "Mango", "Orange", "Watermelon"],
      pieChartyData: [44, 55, 13, 33],
      boxPlotChartxyData: [
        {
          data: [
            {
              x: "Series 1",
              y: [40, 51.98, 56.29, 59.59, 63.85],
            },
            {
              x: "Series 2",
              y: [43.66, 44.99, 51.35, 52.95, 59.42],
            },
            {
              x: "Series 3",
              y: [52.76, 57.35, 59.15, 63.03, 67.98],
            },
            {
              x: "Series 4",
              y: [40.3, 51.98, 56.29, 59.59, 63.85],
            },
            {
              x: "Series 5",
              y: [43.66, 44.99, 51.35, 52.95, 59.42],
            },
          ],
        },
      ],
      candleStickChartxyData: [
        {
          data: [
            {
              x: "2022-04-13",
              y: [53.66, 54.99, 51.59, 53.85],
            },
            {
              x: "2022-04-14",
              y: [53.66, 54.99, 51.35, 52.95],
            },
            {
              x: "2022-04-15",
              y: [51.98, 56.29, 51.59, 53.85],
            },
            {
              x: "2022-04-16",
              y: [53.66, 54.99, 51.35, 53.42],
            },
            {
              x: "2022-04-17",
              y: [52.76, 55.35, 52.15, 55.03],
            },
          ],
        },
      ],
      rangeChartxyData: [
        {
          name: "",
          data: [
            {
              x: "Team A",
              y: [65, 96],
            },
            {
              x: "Team B",
              y: [55, 78],
            },
            {
              x: "Team C",
              y: [65, 96],
            },
          ],
        },
        {
          name: "",
          data: [
            {
              x: "Team A",
              y: [25, 88],
            },
            {
              x: "Team B",
              y: [35, 88],
            },
            {
              x: "Team C",
              y: [75, 85],
            },
          ],
        },
      ],
      heatMapChartxyData: [
        {
          name: "",
          data: [
            {
              x: "2019",
              y: 42,
            },
            {
              x: "2020",
              y: 35,
            },
            {
              x: "2021",
              y: 50,
            },
            {
              x: "2022",
              y: 29,
            },
          ],
        },
        {
          name: "",
          data: [
            {
              x: "2019",
              y: 50,
            },
            {
              x: "2020",
              y: 25,
            },
            {
              x: "2021",
              y: 40,
            },
            {
              x: "2022",
              y: 45,
            },
          ],
        },
      ],
      treeMapChartxyData: [
        {
          name: "Population",
          data: [
            {
              x: "New Delhi",
              y: 218,
            },
            {
              x: "Kolkata",
              y: 149,
            },
            {
              x: "Mumbai",
              y: 184,
            },
            {
              x: "Ahmedabad",
              y: 55,
            },
            {
              x: "Bangaluru",
              y: 84,
            },
            {
              x: "Pune",
              y: 31,
            },
            {
              x: "Chennai",
              y: 70,
            },
          ],
        },
      ],
      radarChartxData: ["April", "May", "June", "July", "August", "September"],
      radarChartyData: [
        {
          name: "",
          data: [26, 21, 20, 6, 8, 15],
        },
        {
          name: "",
          data: [45, 52, 38, 24, 33, 10],
        },
      ],
      gaugeChartxData: ["Progress"],
      gaugeChartyData: [72],
      radialBarChartxData: ["Apples", "Oranges", "Bananas", "Berries"],
      radialBarChartyData: [44, 55, 67, 83],
      bubbleChartxData: [2018, 2019, 2020, 2021, 2022],
      bubbleChartyData: [
        {
          name: "",
          data: [44, 55, 13, 33, 38, 9],
        },
        {
          name: "",
          data: [12, 39, 52, 36, 44],
        },
      ],
    };
  },

  methods: {
    switchPanelType(type) {
      this.$emit("switch-panel-type", type);
    },

    showSettings() {
      this.$emit("settings");
    },

    showFormPreview() {
      this.isFormPreviewVisible = true;
    },

    updatePanels(panels) {
      this.$emit("update-panels", panels);
    },

    updateSecondaryPanels(panels) {
      this.$emit("update-secondary-panels", panels);
    },

    saveForm() {
      this.$emit("save");
    },

    openChart() {
      this.tableView = false;
      this.chatView = true;
    },
    openTable() {
      this.chatView = false;
      this.tableView = true;
    },
    addBarChartData() {
      if (this.barChartyData.length < 5) {
        this.barChartyData.push({
          name: `Series ${this.barChartyData.length + 1}`,
          data: [
            Math.floor(Math.random() * 100),
            Math.floor(Math.random() * 100),
            Math.floor(Math.random() * 100),
            Math.floor(Math.random() * 100),
          ],
        });
      }
    },

    removeBarChartData() {
      if (this.barChartyData.length > 1) {
        this.barChartyData.pop();
      }
    },

    addLineChartData() {
      if (this.lineChartyData.length < 5) {
        this.lineChartyData.push({
          name: `Series ${this.lineChartyData.length + 1}`,
          data: [
            Math.floor(Math.random() * 100),
            Math.floor(Math.random() * 100),
            Math.floor(Math.random() * 100),
            Math.floor(Math.random() * 100),
          ],
        });
      }
    },

    removeLineChartData() {
      if (this.lineChartyData.length > 1) {
        this.lineChartyData.pop();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  // height: calc(100vh - 235px);
  height: 283px;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 10px;
  background: white;

  &.chartBox {
    height: 140px;
  }
}
.disablegrid {
  pointer-events: none;
  opacity: 0.4;
}
.label {
  color: #ea7b04 !important;
}

.apexcharts-legend {
  display: none;
}

.header {
  display: none;
}
</style>
