<template>
  <div id="calculation-builder">
    <!-- secondary header -->

    <SecondaryHeader
      v-if="isConditionalRule"
      v-model="groupLogic"
      :items="groupLogics"
    >
      <template #before>Then</template>
      <template #after>the following field(s)</template>
    </SecondaryHeader>

    <div v-else class="header">
      <span class="highlight">Calculate</span> the following field(s)
    </div>

    <!-- ... -->

    <!-- tertiary header -->

    <TertiaryHeader class="row q-mb-md">
      <div class="label col-4">Field</div>
      <div class="label col">Formula</div>

      <div class="action" @click="showCalculationPopup">
        <BaseIcon name="eva-plus" color="secondary" />
      </div>
    </TertiaryHeader>

    <!-- ... -->

    <!-- calculations -->

    <template v-if="calculationsClone.length">
      <div
        v-for="(calculation, idx) in calculationsClone"
        :key="calculation.id"
        class="row items-center calculation"
      >
        <div class="col-4">{{ getFieldName(calculation) }}</div>
        <div class="col q-mx-sm ellipsis">
          {{ getFormula(calculation) }}
        </div>

        <BaseIcon
          name="eva-edit-outline"
          class="cursor-pointer q-mr-sm"
          @click="editCalculation(calculation)"
        />

        <BaseIcon
          name="eva-close"
          color="red"
          class="cursor-pointer"
          @click="calculationsClone.splice(idx, 1)"
        />
      </div>
    </template>

    <!-- ... -->

    <!-- add calculation -->

    <div v-else class="add-calculation" @click="showCalculationPopup">
      Add calculation
    </div>

    <!-- ... -->

    <!-- calculation popup -->

    <CalculationPopup
      v-model="isCalculationPopupVisible"
      :calculation="selectedCalculation"
      :numeric-fields="numericFields"
      :calculated-fields="calculatedFields"
      @save="saveCalculation"
    />

    <!-- ... -->
  </div>
</template>

<script>
import SecondaryHeader from "../SecondaryHeader.vue";
import TertiaryHeader from "../TertiaryHeader.vue";
import CalculationPopup from "./components/CalculationPopup.vue";
import { cloneDeep, isEmpty } from "lodash-es";

export default {
  name: "CalculationBuilder",

  components: { SecondaryHeader, TertiaryHeader, CalculationPopup },

  props: {
    numericFields: {
      type: Array,
      default: () => [],
    },

    calculatedFields: {
      type: Array,
      default: () => [],
    },

    calculations: {
      type: Array,
      default: () => [],
    },

    isConditionalRule: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      groupLogic: "CALCULATE",
      groupLogics: [
        {
          id: this.$nano.id(),
          label: "Show",
          value: "SHOW",
        },
        {
          id: this.$nano.id(),
          label: "Hide",
          value: "HIDE",
        },
        {
          id: this.$nano.id(),
          label: "Calculate",
          value: "CALCULATE",
        },
      ],
      calculationsClone: [],
      selectedCalculation: {},
      isCalculationPopupVisible: false,
    };
  },

  watch: {
    calculationsClone: {
      deep: true,
      handler() {
        this.$emit("update:calculations", this.calculationsClone);
      },
    },

    isCalculationPopupVisible() {
      if (!this.isCalculationPopupVisible) {
        this.selectedCalculation = {};
      }
    },
  },

  created() {
    if (this.calculations.length) {
      this.calculationsClone = cloneDeep(this.calculations);
    }
  },

  methods: {
    showCalculationPopup() {
      this.isCalculationPopupVisible = true;
    },

    editCalculation(calculation) {
      this.selectedCalculation = calculation;
      this.showCalculationPopup();
    },

    saveCalculation(calculation) {
      if (isEmpty(this.selectedCalculation)) {
        this.calculationsClone.push(calculation);
        return;
      }

      const calculationIdx = this.calculationsClone.findIndex(
        (_calculation) => _calculation.id === calculation.id
      );
      this.calculationsClone[calculationIdx] = calculation;
    },

    getFieldName(calculation) {
      const field = this.calculatedFields.find(
        (field) => field.id === calculation.fieldId
      );

      if (field.type === "TABLE") {
        const column = field.settings.specific.tableColumns.find(
          (column) => column.id === calculation.columnId
        );

        return `${field.label || "Table"} → ${column.label || "Column"}`;
      }

      return field.label;
    },

    getFormula(calculation) {
      return calculation.formula.reduce(
        (acc, cur) => `${acc} ${cur.label}`,
        ""
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#calculation-builder {
  .header {
    color: var(--icon-color);
    margin-bottom: 16px;

    .highlight {
      color: var(--secondary);
      font-weight: 500;
    }
  }

  .calculation:not(:last-child) {
    margin-bottom: 16px;
  }

  .add-calculation {
    font-weight: 500;
    color: var(--secondary);
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
