<template>
  <div>
    <div class="check-bg" :class="_color"></div>
    <q-icon name="mdi-check" color="white" size="20px" class="check-icon" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import colorValidator from "@/helpers/color-validator.js";

export default {
  name: "Check",

  props: {
    color: {
      type: String,
      default: "secondary",
      validator: colorValidator,
    },
  },

  computed: {
    ...mapState(["darkTheme"]),

    _color() {
      return this.darkTheme ? `bg-${this.color}-5` : `bg-${this.color}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.check-bg {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 100% 0% 0% 100% / 0% 100% 0% 100%;
  height: 32px;
  width: 32px;
}

.check-icon {
  position: absolute;
  top: 3px;
  right: 3px;
}
</style>
