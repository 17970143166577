<template>
  <div id="specific-settings">
    <!-- options builder -->

    <OptionsBuilder
      v-if="showOptionsBuilder"
      :field-type="fieldType"
      :options-type="optionsType"
      :master-table="masterTable"
      :master-table-column="masterTableColumn"
      :predefined-table="predefinedTable"
      :predefined-table-column="predefinedTableColumn"
      :custom-options="customOptions"
      :separate-options-using="separateOptionsUsing"
      :allow-to-add-new-options="allowToAddNewOptions"
      :options-per-line="optionsPerLine"
    />

    <!-- ... -->

    <!-- default value -->

    <SingleChoiceField
      v-if="showDefaultValue"
      :value="defaultValue"
      label="default value"
      :options="_defaultValueOptions"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateDefaultValue"
    />

    <!-- ... -->

    <!-- auto generate value - SHORT_TEXT -->

    <div
      v-if="fieldType === 'SHORT_TEXT' && defaultValue === 'AUTO_GENERATE'"
      class="row items-start q-pa-md"
    >
      <!-- prefix -->

      <ValidationProvider
        v-slot="{ errors }"
        name="prefix"
        :rules="{ required: true }"
        class="col"
      >
        <TextField
          :value="autoGenerateValue.prefix"
          label="prefix"
          is-mandatory
          :error="errors[0]"
          @input="
            (value) =>
              updateAutoGenerateValue({
                prefix: value,
                suffix: autoGenerateValue.suffix,
              })
          "
        />
      </ValidationProvider>

      <!-- ... -->

      <div class="q-mx-sm"></div>

      <!-- suffix -->

      <SelectField
        :value="autoGenerateValue.suffix"
        label="suffix"
        is-mandatory
        :options="autoGenerateSuffixOptions"
        :is-clearable="false"
        class="col"
        @input="
          (value) =>
            updateAutoGenerateValue({
              prefix: autoGenerateValue.prefix,
              suffix: value,
            })
        "
      />

      <!-- ... -->
    </div>

    <!-- ... -->

    <!-- custom default value - SHORT_TEXT -->

    <ValidationProvider
      v-if="fieldType === 'SHORT_TEXT' && defaultValue === 'CUSTOM'"
      v-slot="{ errors }"
      name="default value"
      :rules="{ min: minimum || false, max: maximum || false }"
    >
      <TextField
        :value="customDefaultValue"
        label="custom default value"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateCustomDefaultValue"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- default value - LONG_TEXT -->

    <ValidationProvider
      v-if="fieldType === 'LONG_TEXT'"
      v-slot="{ errors }"
      name="default value"
      :rules="{ min: minimum || false, max: maximum || false }"
    >
      <TextAreaField
        :value="customDefaultValue"
        label="default value"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateCustomDefaultValue"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- default value - NUMBER -->

    <ValidationProvider
      v-if="['NUMBER', 'COUNTER'].includes(fieldType)"
      v-slot="{ errors }"
      name="default value"
      :rules="{ min_value: minimum || false, max_value: maximum || false }"
    >
      <NumberField
        :value="customDefaultValue"
        label="default value"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateCustomDefaultValue"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- custom default value - DATE -->

    <ValidationProvider
      v-if="fieldType === 'DATE' && defaultValue === 'CUSTOM'"
      v-slot="{ errors }"
      name="default value"
      :rules="{ minDate: minimum || false, maxDate: maximum || false }"
    >
      <DateField
        :value="customDefaultValue"
        label="custom default value"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateCustomDefaultValue"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- custom default value - TIME -->

    <ValidationProvider
      v-if="fieldType === 'TIME' && defaultValue === 'CUSTOM'"
      v-slot="{ errors }"
      name="default value"
      :rules="{ minTime: minimum || false, maxTime: maximum || false }"
    >
      <TimeField
        :value="customDefaultValue"
        label="custom default value"
        class="q-pa-md"
        :error="errors[0]"
        @input="updateCustomDefaultValue"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- custom default value - DATE_TIME -->

    <ValidationProvider
      v-if="fieldType === 'DATE_TIME' && defaultValue === 'CUSTOM'"
      v-slot="{ errors }"
      name="default value"
      :rules="{ minDate: minimum || false, maxDate: maximum || false }"
    >
      <DateTimeField
        :value="customDefaultValue"
        label="custom default value"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateCustomDefaultValue"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- default value - SINGLE_SELECT | SINGLE_CHOICE -->

    <SelectField
      v-if="
        ['SINGLE_SELECT', 'SINGLE_CHOICE'].includes(fieldType) &&
        optionsType === 'CUSTOM'
      "
      :value="customDefaultValue"
      label="default value"
      :options="_customOptions"
      class="q-pa-md"
      @input="updateCustomDefaultValue"
    />

    <!-- ... -->

    <!-- default value - MULTI_SELECT | MULTIPLE_CHOICE -->

    <MultiSelectField
      v-if="
        ['MULTI_SELECT', 'MULTIPLE_CHOICE'].includes(fieldType) &&
        optionsType === 'CUSTOM'
      "
      :value="customDefaultValue"
      label="default value"
      :options="_customOptions"
      class="q-pa-md"
      @input="updateCustomDefaultValue"
    />

    <!-- ... -->

    <!-- column total -->

    <SingleChoiceField
      v-if="_showColumnTotal"
      :value="showColumnTotal"
      label="show column total"
      :options="binaryOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateShowColumnTotal"
    />

    <!-- ... -->

    <!-- allow multiple files -->

    <SingleChoiceField
      v-if="fieldType === 'FILE_UPLOAD'"
      :value="allowMultipleFiles"
      label="allow multiple files"
      :options="binaryOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateAllowMultipleFiles"
    />

    <!-- ... -->

    <!-- rating icon -->

    <SingleChoiceField
      v-if="fieldType === 'RATING'"
      :value="ratingIcon"
      label="rating icon"
      :options="ratingIcons"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateRatingIcon"
    />

    <!-- ... -->

    <!-- rating icon count -->

    <SelectField
      v-if="fieldType === 'RATING'"
      :value="ratingIconCount"
      label="rating icon count"
      :options="ratingIconCounts"
      :is-clearable="false"
      class="q-pa-md"
      @input="updateRatingIconCount"
    />

    <!-- ... -->

    <!-- allow half rating -->

    <SingleChoiceField
      v-if="fieldType === 'RATING'"
      :value="allowHalfRating"
      label="allow half rating"
      :options="binaryOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateAllowHalfRating"
    />

    <!-- ... -->

    <!-- allow multiple signatures -->

    <SingleChoiceField
      v-if="fieldType === 'SIGNATURE'"
      :value="allowMultipleSignatures"
      label="allow multiple signatures"
      :options="binaryOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateAllowMultipleSignatures"
    />

    <!-- ... -->

    <!-- table columns builder -->

    <ValidationProvider
      v-if="fieldType === 'TABLE'"
      v-slot="{ errors }"
      name="columns"
      :rules="{ required: true }"
    >
      <TableColumnsBuilder
        :value="tableColumns"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateTableColumns"
        @column-settings="showColumnSettings"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- table rows type -->

    <SingleChoiceField
      v-if="fieldType === 'TABLE'"
      :value="tableRowsType"
      label="table rows type"
      :options="tableRowsTypes"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateTableRowsType"
    />

    <!-- ... -->

    <!-- table fixed row count -->

    <ValidationProvider
      v-if="fieldType === 'TABLE' && tableRowsType === 'FIXED'"
      v-slot="{ errors }"
      name="row count"
      :rules="{ required: true, min_value: 1, max_value: 100 }"
    >
      <CounterField
        is-mandatory
        :value="tableFixedRowCount"
        label="table fixed row count"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateTableFixedRowCount"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- get values from QR -->

    <SingleChoiceField
      v-if="fieldType === 'TABLE'"
      :value="qrValue"
      label="Read values from QR"
      :options="binaryOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateQRValue"
    />
    <!-- ... -->

    <!-- matrix columns builder -->

    <ValidationProvider
      v-if="fieldType === 'MATRIX'"
      v-slot="{ errors }"
      name="columns"
      :rules="{ required: true }"
    >
      <MatrixVectorsBuilder
        :value="matrixColumns"
        label="columns"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateMatrixColumns"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- matrix rows builder -->

    <ValidationProvider
      v-if="fieldType === 'MATRIX'"
      v-slot="{ errors }"
      name="rows"
      :rules="{ required: true }"
    >
      <MatrixVectorsBuilder
        :value="matrixRows"
        label="rows"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateMatrixRows"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- matrix type -->

    <MatrixType
      v-if="fieldType === 'MATRIX'"
      :value="matrixType"
      class="q-pa-md"
      @input="updateMatrixType"
      @matrix-type-settings="showMatrixTypeSettings"
    />

    <!-- ... -->

    <!-- fib fields -->

    <FibFields
      v-if="fieldType === 'FILL_IN_THE_BLANKS'"
      :fib-fields="fibFields"
      class="q-pa-md"
    />

    <!-- ... -->

    <!-- tabs builder -->

    <TabsBuilder
      v-if="fieldType === 'TABS'"
      :value="tabList"
      :secondary-panels="_secondaryPanels"
      class="q-pa-md"
      @input="updateTabList"
    />

    <!-- ... -->

    <!-- popup trigger type -->

    <SingleChoiceField
      v-if="fieldType === 'POPUP'"
      :value="popupTriggerType"
      label="trigger type"
      :options="popupTriggerTypes"
      :options-per-line="3"
      class="q-pa-md"
      @input="updatePopupTriggerType"
    />

    <!-- ... -->

    <!-- secondary panel -->

    <ValidationProvider
      v-if="fieldType === 'POPUP'"
      v-slot="{ errors }"
      name="secondary panel"
      :rules="{ required: true }"
    >
      <SelectField
        is-mandatory
        label="secondary panel"
        tooltip="selected secondary panel will be displayed inside the popup"
        :value="secondaryPanel"
        :options="_secondaryPanels"
        :is-clearable="false"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateSecondaryPanel"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- mapped field -->

    <SelectField
      v-if="fieldType === 'POPUP'"
      label="mapped field"
      tooltip="mapped field's value will be displayed instead of default label"
      :value="mappedFieldId"
      :options="secondaryPanelFields"
      :is-clearable="false"
      class="q-pa-md"
      @input="updateMappedFieldId"
    />

    <!-- ... -->

    <!-- mapped column -->

    <SelectField
      v-if="fieldType === 'POPUP'"
      label="mapped column"
      :value="mappedColumnId"
      :options="secondaryPanelFieldColumns"
      :is-clearable="false"
      class="q-pa-md"
      @input="updateMappedColumnId"
    />

    <!-- ... -->

    <!-- nested list max level -->

    <ValidationProvider
      v-if="fieldType === 'NESTED_LIST'"
      v-slot="{ errors }"
      name="max level"
      :rules="{ min_value: 1, max_value: 10 }"
    >
      <CounterField
        :value="nestedListMaxLevel"
        label="max level"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateNestedListMaxLevel"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- nested list field type -->

    <NestedListFieldType
      v-if="fieldType === 'NESTED_LIST'"
      :value="nestedListFieldType"
      class="q-pa-md"
      @input="updateNestedListFieldType"
      @nested-list-type-settings="showNestedListTypeSettings"
    />

    <!-- ... -->

    <!-- nested list items per line -->

    <NestedListItemsPerLine
      v-if="fieldType === 'NESTED_LIST'"
      :value="nestedListItemsPerLine"
      :max-level="nestedListMaxLevel"
      class="q-pa-md"
      @input="updateNestedListItemsPerLine"
    />

    <!-- ... -->

    <!-- login authentication types -->

    <SingleChoiceField
      v-if="fieldType === 'LOGIN_AUTHENTICATION'"
      :value="loginType"
      label="login types"
      :options="loginTypes"
      :options-per-line="1"
      is-mandatory
      class="q-pa-md"
      @input="updateLoginType"
    />

    <!-- ... -->

    <!-- login authentication additional types-->

    <MultipleChoiceField
      v-if="fieldType === 'LOGIN_AUTHENTICATION'"
      :value="additionalLoginTypes"
      label="Social Login"
      :options="additionalLoginTypeOptions"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateAdditionalLoginTypes"
    />
    <!-- ... -->
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import OptionsBuilder from "@/components/common/options-builder/OptionsBuilder.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import CounterField from "@/components/common/form/counter-field/CounterField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import DateTimeField from "@/components/common/form/date-time-field/DateTimeField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import TableColumnsBuilder from "./components/TableColumnsBuilder.vue";
import MatrixVectorsBuilder from "./components/MatrixVectorsBuilder.vue";
import FibFields from "./components/FibFields.vue";
import MatrixType from "./components/MatrixType.vue";
import TabsBuilder from "./components/TabsBuilder.vue";
import NestedListFieldType from "./components/NestedListFieldType.vue";
import NestedListItemsPerLine from "./components/NestedListItemsPerLine.vue";
import MultipleChoiceField from "@/components/common/form/multiple-choice-field/MultipleChoiceField.vue";
import getNewField from "@/helpers/new-field.js";
import { startCase, lowerCase, isEmpty } from "lodash-es";

export default {
  name: "SpecificSettings",

  components: {
    ValidationProvider,
    OptionsBuilder,
    TextField,
    TextAreaField,
    NumberField,
    CounterField,
    DateField,
    TimeField,
    DateTimeField,
    SelectField,
    MultiSelectField,
    SingleChoiceField,
    TableColumnsBuilder,
    MatrixVectorsBuilder,
    FibFields,
    MatrixType,
    TabsBuilder,
    NestedListFieldType,
    NestedListItemsPerLine,
    MultipleChoiceField,
  },

  props: {
    settingsFor: {
      type: String,
      default: "",
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    fieldType: {
      type: String,
      default: "",
    },

    optionsType: {
      type: String,
      default: "",
    },

    masterTable: {
      type: String,
      default: "",
    },

    masterTableColumn: {
      type: String,
      default: "",
    },

    predefinedTable: {
      type: String,
      default: "",
    },

    predefinedTableColumn: {
      type: String,
      default: "",
    },

    customOptions: {
      type: String,
      default: "",
    },

    separateOptionsUsing: {
      type: String,
      default: "",
    },

    allowToAddNewOptions: {
      type: Boolean,
      default: false,
    },

    optionsPerLine: {
      type: Number,
      default: 0,
    },

    defaultValue: {
      type: String,
      default: "",
    },

    customDefaultValue: {
      type: [String, Number, Array, Object],
      default: "",
    },

    autoGenerateValue: {
      type: Object,
      default: () => ({
        prefix: "Form",
        suffix: "DATE_TIME",
      }),
    },

    showColumnTotal: {
      type: Boolean,
      default: false,
    },

    allowMultipleFiles: {
      type: Boolean,
      default: false,
    },

    ratingIcon: {
      type: String,
      default: "",
    },

    ratingIconCount: {
      type: Number,
      default: 0,
    },

    allowHalfRating: {
      type: Boolean,
      default: false,
    },

    allowMultipleSignatures: {
      type: Boolean,
      default: false,
    },

    tableColumns: {
      type: Array,
      default: () => [],
    },

    tableRowsType: {
      type: String,
      default: "",
    },

    tableFixedRowCount: {
      type: Number,
      default: 0,
    },

    matrixColumns: {
      type: Array,
      default: () => [],
    },

    matrixRows: {
      type: Array,
      default: () => [],
    },

    matrixType: {
      type: String,
      default: "",
    },

    matrixTypeSettings: {
      type: Object,
      default: () => {},
    },

    fibFields: {
      type: Array,
      default: () => [],
    },

    minimum: {
      type: [String, Number],
      default: "",
    },

    maximum: {
      type: [String, Number],
      default: "",
    },

    tabList: {
      type: Array,
      default: () => [],
    },

    popupTriggerType: {
      type: String,
      default: "",
    },

    secondaryPanel: {
      type: String,
      default: "",
    },

    mappedFieldId: {
      type: String,
      default: "",
    },

    mappedColumnId: {
      type: String,
      default: "",
    },

    nestedListMaxLevel: {
      type: Number,
      default: 0,
    },

    nestedListFieldType: {
      type: String,
      default: "SHORT_TEXT",
    },

    nestedListItemsPerLine: {
      type: Array,
      default: () => [],
    },

    nestedListTypeSettings: {
      type: Object,
      default: () => {},
    },

    loginType: {
      type: String,
      default: "",
    },

    additionalLoginTypes: {
      type: Array,
      default: () => [],
    },

    qrValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      autoGenerateSuffixOptions: [
        {
          id: this.$nano.id(),
          label: "Datetime",
          value: "DATE_TIME",
        },
        {
          id: this.$nano.id(),
          label: "Date",
          value: "DATE",
        },
        {
          id: this.$nano.id(),
          label: "Time",
          value: "TIME",
        },
      ],
      defaultValueOptions: [
        {
          id: this.$nano.id(),
          label: "Logged in user's email",
          value: "USER_EMAIL",
          for: ["SHORT_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Logged in user's name",
          value: "USER_NAME",
          for: ["SHORT_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Current date",
          value: "CURRENT_DATE",
          for: ["DATE"],
        },
        {
          id: this.$nano.id(),
          label: "Current time",
          value: "CURRENT_TIME",
          for: ["TIME"],
        },
        {
          id: this.$nano.id(),
          label: "Current date time",
          value: "CURRENT_DATE_TIME",
          for: ["DATE_TIME"],
        },
        {
          id: this.$nano.id(),
          label: "Auto Generate",
          value: "AUTO_GENERATE",
          for: ["SHORT_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Custom",
          value: "CUSTOM",
          for: ["SHORT_TEXT", "DATE", "TIME", "DATE_TIME"],
        },
      ],
      binaryOptions: [
        {
          id: this.$nano.id(),
          label: "Yes",
          value: true,
        },
        {
          id: this.$nano.id(),
          label: "No",
          value: false,
        },
      ],
      tableRowsTypes: [
        {
          id: this.$nano.id(),
          label: "On Demand",
          description: "Add any number of rows on demand",
          value: "ON_DEMAND",
        },
        {
          id: this.$nano.id(),
          label: "Fixed",
          description: "Freeze the number of rows",
          value: "FIXED",
        },
      ],
      ratingIcons: [
        {
          id: this.$nano.id(),
          label: "Star",
          value: "STAR",
          icon: "mdi-star",
          color: "amber",
        },
        {
          id: this.$nano.id(),
          label: "Heart",
          value: "HEART",
          icon: "mdi-heart",
          color: "red",
        },
        {
          id: this.$nano.id(),
          label: "Shield",
          value: "SHIELD",
          icon: "mdi-shield",
          color: "green",
        },
      ],
      popupTriggerTypes: [
        {
          id: this.$nano.id(),
          label: "Button",
          value: "BUTTON",
        },
        {
          id: this.$nano.id(),
          label: "Link",
          value: "LINK",
        },
      ],
      loginTypes: [
        {
          id: this.$nano.id(),
          label: "Default Login",
          description: "Application Accounts to login",
          value: "EZOFIS_LOGIN",
        },
        {
          id: this.$nano.id(),
          label: "Email with OTP ",
          description: "Sends OTP to the Email",
          value: "EMAIL_LOGIN",
        },
        {
          id: this.$nano.id(),
          label: "Mobile with OTP ",
          description: "Sends OTP to the Mobile",
          value: "MOBILE_LOGIN",
        },
      ],
      additionalLoginTypeOptions: [
        {
          id: this.$nano.id(),
          label: "Google",
          value: "GOOGLE_LOGIN",
        },
        {
          id: this.$nano.id(),
          label: "Microsoft",
          value: "MICROSOFT_LOGIN",
        },
      ],
    };
  },

  computed: {
    showOptionsBuilder() {
      return [
        "SINGLE_SELECT",
        "MULTI_SELECT",
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
      ].includes(this.fieldType);
    },

    showDefaultValue() {
      return ["SHORT_TEXT", "DATE", "TIME", "DATE_TIME"].includes(
        this.fieldType
      );
    },

    _defaultValueOptions() {
      return this.defaultValueOptions.filter((option) =>
        option.for.includes(this.fieldType)
      );
    },

    _customOptions() {
      if (!this.customOptions) {
        return [];
      }

      const separator = this.separateOptionsUsing === "NEWLINE" ? "\n" : ",";

      return this.customOptions.split(separator).map((option) => ({
        id: this.$nano.id(),
        label: option.trim(),
        value: option.trim(),
      }));
    },

    _showColumnTotal() {
      if (this.settingsFor !== "TABLE") {
        return false;
      }

      if (["NUMBER", "COUNTER", "CALCULATED"].includes(this.fieldType)) {
        return true;
      }

      return false;
    },

    ratingIconCounts() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((count) => ({
        id: this.$nano.id(),
        label: `${count}`,
        value: count,
      }));
    },

    _secondaryPanels() {
      return this.secondaryPanels.map((panel, idx) => {
        const label = `${idx + 1}. ${panel.settings.title || "Panel title"}`;

        return {
          id: this.$nano.id(),
          label: label,
          value: panel.id,
        };
      });
    },

    secondaryPanelFields() {
      if (!this.secondaryPanel) return [];

      const secondaryPanel = this.secondaryPanels.find(
        (panel) => panel.id === this.secondaryPanel
      );

      return secondaryPanel.fields
        .filter((field) => {
          if (field.type === "TABLE") {
            return field.settings.specific.tableColumns.some((column) =>
              ["CALCULATED", "NUMBER", "COUNTER"].includes(column.type)
            );
          }

          if (field.type === "NESTED_LIST") {
            return ["NUMBER", "COUNTER"].includes(
              field.settings.specific.nestedListFieldType
            );
          }

          return ["CALCULATED", "NUMBER", "COUNTER"].includes(field.type);
        })
        .map((field) => ({
          id: field.id,
          label: field.label || startCase(lowerCase(field.type)),
          value: field.id,
        }));
    },

    secondaryPanelFieldColumns() {
      if (!this.mappedFieldId) return [];
      if (isEmpty(this.secondaryPanelFields)) return [];

      const secondaryPanel = this.secondaryPanels.find(
        (panel) => panel.id === this.secondaryPanel
      );

      const field = secondaryPanel.fields.find(
        (_field) => _field.id === this.mappedFieldId
      );

      return field.settings.specific.tableColumns
        .filter((column) =>
          ["CALCULATED", "NUMBER", "COUNTER"].includes(column.type)
        )
        .map((column) => ({
          id: column.id,
          label: column.label || startCase(lowerCase(column.type)),
          value: column.id,
        }));
    },
  },

  methods: {
    updateDefaultValue(option) {
      this.$emit("update:defaultValue", option);
    },

    updateCustomDefaultValue(value) {
      this.$emit("update:customDefaultValue", value);
    },

    updateAutoGenerateValue(value) {
      this.$emit("update:autoGenerateValue", value);
    },

    updateShowColumnTotal(value) {
      this.$emit("update:showColumnTotal", value);
    },

    updateAllowMultipleFiles(value) {
      this.$emit("update:allowMultipleFiles", value);
    },

    updateRatingIcon(icon) {
      this.$emit("update:ratingIcon", icon);
    },

    updateRatingIconCount(count) {
      this.$emit("update:ratingIconCount", count);
    },

    updateAllowHalfRating(value) {
      this.$emit("update:allowHalfRating", value);
    },

    updateAllowMultipleSignatures(value) {
      this.$emit("update:allowMultipleSignatures", value);
    },

    updateTableColumns(columns) {
      this.$emit("update:tableColumns", columns);
    },

    updateMatrixColumns(columns) {
      this.$emit("update:matrixColumns", columns);
    },

    updateMatrixRows(rows) {
      this.$emit("update:matrixRows", rows);
    },

    updateMatrixType(type) {
      this.$emit("update:matrixType", type);
      this.$emit("update:matrixTypeSettings", getNewField(type));
    },

    showColumnSettings(column) {
      this.$emit("column-settings", column);
    },

    showMatrixTypeSettings() {
      this.$emit("matrix-type-settings");
    },

    showNestedListTypeSettings() {
      this.$emit("nested-list-type-settings");
    },

    updateTabList(tabs) {
      this.$emit("update:tabList", tabs);
    },

    updatePopupTriggerType(type) {
      this.$emit("update:popupTriggerType", type);
    },

    updateSecondaryPanel(panelIdx) {
      this.$emit("update:secondaryPanel", panelIdx);
    },

    updateMappedFieldId(fieldId) {
      this.$emit("update:mappedFieldId", fieldId);
    },

    updateMappedColumnId(columnId) {
      this.$emit("update:mappedColumnId", columnId);
    },

    updateNestedListMaxLevel(level) {
      this.$emit("update:nestedListMaxLevel", level);
    },

    updateNestedListFieldType(type) {
      this.$emit("update:nestedListFieldType", type);
      this.$emit("update:nestedListTypeSettings", getNewField(type));
    },

    updateNestedListItemsPerLine(value) {
      this.$emit("update:nestedListItemsPerLine", value);
    },

    updateTableRowsType(type) {
      this.$emit("update:tableRowsType", type);
    },

    updateTableFixedRowCount(count) {
      this.$emit("update:tableFixedRowCount", count);
    },

    updateLoginType(type) {
      this.$emit("update:loginType", type);
    },

    updateAdditionalLoginTypes(value) {
      this.$emit("update:additionalLoginTypes", value);
    },

    updateQRValue(option) {
      this.$emit("update:qrValue", option);
    },
  },
};
</script>

<style lang="scss" scoped></style>
