<template>
  <div id="fill-in-the-blanks-field">
    <FormFieldLabel label="fill in the blanks" />

    <FormFieldWrapper
      :is-focused="isFocused"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
    >
      <div class="q-pa-md">
        <RenderContent
          v-if="content"
          :value="value"
          :content="content"
          :fib-fields="fibFields"
          @input="handleInput"
        />
        <div v-else class="placeholder">Add content ...</div>
      </div>
    </FormFieldWrapper>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import RenderContent from "./components/RenderContent.vue";

export default {
  name: "FillInTheBlanksField",

  components: { FormFieldLabel, FormFieldWrapper, RenderContent },

  props: {
    value: {
      type: Object,
      required: true,
    },

    content: {
      type: String,
      default: "",
    },

    fibFields: {
      type: Array,
      required: true,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isFocused: false,
    };
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
