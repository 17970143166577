<template>
  <q-dialog :value="value" position="right" maximized @input="closePreview">
    <div id="form-preview" style="width: 840px">
      <!-- header -->

      <div class="header">
        <!-- form meta -->

        <div class="col">
          <div class="title-1">{{ formName }}</div>
          <div class="description">{{ formDescription }}</div>
        </div>

        <!-- ... -->

        <!-- close -->

        <BaseCloseButton @click="closePreview" />

        <!-- ... -->
      </div>

      <!-- ... -->

      <!-- form -->

      <BaseScrollbar height="calc(100vh - 79px)" class="q-pa-md">
        <RenderForm
          :panels="panels"
          :secondary-panels="secondaryPanels"
          :form-settings="formSettings"
        />
      </BaseScrollbar>

      <!-- ... -->
    </div>
  </q-dialog>
</template>

<script>
import RenderForm from "@/components/common/render-form/RenderForm.vue";

export default {
  name: "ReportsPreview",

  components: { RenderForm },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    formSettings: {
      type: Object,
      required: true,
    },

    panels: {
      type: Array,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    formName() {
      return this.formSettings.general.name;
    },

    formDescription() {
      return this.formSettings.general.description;
    },
  },

  methods: {
    closePreview() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#form-preview {
  background-color: var(--body-bg-color);

  .header {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid var(--divider-color);
    background-color: var(--component-bg-color);

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }
}
</style>
