<template>
  <div id="fib-fields">
    <!-- label -->

    <FormFieldLabel label="fib fields" />

    <!-- ... -->

    <!-- header -->

    <div class="header row">
      <div class="col label">Name</div>

      <div class="col label">Data Type</div>
    </div>

    <!-- ... -->

    <!-- fib fields -->

    <div
      v-for="(fibField, idx) in fibFields"
      :key="fibField.id"
      class="row items-center q-mb-sm"
    >
      <!-- name -->

      <div class="col">{{ idx + 1 }}. {{ fibField.label }}</div>

      <!-- ... -->

      <!-- type -->

      <div class="col row items-center">
        <div class="col q-mr-sm">{{ fibField.type }}</div>

        <BaseActionButton
          is-flat
          color="secondary"
          icon="eva-settings-outline"
          no-border
          @click="handleClick(fibField)"
        />
      </div>

      <!-- ... -->
    </div>

    <!-- ... -->

    <BaseSeparator v-if="fibFields.length" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";

export default {
  name: "FibFields",

  components: {
    FormFieldLabel,
  },

  props: {
    fibFields: {
      type: Array,
      required: true,
    },
  },

  methods: {
    handleClick(field) {
      this.$parent.$emit("fib-settings", field);
    },
  },
};
</script>

<style lang="scss" scoped>
#fib-fields {
  .header {
    border-bottom: 1px solid var(--divider-color);
    margin-bottom: 16px;
    padding-bottom: 4px;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }
}
</style>
