<template>
  <div id="panel-types">
    <div
      v-for="type in panelTypes"
      :key="type.id"
      v-tooltip:secondary.right="type.label"
      class="panel-type"
      :class="{ active: value === type.value }"
      @click="handleClick(type.value)"
    >
      <BaseIcon
        :name="value === type.value ? type.activeIcon : type.icon"
        inherit-color
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PanelType",

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      panelTypes: [
        {
          id: this.$nano.id(),
          label: "Primary panels",
          value: "PRIMARY_PANELS",
          icon: "mdi-file-table-box-outline",
          activeIcon: "mdi-file-table-box",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Secondary panels",
        //   value: "SECONDARY_PANELS",
        //   icon: "mdi-file-table-box-multiple-outline",
        //   activeIcon: "mdi-file-table-box-multiple",
        // },
      ],
    };
  },

  methods: {
    handleClick(panelType) {
      this.$emit("input", panelType);
    },
  },
};
</script>

<style lang="scss" scoped>
#panel-types {
  position: absolute;
  top: 72px;
  left: 16px;
  border-radius: 4px;
  background-color: var(--component-bg-color);
  z-index: 1;

  .panel-type {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--icon-color);

    &:hover {
      cursor: pointer;
      color: var(--secondary);
    }

    &.active {
      color: var(--primary);
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--divider-color);
    }
  }
}
</style>
