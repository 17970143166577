<template>
  <div id="paragraph-field">
    <FormFieldLabel label="paragraph" />

    <FormFieldWrapper
      :is-focused="isFocused"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
    >
      <div class="q-pa-md">
        <RenderContent v-if="content" :content="content" />
        <div v-else class="placeholder">Add content ...</div>
      </div>
    </FormFieldWrapper>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import RenderContent from "./render-content.js";

export default {
  name: "ParagraphField",

  components: { FormFieldLabel, FormFieldWrapper, RenderContent },

  props: {
    content: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isFocused: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
