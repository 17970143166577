<template>
  <div>
    <FormFieldLabel label="Reports Generate" />

    <ValidationProvider
      v-slot="{ errors }"
      name="QRGenerate"
      :rules="{ required: true }"
    >
      <CheckboxField
        v-model="QRGenerate"
        label="Data"
        description="Use this reports in Data"
        class="q-mb-lg"
        :error="errors[0]"
      />

      <CheckboxField
        v-model="QRGenerate"
        label="Visualaziation"
        description="Use this reports to collect Visualaziation"
        class="q-mb-lg"
        :error="errors[0]"
      />

      <MultiSelectField
        v-if="QRGenerate"
        :value="value"
        label="Form Fields"
        :options="fields"
        @input="handleInput"
      />
    </ValidationProvider>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import { ValidationProvider } from "vee-validate";

export default {
  name: "ReportsGenerate",

  components: {
    FormFieldLabel,
    CheckboxField,
    MultiSelectField,
    ValidationProvider,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      QRGenerate: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        if (this.value.length) {
          this.QRGenerate = true;
        }
      },
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.options-wrapper {
  border-top: 1px solid var(--divider-color);
  padding-top: 16px;
}
</style>
