<template>
  <div>
    <div class="wrapper q-pa-md">
      <!-- primary panels -->
      <Draggable :value="panels" group="panels" @input="movePanels">
        <div
          v-for="(panel, idx) in panels"
          :key="panel.id"
          class="panel flip-list draggable"
        >
          <Panel
            ref="panel"
            :title="panelsLabel"
            :description="panelDescription"
            :panel-index="idx"
            :panel="panel"
            @update="updatePanel"
            @deselect="deselectAll"
          />
          <PanelSettings
            v-model="isPanelSettingsVisible"
            :panel="selectedPanel"
            @save="updatePanel"
          />
        </div>
      </Draggable>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEmpty } from "lodash-es";
import Panel from "./components/panel/Panel.vue";
import DataType from "@/constants/data-type.js";
import PanelSettings from "./components/PanelSettings.vue";
import Draggable from "@/components/common/Draggable.vue";

export default {
  name: "Panels",

  components: { Panel, Draggable, PanelSettings },

  props: {
    panels: {
      type: Array,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    panelType: {
      type: String,
      required: true,
    },

    formSettings: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedPanel: {},
      isPanelSettingsVisible: false,
      columns: [
        {
          id: this.$nano.id(),
          name: "docno",
          label: "Doc No",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "level",
          label: "Level",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "files",
          label: "Files",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "size",
          label: "Size",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdby",
          label: "Created By",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdat",
          label: "Created At",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
      ],
      reportData: [
        {
          key: "",
          data: [
            {
              id: this.$nano.id(),
              docno: "10000-1",
              level: "1",
              files: "2",
              size: "242.42 KB",
              createdby: "admin@ezofis.com",
              createdat: "2023-May-31 o4:53 PM",
              icon: "mdi-folder",
            },
            {
              id: this.$nano.id(),
              docno: "asd",
              level: "1",
              files: "1",
              size: "7.4 KB",
              createdby: "admin@ezofis.com",
              createdat: "2023-May-31 o5:03 PM",
              icon: "mdi-folder",
            },
            {
              id: this.$nano.id(),
              docno: "1123",
              level: "1",
              files: "4",
              size: "1.57 MB",
              createdby: "admin@ezofis.com",
              createdat: "2023-Feb-28 o2:49 PM",
              icon: "mdi-folder",
            },
            {
              id: this.$nano.id(),
              docno: "asd",
              level: "1",
              files: "1",
              size: "7.4 KB",
              createdby: "admin@ezofis.com",
              createdat: "2023-May-31 o5:03 PM",
              icon: "mdi-folder",
            },
          ],
        },
      ],
      filterBy: [],
      columnData: [],
      activeView: "REPORTSDATA",
      panelsLabel: "",
    };
  },

  created() {
    if (this.panels.length) {
      return;
    }

    this.addPanel();
  },
  mounted() {
    this.tittle();
    // this.panelsLabel = localStorage.getItem("panelsPage");
  },

  methods: {
    tittle() {
      if (this.panelType === "PRIMARY_PANELS") {
        this.panelsLabel = "Measures";
        this.panelDescription =
          "numerical data that you want to analyze or measures";
      } else if (this.panelType === "SECONDARY_PANELS") {
        this.panelsLabel = "Dimensions";
        this.panelDescription =
          "qualitatives or categorical data fileds used to categorize, segment, or group data";
      }
    },
    updatePanels(panels) {
      this.$emit("update", panels);
    },
    hasFields() {
      return !!this.panel?.fields?.length;
    },

    movePanels(panels) {
      this.updatePanels(panels);
    },

    deselectAll() {
      this.deselectPanel();
      this.$refs.panel.forEach((panel) => panel.deSelectField());
    },

    /* panel */

    isPanelSelected(panel) {
      if (isEmpty(this.selectedPanel)) {
        return false;
      }

      return this.selectedPanel.id === panel.id;
    },

    isPanelSelected1(measures) {
      if (isEmpty(this.selectedPanel)) {
        return false;
      }

      return this.selectedPanel.id === measures.id;
    },

    selectPanel(panel) {
      this.deselectAll();
      this.selectedPanel = panel;
    },

    selectPanel1(measures) {
      this.deselectAll();
      this.selectedPanel1 = measures;
    },

    deselectPanel() {
      this.selectedPanel = {};
    },

    addPanel() {
      const newPanel = {
        id: this.$nano.id(),
        settings: {
          title: "",
          description: "",
        },
        fields: [],
      };

      this.updatePanels([...cloneDeep(this.panels), newPanel]);
    },

    updatePanel(panel) {
      const panels = cloneDeep(this.panels);
      const panelIdx = panels.findIndex((_panel) => _panel.id === panel.id);
      panels[panelIdx] = panel;
      this.updatePanels(panels);
    },

    deletePanel(panel) {
      const panels = this.panels.filter((_panel) => _panel.id !== panel.id);
      this.updatePanels(panels);
    },

    duplicatePanel(panelIdx) {
      const panel = this.panels[panelIdx];
      const duplicatePanel = cloneDeep(panel);
      duplicatePanel.id = this.$nano.id();

      const panels = cloneDeep(this.panels);
      panels.splice(panelIdx + 1, 0, duplicatePanel);

      this.updatePanels(panels);
    },

    movePanelUp(panelIdx) {
      if (panelIdx === 0) {
        return;
      }

      const panels = cloneDeep(this.panels);
      [panels[panelIdx], panels[panelIdx - 1]] = [
        panels[panelIdx - 1],
        panels[panelIdx],
      ];

      this.updatePanels(panels);
    },

    movePanelDown(panelIdx) {
      if (panelIdx === this.panels.length - 1) {
        return;
      }

      const panels = cloneDeep(this.panels);
      [panels[panelIdx], panels[panelIdx + 1]] = [
        panels[panelIdx + 1],
        panels[panelIdx],
      ];

      this.updatePanels(panels);
    },

    showPanelSettings() {
      this.isPanelSettingsVisible = true;
    },

    savePanelSettings(settings) {
      const panel = cloneDeep(this.panel);
      panel.settings = settings;

      this.$emit("update", panel);
    },

    /* ... */

    /* history fns */

    undo() {
      if (!this.panelsHistory.canUndo()) {
        return;
      }

      const panels = this.panelsHistory.undo();
      this.updatePanels(panels);
    },

    redo() {
      if (!this.panelsHistory.canRedo()) {
        return;
      }

      const panels = this.panelsHistory.redo();
      this.updatePanels(panels);
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#panels {
  height: calc(100% - 56px);
  outline: none;

  .wrapper {
    width: 820px;
    margin: auto;
    padding: 16px;
  }

  .history {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 16px;
    z-index: 1;
  }

  .panel {
    margin-bottom: 24px;
    outline: none;

    &.draggable {
      cursor: move;
    }
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
</style>
