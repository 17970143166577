<template>
  <Sheet
    :value="value"
    width="720px"
    no-padding
    has-footer
    show-maximize-btn
    @input="closeSettings"
  >
    <!-- title -->

    <template #title>Nested List Builder</template>

    <!-- ... -->

    <!-- content -->

    <template #default>
      <NestedList
        v-model="nestedList"
        :level="1"
        :max-level="maxLevel"
        class="root-list"
      />
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton
        label="cancel"
        is-flat
        class="q-mr-sm"
        @click="closeSettings"
      />

      <BaseButton label="save" @click="save" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import NestedList from "./components/NestedList.vue";
import { cloneDeep } from "lodash-es";

export default {
  name: "NestedListBuilder",

  components: { Sheet, NestedList },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    field: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      nestedList: [],
    };
  },

  computed: {
    maxLevel() {
      return this.field.settings.specific.nestedListMaxLevel;
    },
  },

  created() {
    this.nestedList = cloneDeep(this.field.settings.specific.nestedList);
  },

  methods: {
    closeSettings() {
      this.$emit("input", false);
    },

    save() {
      if (!this.nestedList.length) return;

      const field = cloneDeep(this.field);
      field.settings.specific.nestedList = this.nestedList;

      this.$emit("save", field);
      this.closeSettings();
    },
  },
};
</script>

<style lang="scss" scoped>
.root-list {
  margin-left: 0;
  margin-right: 16px;
}
</style>
