<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div id="portals-fields">
    <Tabs
      v-model="tab"
      :tabs="tabs"
      color="secondary"
      is-full-width
      class="tabs"
    />

    <BaseScrollbar height="calc(100vh - 138px)" class="q-pa-md">
      <div
        v-for="field in _fields"
        :key="field.id"
        class="field"
        draggable
        @dragstart="(e) => handleFieldDragStart(e, field.name)"
      >
        <BaseIcon
          name="drag_indicator"
          size="16px"
          inherit-color
          class="drag-icon"
        />

        <div class="icon-wrapper">
          <BaseIcon :name="field.icon" />
        </div>

        <div class="label">{{ field.name }}</div>
      </div>
      <p class="q-mt-md q-ml-md" style="font-weight: 500">Task</p>
      <BaseSeparator class="q-my-md" />

      <div
        v-for="field in _fields1"
        :key="field.id"
        class="field"
        draggable
        @dragstart="(e) => handleFieldDragStart(e, field.name)"
      >
        <BaseIcon
          name="drag_indicator"
          size="16px"
          inherit-color
          class="drag-icon"
        />

        <div class="icon-wrapper">
          <BaseIcon :name="field.icon" />
        </div>

        <div class="label">{{ field.name }}</div>
      </div>
      <p class="q-mt-md q-ml-md" style="font-weight: 500">Folders</p>
      <BaseSeparator class="q-my-md" />
      <div
        v-for="field in _fields2"
        :key="field.id"
        class="field"
        draggable
        @dragstart="(e) => handleFieldDragStart(e, field.name)"
      >
        <BaseIcon
          name="drag_indicator"
          size="16px"
          inherit-color
          class="drag-icon"
        />

        <div class="icon-wrapper">
          <BaseIcon :name="field.icon" />
        </div>

        <div class="label">{{ field.name }}</div>
      </div>
      <BaseSeparator class="q-my-md" />
      <div
        v-for="field in _fields3"
        :key="field.id"
        class="field"
        draggable
        @dragstart="(e) => handleFieldDragStart(e, field.name)"
      >
        <BaseIcon
          name="drag_indicator"
          size="16px"
          inherit-color
          class="drag-icon"
        />

        <div class="icon-wrapper">
          <BaseIcon :name="field.icon" />
        </div>

        <div class="label">{{ field.name }}</div>
      </div>
    </BaseScrollbar>
  </div>
</template>

<script>
import Tabs from "@/components/common/tabs/Tabs.vue";

export default {
  name: "ReportsFields",

  components: { Tabs },

  props: {
    activePanelType: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      tab: "BASIC",
      tabs: [
        {
          id: this.$nano.id(),
          label: "Measures",
          value: "BASIC",
        },
        {
          id: this.$nano.id(),
          label: "Dimensions",
          value: "ADVANCED",
        },
      ],
      fields: [
        {
          id: this.$nano.id(),
          name: "Date",
          type: "DATE",
          icon: "eva-calendar-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Time",
          type: "TIME",
          icon: "eva-clock-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Datetime",
          type: "DATE_TIME",
          icon: "mdi-calendar-clock-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Date",
          type: "DATE",
          icon: "eva-calendar-outline",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Time",
          type: "TIME",
          icon: "eva-clock-outline",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Datetime",
          type: "DATE_TIME",
          icon: "mdi-calendar-clock-outline",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
      ],
      fields1: [
        {
          id: this.$nano.id(),
          name: "TaskName",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "TaskOwner",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "CreatedBy",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "CreatedBy",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "TaskassignedBy",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Priority",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Priority",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "TaskStatus",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "TaskStatus",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "TaskWorkspace",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "StartDate",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "EndtDate",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "OverDue",
          type: "PARAGRAPH",
          icon: "mdi-alphabetical",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
      ],
      fields3: [
        {
          id: this.$nano.id(),
          name: "LifeCycleStage",
          type: "SINGLE_SELECT",
          icon: "mdi-form-dropdown",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Portals",
          type: "MULTI_SELECT",
          icon: "mdi-form-select",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Workflows",
          type: "SINGLE_CHOICE",
          icon: "eva-radio-button-on",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Workspace",
          type: "MULTIPLE_CHOICE",
          icon: "eva-checkmark-square-2-outline",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "CreatedBy",
          type: "FILE_UPLOAD",
          icon: "mdi-file-document-outline",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Priority",
          type: "DIVIDER",
          icon: "mdi-format-vertical-align-center",
          group: "ADVANCED",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "LifeCycleStage",
          type: "SINGLE_SELECT",
          icon: "mdi-form-dropdown",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Portals",
          type: "MULTI_SELECT",
          icon: "mdi-form-select",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Workflows",
          type: "SINGLE_CHOICE",
          icon: "eva-radio-button-on",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Workspace",
          type: "MULTIPLE_CHOICE",
          icon: "eva-checkmark-square-2-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "CreatedBy",
          type: "FILE_UPLOAD",
          icon: "mdi-file-document-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "Priority",
          type: "DIVIDER",
          icon: "mdi-format-vertical-align-center",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
      ],
      fields2: [
        {
          id: this.$nano.id(),
          name: "Folders",
          type: "CHIPS",
          icon: "mdi-dns-outline",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "DueDate",
          type: "SINGLE_SELECT",
          icon: "mdi-form-dropdown",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          name: "PendingApprovel",
          type: "MULTI_SELECT",
          icon: "mdi-form-select",
          group: "BASIC",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
      ],
    };
  },

  computed: {
    _fields() {
      return this.fields.filter(
        (field) =>
          field.group === this.tab && field.for.includes(this.activePanelType)
      );
    },
    _fields1() {
      return this.fields1.filter(
        (field) =>
          field.group === this.tab && field.for.includes(this.activePanelType)
      );
    },
    _fields2() {
      return this.fields2.filter(
        (field) =>
          field.group === this.tab && field.for.includes(this.activePanelType)
      );
    },
    _fields3() {
      return this.fields3.filter(
        (field) =>
          field.group === this.tab && field.for.includes(this.activePanelType)
      );
    },
  },

  methods: {
    handleFieldDragStart(e, fieldName) {
      e.dataTransfer.setData("fieldName", fieldName);
    },
  },
};
</script>

<style lang="scss" scoped>
#portals-fields {
  width: 320px;
  background-color: var(--component-bg-color);

  .tabs {
    margin: 0px 16px;
    border-bottom: 1px solid var(--divider-color);
  }

  .field {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    user-select: none;
    transition: all 0.3s ease;

    .drag-icon {
      color: var(--icon-color-inverted);
    }

    .icon-wrapper {
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--body-bg-color);
      border-radius: 100%;
      margin: 0px 16px 0px 8px;
      transition: all 0.3s ease;
    }

    .label {
      font-weight: 500;
      text-transform: capitalize;
      flex: 1;
    }

    &:hover {
      background-color: var(--hover-bg-color);
      cursor: move;
    }
  }
  #div1 {
    width: 350px;
    height: 70px;
    padding: 10px;
    border: 1px solid #aaaaaa;
  }
}
</style>
