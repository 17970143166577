<template>
  <div id="form-publish">
    <!-- action bar -->

    <ActionBar v-model="tab" :tabs="tabs" @save="saveSettings" />

    <!-- ... -->

    <!-- settings -->

    <BaseScrollbar height="calc(100vh - 138px)">
      <div class="row q-pb-xl">
        <ValidationObserver ref="form">
          <!-- publish options -->

          <PublishOptions
            ref="PUBLISH"
            :mode="mode"
            :publish-option.sync="publishOption"
            :publish-schedule.sync="publishSchedule"
            :unpublish-schedule.sync="unpublishSchedule"
            :is-active="tab === 'PUBLISH'"
            @click="tab = 'PUBLISH'"
          />

          <!-- ... -->

          <!-- share options -->

          <!-- ... -->

          <!-- embed options -->

          <!-- ... -->
        </ValidationObserver>

        <!-- ... -->
      </div>
    </BaseScrollbar>

    <!-- ... -->
  </div>
</template>

<script>
import ActionBar from "../components/ActionBar.vue";
import { ValidationObserver } from "vee-validate";
import { cloneDeep } from "lodash-es";
import PublishOptions from "./components/publish-options/PublishOptions.vue";

export default {
  name: "RepotrsPublish",

  components: {
    ActionBar,
    ValidationObserver,
    PublishOptions,
  },

  props: {
    formSettings: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mode: "NEW",
      tab: "PUBLISH",
      tabs: [
        {
          id: this.$nano.id(),
          label: "publish",
          value: "PUBLISH",
        },
      ],

      // publish

      publishOption: "",
      publishSchedule: "",
      unpublishSchedule: "",

      // share options

      emailToInternalUsers: [],
      emailToExternalUsers: [],
    };
  },

  watch: {
    tab() {
      this.$refs[this.tab].$el.scrollIntoView({ behavior: "smooth" });
    },

    formSettings: {
      immediate: true,
      deep: true,
      handler() {
        const formSettings = cloneDeep(this.formSettings);

        this.publishOption = formSettings.publish.publishOption;
        this.publishSchedule = formSettings.publish.publishSchedule;
        this.unpublishSchedule = formSettings.publish.unpublishSchedule;
      },
    },
  },

  methods: {
    saveSettings() {
      const formSettings = cloneDeep(this.formSettings);

      formSettings.publish.publishOption = this.publishOption;
      formSettings.publish.publishSchedule = this.publishSchedule;
      formSettings.publish.unpublishSchedule = this.unpublishSchedule;

      this.$emit("update", formSettings);
    },
  },
};
</script>

<style lang="scss" scoped></style>
