<template>
  <FormSection
    :icon="isActive ? 'eva-options-2' : 'eva-options-2-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>General</template>
    <template #description>
      Name your reports and provide detailed description for your reference
    </template>

    <!-- name -->

    <ValidationProvider
      v-slot="{ errors }"
      name="name"
      :rules="{ required: true }"
    >
      <TextField
        :value="name"
        label="name"
        is-mandatory
        :is-readonly="mode === 'EDIT'"
        :error="errors[0]"
        class="q-mb-lg"
        @input="updateName"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- description -->

    <TextAreaField
      :value="description"
      label="description"
      class="q-mb-lg"
      @input="updateDescription"
    />

    <!-- ... -->

    <!-- layout -->

    <!-- ... -->

    <!-- types -->

    <!-- ... -->

    <!-- unique columns -->

    <!-- ... -->

    <!-- qr fields -->

    <SelectField
      v-model="Objects"
      :value="linkobjectsId"
      is-mandatory
      label="Objects"
      :options="objectsList"
      class="q-mt-lg"
    />

    <DateField
      :value="maximum"
      placeholder="Maximum"
      label="Start Date"
      class="q-mt-lg"
      @input="updateMaximum"
    />

    <DateField
      :value="maximum"
      placeholder="Maximum"
      label="End Date"
      class="q-mt-lg"
      @input="updateMaximum"
    />

    <ReportsGenerate
      :value="qrFields"
      is-mandatory
      class="q-mt-lg"
      :fields="_fields"
      @input="updateQRFields"
    />

    <!-- ... -->
  </FormSection>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormSection from "@/components/common/item-builder/FormSection.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import ReportsGenerate from "./components/ReportsGenerate.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";

export default {
  name: "GeneralSettings",

  components: {
    FormSection,
    ValidationProvider,
    TextField,
    TextAreaField,
    SelectField,
    ReportsGenerate,
    DateField,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    layout: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    qrFields: {
      type: Array,
      default: () => [],
    },

    uniqueColumns: {
      type: Array,
      default: () => [],
    },

    linkobjectsId: {
      type: Number,
      default: 0,
    },
    maximum: {
      type: [String, Number],
      default: "",
    },
  },

  data() {
    return {
      fields: [],
      objectsList: [
        {
          id: this.$nano.id(),
          label: "Workflows",
          value: "workflows",
        },
        {
          id: this.$nano.id(),
          label: "Portals",
          value: "portals",
        },
        {
          id: this.$nano.id(),
          label: "Files",
          value: "files",
        },
        {
          id: this.$nano.id(),
          label: "Tasks",
          value: "tasks",
        },
      ],
      Objects: "",
    };
  },

  computed: {
    _fields() {
      return this.fields.map((field) => ({
        id: field.id,
        label: field.label || field.type,
        value: field.id,
      }));
    },
  },

  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        this.fields = [];
        const panels = [...this.form.panels, ...this.form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        for (const panel of panels) {
          this.fields.push(...panel.fields);
        }
      },
    },
  },

  methods: {
    updateName(name) {
      this.$emit("update:name", name);
    },

    updateDescription(description) {
      this.$emit("update:description", description);
    },

    updateLayout(layout) {
      this.$emit("update:layout", layout);
    },

    updateType(type) {
      this.$emit("update:type", type);
    },

    updateQRFields(fields) {
      this.$emit("update:qrFields", fields);
    },

    updateUniqueColumns(fields) {
      this.$emit("update:uniqueColumns", fields);
    },
    updateMaximum(value) {
      this.$emit("update:maximum", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
