<template>
  <div id="add-rule-buttons">
    <div class="add-rule" @click="$emit('add-conditonal-rule')">
      add conditional rule
    </div>
    <div class="add-rule" @click="$emit('add-rule')">add rule</div>
  </div>
</template>

<script>
export default {
  name: "AddRuleButtons",
};
</script>

<style lang="scss" scoped>
#add-rule-buttons {
  display: flex;
  align-items: center;

  .add-rule {
    padding: 8px 16px;
    background-color: var(--hover-bg-color);
    border-radius: 4px;
    font-weight: 500;
    text-transform: capitalize;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &:hover {
      cursor: pointer;
      color: white;
      background-color: var(--secondary);
    }
  }
}
</style>
