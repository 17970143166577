<template>
  <div id="vectors-builder">
    <!-- header -->

    <div class="header row">
      <div class="col row items-center">
        <!-- label -->

        <FormFieldLabel :label="label" is-mandatory class="no-margin" />

        <!-- ... -->
      </div>

      <div class="row items-center">
        <BaseActionButton
          is-flat
          color="secondary"
          icon="eva-plus"
          no-border
          @click="addVector"
        />
      </div>
    </div>

    <!-- ... -->

    <!-- vectors -->

    <Draggable v-model="vectors">
      <div
        v-for="(vector, idx) in vectors"
        :key="vector.id"
        class="row q-mb-sm"
      >
        <!-- name -->

        <div class="col row items-center q-mr-sm">
          <BaseIcon
            name="drag_indicator"
            size="16px"
            inherit-color
            class="q-mr-sm drag-icon"
          />
          <TextField v-model="vector.label" :is-clearable="false" class="col" />
        </div>

        <!-- ... -->

        <!-- delete -->

        <div class="row items-center">
          <BaseActionButton
            is-flat
            color="red"
            icon="eva-close-outline"
            no-border
            @click="deleteVector(idx)"
          />
        </div>

        <!-- ... -->
      </div>
    </Draggable>

    <!-- ... -->

    <BaseSeparator v-if="value.length" class="q-mt-md" />

    <!-- error -->

    <FormFieldError v-if="error" :error="error" />

    <!-- ... -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import Draggable from "@/components/common/Draggable.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import { cloneDeep } from "lodash-es";

export default {
  name: "MatrixVectorsBuilder",

  components: {
    FormFieldLabel,
    FormFieldError,
    Draggable,
    TextField,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      default: "",
    },

    error: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      vectors: [],
    };
  },

  watch: {
    vectors: {
      deep: true,
      handler() {
        this.$emit("input", this.vectors);
      },
    },
  },

  created() {
    this.vectors = cloneDeep(this.value);
  },

  methods: {
    addVector() {
      this.vectors.push({
        id: this.$nano.id(),
        label: this.label === "columns" ? "column" : "row",
      });
    },

    deleteVector(vectorIdx) {
      this.vectors.splice(vectorIdx, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
#vectors-builder {
  .header {
    border-bottom: 1px solid var(--divider-color);
    margin-bottom: 16px;
    padding-bottom: 4px;

    .no-margin {
      margin: 0px !important;
    }
  }

  .drag-icon {
    color: var(--icon-color-inverted);
    cursor: move;
  }
}
</style>
