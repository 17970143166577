<template>
  <div id="form-builder-wrapper">
    <ItemBuilder @close="closeBuilder">
      <!-- header -->

      <template #title> Form {{ _tab }} </template>

      <template #description>
        {{ _description }}
      </template>

      <template #header>
        <div class="row items-center justify-center">
          <Tabs v-model="tab" :tabs="tabs" is-full-width class="tabs" />
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          icon="eva-question-mark"
          color="secondary"
          class="q-mr-sm"
          @click="showQuickHelp"
        />
      </template>

      <!-- ... -->

      <!-- content -->

      <FormBuilder
        v-if="tab === 'BUILDER'"
        :form="form"
        :is-loading="isLoading"
        @update-panels="updatePanels"
        @update-secondary-panels="updateSecondaryPanels"
        @settings="tab = 'SETTINGS'"
        @save="saveForm"
      />

      <FormSettings
        v-if="tab === 'SETTINGS'"
        :form="form"
        @update="updateSettings"
      />

      <FormPublish
        v-if="tab === 'PUBLISH'"
        :form-id="formId"
        :form-settings="form.settings"
        @update="updateSettings"
      />

      <!-- ... -->

      <!-- quick help -->

      <QuickHelp v-model="quickHelp" />

      <!-- ... -->
    </ItemBuilder>
  </div>
</template>

<script>
import ItemBuilder from "@/components/common/item-builder/ItemBuilder.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";
import FormBuilder from "./form-builder/FormBuilder.vue";
import FormSettings from "./form-settings/FormSettings.vue";
import FormPublish from "./form-publish/FormPublish.vue";
import { capitalize, lowerCase } from "lodash-es";
import { form } from "@/api/factory.js";
import taskForm from "@/helpers/task-form";

export default {
  name: "FormBuilderWrapper",

  components: {
    ItemBuilder,
    Tabs,
    QuickHelp,
    FormBuilder,
    FormSettings,
    FormPublish,
  },

  mixins: [taskForm],

  props: {
    formId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      quickHelp: false,
      isLoading: false,
      tab: "BUILDER",
      tabs: [
        {
          id: this.$nano.id(),
          label: "Builder",
          value: "BUILDER",
          description: "Create custom forms and collect data effortlessly",
        },
        {
          id: this.$nano.id(),
          label: "Settings",
          value: "SETTINGS",
          description: "Customize and manage your form settings",
        },
        {
          id: this.$nano.id(),
          label: "Publish",
          value: "PUBLISH",
          description: "Publish and deploy your form in action",
        },
      ],
      form: {
        uid: this.$nano.id(),
        panels: [],
        secondaryPanels: [],
        settings: {
          general: {
            name: "Form - " + this.$day.newDateTime(),
            description: "",
            layout: "CLASSIC",
            type: "WORKFLOW",
          },
          rules: [],
          publish: {
            publishOption: "DRAFT",
            publishSchedule: "",
            unpublishSchedule: "",
          },
        },
        isDeleted: false,
      },
    };
  },

  computed: {
    _tab() {
      return capitalize(lowerCase(this.tab));
    },

    _description() {
      let currentTab = this.tabs.filter((item) => item.value === this.tab);
      return currentTab[0].description;
    },
  },

  watch: {
    formId: {
      immediate: true,
      handler() {
        if (this.formId) {
          this.mode = "EDIT";
          this.getForm();
        }
      },
    },
  },

  methods: {
    showQuickHelp() {
      this.quickHelp = true;
    },

    closeBuilder() {
      this.$router.back();
    },

    updatePanels(panels) {
      this.form.panels = panels;
    },

    updateSecondaryPanels(panels) {
      this.form.secondaryPanels = panels;
    },

    updateSettings(settings) {
      this.form.settings = settings;
      this.tab = "BUILDER";

      if (this.form.settings.general.type === "TASK") {
        // console.log(this.taskForm.panels);
        if (this.form.panels.length == 0) {
          this.form.panels = this.taskForm.panels;
        } else if (this.form.panels[0].fields.length == 0) {
          this.form.panels[0].fields = this.taskForm.panels[0].fields;
        } else {
          if (this.form.panels[0].fields.length) {
            // this.taskForm.panels[0].fields.forEach((item) => {
            //   this.form.panels[0].fields.push(item);
            // });
            this.form.panels[0].fields = this.taskForm.panels[0].fields;
          }
        }
      } else if (this.form.settings.general.type === "SLA") {
        // console.log(this.taskForm.panels);
        if (this.form.panels.length == 0) {
          this.form.panels = this.slaForm.panels;
        } else if (this.form.panels[0].fields.length == 0) {
          this.form.panels[0].fields = this.slaForm.panels[0].fields;
        } else {
          if (this.form.panels[0].fields.length) {
            // this.slaForm.panels[0].fields.forEach((item) => {
            //   this.form.panels[0].fields.push(item);
            // });
            this.form.panels[0].fields = this.slaForm.panels[0].fields;
          }
        }
      } else if (this.form.settings.general.type === "Feedback Form") {
        // console.log(this.taskForm.panels);
        if (this.form.panels.length == 0) {
          this.form.panels = this.feedbackForm.panels;
        } else if (this.form.panels[0].fields.length == 0) {
          this.form.panels[0].fields = this.feedbackForm.panels[0].fields;
        } else {
          if (this.form.panels[0].fields.length) {
            // this.feedbackForm.panels[0].fields.forEach((item) => {
            //   this.form.panels[0].fields.push(item);
            // });
            this.form.panels[0].fields = this.feedbackForm.panels[0].fields;
          }
        }
      } else {
        // this.form.panels = [];
      }
    },

    saveForm() {
      let fieldsCount = 0;
      this.form.panels.forEach((panel) => (fieldsCount += panel.fields.length));

      if (!fieldsCount) {
        this.$alert.warning("Please add fields to continue");
        return;
      }

      this.form.panels.forEach((panel) => {
        panel.fields.forEach((field) => {
          delete field.filterBy;
          delete field.filter;
        });
      });

      this.formId ? this.updateForm() : this.createForm();
    },

    /* api functions */

    async createForm() {
      this.isLoading = true;
      this.$store.commit("showLoadingBar");

      const { error } = await form.createForm(this.form);

      if (error) {
        if (error !== "error creating form") {
          this.$alert.warning(error);
        } else {
          this.$alert.error(error);
        }
        this.$store.commit("hideLoadingBar");
        this.isLoading = false;
        return;
      }

      this.$alert.success("New form created");
      this.closeBuilder();
    },

    async getForm() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getForm(this.formId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.form = JSON.parse(payload.formJson);
    },

    async updateForm() {
      this.isLoading = true;

      this.$store.commit("showLoadingBar");
      // console.log(this.form);
      const { error } = await form.updateForm(this.formId, this.form);

      this.$store.commit("hideLoadingBar");

      if (error) {
        if (error !== "error updating form") {
          this.$alert.warning(error);
        } else {
          this.$alert.error(error);
        }
        this.isLoading = false;

        return;
      }

      this.$alert.success("Form saved successfully");
      this.closeBuilder();
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#form-builder-wrapper {
  height: 100vh;

  .tabs {
    width: 240px;
    border-bottom: 1px solid var(--divider-color);
  }
}
</style>
