<template>
  <div id="form-header" @mouseleave="handleFocusOut" @blur="handleFocusOut">
    <!-- form info -->

    <div class="row items-center">
      <!-- settings -->

      <BaseActionButton
        v-tooltip.top="'edit form settings'"
        icon="eva-edit"
        is-flat
        color="primary"
        class="q-mr-md"
        @click="$emit('settings')"
      />

      <!-- ... -->

      <div>
        <div class="title-2" @click="openFormNameInput = true">
          <template v-if="!openFormNameInput">{{ formName }}</template>

          <input
            v-if="openFormNameInput"
            v-model="formSettings.general.name"
            type="text"
            autofocus
            class="title"
            @mouseleave="openFormNameInput = false"
          />
        </div>

        <div class="description" @click="openDecsInput = true">
          <template v-if="!openDecsInput">{{ formDescription }}</template>

          <input
            v-if="openDecsInput"
            v-model="formSettings.general.description"
            type="text"
            autofocus
            class="description"
            @mouseleave="openDecsInput = false"
          />
        </div>
      </div>
    </div>

    <!-- ... -->

    <q-space />

    <BaseButton
      label="preview"
      color="secondary"
      icon="eva-eye-outline"
      class="q-mr-sm"
      @click="$emit('preview')"
    />

    <BaseButton
      label="save"
      :is-loading="isLoading"
      icon="eva-save-outline"
      @click="$emit('save')"
    />
  </div>
</template>

<script>
export default {
  name: "FormHeader",

  props: {
    formSettings: {
      type: Object,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      openFormNameInput: false,
      openDecsInput: false,
    };
  },

  computed: {
    formName() {
      return this.formSettings.general.name || "Form name";
    },

    formDescription() {
      return this.formSettings.general.description || "form description";
    },
  },

  methods: {
    handleFocusOut() {
      this.openFormNameInput = false;
      this.openDecsInput = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#form-header {
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  background-color: var(--component-bg-color);

  .description {
    @extend .text-sm;
    color: var(--icon-color);
  }

  .title-2:hover,
  .description:hover {
    cursor: auto;
  }

  .title {
    color: var(--title-2-color);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600 !important;
    width: 600px !important;
  }

  input:focus {
    transition: opacity 90ms ease-in-out 0s;
  }
}
</style>
