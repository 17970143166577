import { isEmpty } from "lodash-es";
import { last } from "lodash-es";

export default class History {
  constructor() {
    this.undoStack = [];
    this.redoStack = [];
  }

  add(record) {
    this.redoStack = [];
    this.undoStack.push(record);
  }

  canUndo() {
    return !isEmpty(this.undoStack);
  }

  undo() {
    const record = this.undoStack.pop();
    this.redoStack.push(record);

    if (isEmpty(this.undoStack)) {
      return [];
    }

    return last(this.undoStack);
  }

  canRedo() {
    return !isEmpty(this.redoStack);
  }

  redo() {
    const record = this.redoStack.pop();
    this.undoStack.push(record);
    return last(this.undoStack);
  }
}
