<template>
  <div class="q-mb-lg">
    <FormFieldLabel label="form type" is-mandatory />

    <div class="options-wrapper">
      <!-- workflow -->

      <RadioField
        v-if="workflowForms"
        :value="value === 'WORKFLOW'"
        label="Workflow"
        description="Use this form in workflows"
        class="q-mb-md"
        @input="handleInput('WORKFLOW')"
      />

      <!-- ... -->

      <!-- master -->

      <RadioField
        v-if="masterForms"
        :value="value === 'MASTER'"
        label="Master"
        description="Use this form to collect master data"
        class="q-mb-md"
        @input="handleInput('MASTER')"
      />

      <!-- ... -->

      <!-- external -->

      <!-- <RadioField
        :value="value === 'EXTERNAL'"
        label="External"
        description="lorem ipsum dolar sit amit"
        class="q-mb-md"
        @input="handleInput('EXTERNAL')"
      /> -->

      <!-- ... -->

      <!-- external -->

      <RadioField
        v-if="taskForms"
        :value="value === 'TASK'"
        label="Task"
        class="q-mb-md"
        description="Use this Form in tasks"
        @input="handleInput('TASK')"
      />

      <RadioField
        v-if="slaForms"
        :value="value === 'SLA'"
        label="SLA"
        class="q-mb-md"
        description="Use this Form in SLA"
        @input="handleInput('SLA')"
      />

      <RadioField
        v-if="feedbackForms"
        :value="value === 'Feedback Form'"
        label="Feedback Form"
        description="Use this Form in workflow for feedback"
        @input="handleInput('Feedback Form')"
      />

      <!-- ... -->
    </div>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import RadioField from "@/components/common/form/radio-field/RadioField.vue";

export default {
  name: "FormTypes",

  components: { FormFieldLabel, RadioField },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      workflowForms: false,
      masterForms: false,
      slaForms: false,
      taskForms: false,
      feedbackForms: false,
    };
  },

  created() {
    if (this.$store.state.profileMenus.length) {
      this.workflowForms = this.$store.state.profileMenus.find(
        (row) => row.forms
      )?.Menu["Manage Workflow Forms"];
      this.masterForms = this.$store.state.profileMenus.find(
        (row) => row.forms
      )?.Menu["Manage Master Forms"];
      this.slaForms = this.$store.state.profileMenus.find(
        (row) => row.forms
      )?.Menu["Manage SLA Forms"];
      this.taskForms = this.$store.state.profileMenus.find(
        (row) => row.forms
      )?.Menu["Manage Task Forms"];
      this.feedbackForms = this.$store.state.profileMenus.find(
        (row) => row.forms
      )?.Menu["Manage Feedback Forms"];
    }
  },

  methods: {
    handleInput(type) {
      this.$emit("input", type);
    },
  },
};
</script>

<style lang="scss" scoped>
.options-wrapper {
  border-top: 1px solid var(--divider-color);
  padding-top: 16px;
}
</style>
