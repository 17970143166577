import { nanoid } from "nanoid";

export default {
  data() {
    return {
      taskForm: {
        uid: nanoid(),
        panels: [
          {
            id: nanoid(),
            settings: { title: "", description: "" },
            fields: [
              {
                id: nanoid(),
                label: "Task Name",
                type: "SHORT_TEXT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-12",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Option 1,Option 2",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "REQUIRED",
                    contentRule: "TEXT",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Details",
                type: "LONG_TEXT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-12",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Option 1,Option 2",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "TEXT",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Task Owner",
                type: "SINGLE_SELECT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-6",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "PREDEFINED",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    masterFormId: 0,
                    masterFormColumn: "",
                    masterFormParentColumn: "",
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                    predefinedTable: "User",
                    predefinedTableColumn: "email",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Task Assign",
                type: "SINGLE_SELECT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-6",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "PREDEFINED",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    masterFormId: 0,
                    masterFormColumn: "",
                    masterFormParentColumn: "",
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                    predefinedTable: "User",
                    predefinedTableColumn: "email",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Start Date",
                type: "DATE",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-6",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Option 1,Option 2",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "End Date",
                type: "DATE",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-6",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Option 1,Option 2",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Priority",
                type: "SINGLE_SELECT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-6",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Low, High, Medium",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Task Status",
                type: "SINGLE_SELECT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-6",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Open,Closed,In Progress,ReOpen",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
            ],
          },
        ],
        secondaryPanels: [
          {
            id: nanoid(),
            settings: { title: "", description: "" },
            fields: [],
          },
        ],
        settings: {
          general: {
            name: "Form - 2023-10-27 06:26 PM",
            description: "",
            layout: "CLASSIC",
            type: "WORKFLOW",
          },
          rules: [],
          publish: {
            publishOption: "DRAFT",
            publishSchedule: "",
            unpublishSchedule: "",
          },
        },
        isDeleted: false,
      },

      slaForm: {
        uid: nanoid(),
        panels: [
          {
            id: nanoid(),
            settings: { title: "", description: "" },
            fields: [
              {
                id: nanoid(),
                label: "Name",
                type: "SINGLE_SELECT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-12",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "EXISTING",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Option 1,Option 2",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: true,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "REQUIRED",
                    contentRule: "",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: false,
                showSettingsOnly: false,
              },
              {
                id: nanoid(),
                label: "Description",
                type: "LONG_TEXT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-12",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Option 1,Option 2",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "TEXT",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Response Time",
                type: "NUMBER",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-6",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Option 1,Option 2",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "DECIMAL",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Response Type",
                type: "SINGLE_SELECT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-6",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Minutes,Hours,Day",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Resolution Time",
                type: "NUMBER",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-6",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Option 1,Option 2",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "DECIMAL",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Resolution Type",
                type: "SINGLE_SELECT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-6",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Minutes,Hours,Days",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Notify",
                type: "MULTI_SELECT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-6",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "EXISTING",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: true,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    masterFormId: 0,
                    masterFormColumn: "",
                    masterFormParentColumn: "",
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                    predefinedTable: "",
                    predefinedTableColumn: "",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Escalate",
                type: "SINGLE_SELECT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-6",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "PREDEFINED",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    masterFormId: 0,
                    masterFormColumn: "",
                    masterFormParentColumn: "",
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                    predefinedTable: "User",
                    predefinedTableColumn: "email",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
            ],
          },
        ],
        secondaryPanels: [
          {
            id: nanoid(),
            settings: { title: "", description: "" },
            fields: [],
          },
        ],
        settings: {
          general: {
            name: "Form - 2023-10-30 01:28 PM",
            description: "",
            layout: "CLASSIC",
            type: "WORKFLOW",
          },
          rules: [],
          publish: {
            publishOption: "DRAFT",
            publishSchedule: "",
            unpublishSchedule: "",
          },
        },
        isDeleted: false,
      },

      feedbackForm: {
        uid: nanoid(),
        panels: [
          {
            id: nanoid(),
            settings: { title: "", description: "" },
            fields: [
              {
                id: nanoid(),
                label: "Request No",
                type: "SHORT_TEXT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-12",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Option 1,Option 2",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "REQUIRED",
                    contentRule: "TEXT",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Email",
                type: "SHORT_TEXT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-12",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Option 1,Option 2",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "REQUIRED",
                    contentRule: "EMAIL",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Remarks",
                type: "LONG_TEXT",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-12",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Option 1,Option 2",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "OPTIONAL",
                    contentRule: "TEXT",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
              {
                id: nanoid(),
                label: "Rating",
                type: "RATING",
                settings: {
                  general: {
                    hideLabel: false,
                    size: "col-12",
                    visibility: "NORMAL",
                    placeholder: "",
                    tooltip: "",
                    dividerType: "SOLID",
                  },
                  specific: {
                    optionsType: "CUSTOM",
                    masterTable: "",
                    masterTableColumn: "",
                    customOptions: "Option 1,Option 2",
                    separateOptionsUsing: "COMMA",
                    allowToAddNewOptions: false,
                    optionsPerLine: 0,
                    defaultValue: "CUSTOM",
                    autoGenerateValue: { prefix: "Form", suffix: "DATE_TIME" },
                    customDefaultValue: "",
                    showColumnTotal: false,
                    allowMultipleFiles: false,
                    ratingIcon: "STAR",
                    ratingIconCount: 5,
                    allowHalfRating: false,
                    allowMultipleSignatures: false,
                    tableColumns: [],
                    tableRowsType: "ON_DEMAND",
                    tableFixedRowCount: 0,
                    qrValue: false,
                    tableFixedRowLabels: [],
                    matrixColumns: [
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                      { id: nanoid(), label: "column" },
                    ],
                    matrixRows: [
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                      { id: nanoid(), label: "row" },
                    ],
                    matrixType: "SHORT_TEXT",
                    matrixTypeSettings: {},
                    textContent: "",
                    fibFields: [],
                    tabList: [
                      {
                        id: nanoid(),
                        label: "tab 1",
                        value: "tab 1",
                        panel: 0,
                      },
                      {
                        id: nanoid(),
                        label: "tab 2",
                        value: "tab 2",
                        panel: 0,
                      },
                    ],
                    popupTriggerType: "BUTTON",
                    secondaryPanel: "",
                    mappedFieldId: "",
                    mappedColumnId: "",
                    nestedListMaxLevel: 3,
                    nestedList: [
                      {
                        id: nanoid(),
                        label: "Item 1",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                      {
                        id: nanoid(),
                        label: "Item 2",
                        level: 1,
                        showChildren: true,
                        children: [],
                      },
                    ],
                    nestedListItemsPerLine: [],
                    nestedListFieldType: "SHORT_TEXT",
                    nestedListTypeSettings: {},
                    formula: "",
                    loginType: "EZOFIS_LOGIN",
                    additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                    repositoryId: 0,
                    repositoryField: "",
                    repositoryFieldParent: "",
                  },
                  validation: {
                    fieldRule: "REQUIRED",
                    contentRule: "",
                    minimum: "",
                    maximum: "",
                    allowedFileTypes: [],
                    maxFileSize: 10,
                    enableSettings: [],
                  },
                },
                notEditable: true,
              },
            ],
          },
        ],
        secondaryPanels: [
          {
            id: nanoid(),
            settings: { title: "", description: "" },
            fields: [],
          },
        ],
        settings: {
          general: {
            name: "Form - 2023-10-31 04:47 PM",
            description: "",
            layout: "CLASSIC",
            type: "WORKFLOW",
          },
          rules: [],
          publish: {
            publishOption: "DRAFT",
            publishSchedule: "",
            unpublishSchedule: "",
          },
        },
        isDeleted: false,
      },
    };
  },
};
