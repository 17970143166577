import { render, staticRenderFns } from "./NestedListFieldType.vue?vue&type=template&id=660b654c&scoped=true&"
import script from "./NestedListFieldType.vue?vue&type=script&lang=js&"
export * from "./NestedListFieldType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "660b654c",
  null
  
)

export default component.exports