<template>
  <q-dialog :value="value" position="right" maximized @input="closePreview">
    <div id="form-preview" style="width: 840px">
      <!-- header -->

      <div class="header">
        <!-- form meta -->

        <div class="col">
          <div class="title-1">{{ formName }}</div>
          <div class="description">{{ formDescription }}</div>
        </div>

        <!-- ... -->

        <!-- close -->

        <BaseCloseButton @click="closePreview" />

        <!-- ... -->
      </div>

      <!-- ... -->

      <!-- form -->

      <BaseScrollbar height="calc(100vh - 79px)" class="q-pa-md">
        <RenderForm
          :panels="panels"
          :secondary-panels="secondaryPanels"
          :form-model="formModel"
          :form-settings="formSettings"
          :enable-controls="_enableControls"
          :is-preview="true"
        />
      </BaseScrollbar>

      <!-- ... -->
    </div>
  </q-dialog>
</template>

<script>
import RenderForm from "@/components/common/render-form/RenderForm.vue";

export default {
  name: "FormPreview",

  components: { RenderForm },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    formSettings: {
      type: Object,
      required: true,
    },

    panels: {
      type: Array,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      formModel: {},
    };
  },

  computed: {
    formName() {
      return this.formSettings.general.name;
    },

    formDescription() {
      return this.formSettings.general.description;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      this.panels.forEach((panel) => {
        if (panel.fields.length) {
          let panelFields = panel.fields.filter(
            (field) =>
              field.type === "SINGLE_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE" ||
              field.type === "MULTI_SELECT"
          );
          if (panelFields.length) {
            panelFields.forEach((row) => {
              if (row.settings.validation.enableSettings) {
                if (row.settings.validation.enableSettings.length) {
                  let controls = [];
                  row.settings.validation.enableSettings.forEach((tblrow) => {
                    if (tblrow.controls.length) {
                      controls.push({
                        value: tblrow.value,
                        childControls: tblrow.controls,
                      });
                      tblrow.controls.forEach((control) => {
                        if (
                          controls.indexOf(control) < 0 &&
                          listControls.indexOf(control) < 0
                        ) {
                          listControls.push(control);
                        }
                      });
                    }
                  });
                  if (controls.length) {
                    enableControls.push({
                      id: row.id,
                      parentControls: controls,
                    });
                  }
                }
              }
            });
          }
        }
      });
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },
  },

  methods: {
    closePreview() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#form-preview {
  background-color: var(--body-bg-color);

  .header {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid var(--divider-color);
    background-color: var(--component-bg-color);

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }
}
</style>
